import { submittalAppId } from "@shared/staticValue";
import { IContracNestedViewItem } from "../api-generated";

export default class ContractNestedViewItem
  implements IContracNestedViewItem
{
  public UserId: string;
  public ContractId: string;
  public ApplicationId: string;
  public NestedView?: boolean | null;

  constructor(
    userId: string, contractId: string, nestedView?: boolean
  ) {
    this.UserId = userId;
    this.ContractId = contractId;
    this.ApplicationId = submittalAppId;
    this.NestedView = nestedView;
  }
}
