export default class RecallRequest {
  public comments: string;

  public reason: string | null;

  constructor() {
    this.comments = "Enter Note";
    this.reason = null;
  }
}
