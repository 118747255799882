import { Injectable } from "@angular/core";
import { of, Subscription } from "rxjs";
import { concatMap, finalize, take } from "rxjs/operators";
import * as XLSX from 'xlsx/xlsx.mjs';
import {
  IPSubItem,
  IPRFISpecification,
  SubmittalDocumentType,
  SubmittalService,
} from "../api-generated";
import { ISubCreateStep } from "../api-generated/model/iSubCreateStep";
import SubmittalCreate from "../models/submittalCreate";
import LoadingService from "./loading.service";

type xlsxShape = {
  firstContentRow: number;
  // sould match columnNames
  columnNumbers: number[];
};

@Injectable({
  providedIn: "root",
})
export default class ScheduleUploadService {
  private headerLength: number = 1;
  private sheetNumber: number = 0;
  // fuzzy logic should handle non a-z or whitespace characters (regex)
  private columnNames: string[] = [
    "title",
    "spec section",
    "type",
    "scheduled submittal due date",
  ];

  constructor(
    private loadingService: LoadingService,
    private submittalService: SubmittalService,
  ) {
    this.columnNames = this.columnNames.map(this.parseCellForMatching);
  }

  public upload(
    file: File,
    contractId: string,
    specificationList: IPRFISpecification[],
    documenttypeList: SubmittalDocumentType[],
  ) {
    this.loadingService.start();
    const reader: FileReader = new FileReader();
    const typeTable = this.cleanLookups(documenttypeList) as SubmittalDocumentType[];

    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const buffer: ArrayBuffer = reader.result as ArrayBuffer;
        const data = this.readXlsxBuffer(buffer);
        const headerShape = this.findHeader(data);
        const drafts = this.readSubmittalData(
          contractId,
          data,
          headerShape,
          specificationList,
          typeTable,
        );
        return this.createSubmittalDrafts(drafts);
      } catch (e) {
        console.error(e);
        this.loadingService.stop();
      }
    };
    reader.readAsArrayBuffer(file);
  }

  private readXlsxBuffer(buffer: ArrayBuffer): unknown[][] {
    const workbook: XLSX.WorkBook = XLSX.read(buffer, {
      type: "buffer",
      cellDates: true,
    });
    const worksheet: XLSX.WorkSheet =
      workbook.Sheets[workbook.SheetNames[this.sheetNumber]];
    return XLSX.utils.sheet_to_json(worksheet, { header: this.headerLength });
  }

  private findHeader(data: unknown[][]): xlsxShape {
    let columnNumbers: number[] = this.columnNames.map(() => -1);

    let rowCounter = 0;
    while (data.length > rowCounter && !this.headerFound(columnNumbers)) {
      let flatCells = data[rowCounter].map(this.parseCellForMatching);
      const newColumnNumbers = this.columnNames.map((name) =>
        flatCells.findIndex((strVal) => strVal && strVal.includes(name)),
      );
      columnNumbers = newColumnNumbers.map((nr, index) =>
        nr === -1 ? columnNumbers[index] : nr,
      );
      rowCounter = rowCounter + 1;
    }

    if (!this.headerFound(columnNumbers)) {
      throw new Error(
        "Unable to find header. Missing column: " +
          this.findMissingColumns(columnNumbers),
      );
    } else {
      return {
        firstContentRow: rowCounter,
        columnNumbers,
      };
    }
  }

  private readSubmittalData(
    contractId: string,
    data: unknown[][],
    headerShape: xlsxShape,
    specificationList: IPRFISpecification[],
    documenttypeList: SubmittalDocumentType[],
  ): SubmittalCreate[] {
    const dataWOHeaders = data.slice(headerShape.firstContentRow);
    const submittals = dataWOHeaders.map((row, index) => {
      const xlsxRow = headerShape.firstContentRow + index;
      try {
        if (
          !headerShape.columnNumbers.every(
            (colIndex) => row[colIndex] !== undefined,
          )
        ) {
          throw new Error("Not all fields were found");
        }

        const newDraft = this.generateSubmittal(
          contractId,
          headerShape.columnNumbers.map((col) => row[col]),
          specificationList,
          documenttypeList,
        );
        console.log(`Row ${xlsxRow}: The row was processed successfully`);

        return newDraft;
      } catch (e) {
        console.warn(
          `Row ${xlsxRow}: ${(e as Error).message} - the row was skipped`,
        );
        return undefined;
      }
    });
    return submittals.filter((s) => s !== undefined);
  }

  private generateSubmittal(
    contractId: string,
    fields: unknown[],
    specificationList: IPRFISpecification[],
    documenttypeList: SubmittalDocumentType[],
  ) {
    const title = fields[0] as string;
    let specId: string | undefined;
    let typeId: string | undefined;
    let dueDate: string | undefined;

    try {
      specId = specificationList.find((s) =>
        (fields[1] as string).includes(s.SpecId),
      ).Guid;
    } catch (e) {
      throw new Error("Specification could not be parsed");
    }

    try {
      const docType = this.parseCellForMatching(fields[2]);
      typeId = documenttypeList.find((s) =>
        (docType as string).includes(s.Title),
      ).Guid;
    } catch (e) {
      throw new Error("Type could not be parsed");
    }

    try {
      dueDate = (fields[3] as Date).toISOString();
    } catch (e) {
      throw new Error("Due Date could not be parsed");
    }

    return new SubmittalCreate(
      contractId as string,
      {
        ContractId: String(contractId),
        submittal_create: {
          IsDraft: true,
          ScheduledSubmittalDate: dueDate,
          SpecificationId: specId,
          SubmittalTypeId: typeId,
          Title: title,
        } as unknown as ISubCreateStep,
      } as unknown as IPSubItem,
    );
  }

  private createSubmittalDrafts(
    draftSubmittals: SubmittalCreate[],
  ): Subscription {
    return of(...draftSubmittals)
      .pipe(
        concatMap((draft) => this.submittalService.subUpdate(draft)),
        take(draftSubmittals.length),
        // take(2),
        finalize(() => this.loadingService.stop()),
      )
      .subscribe();
  }

  private headerFound(columnNumbers: number[]): boolean {
    return (
      columnNumbers.every((n) => n !== -1) &&
      columnNumbers.length === this.columnNames.length
    );
  }

  private findMissingColumns(columnNumbers: number[]): string {
    return this.getAllIndexes(columnNumbers, -1)
      .map((index) => '"' + this.columnNames[index] + '"')
      .join(", ")
      .trim();
  }

  private getAllIndexes(arr: unknown[], val: unknown): number[] {
    const indexes = [];
    let i;
    for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
    return indexes;
  }

  private cleanLookups(
    lookups: (IPRFISpecification | SubmittalDocumentType)[],
  ): (IPRFISpecification | SubmittalDocumentType)[] {
    return lookups.map((lookup) => {
      const newLookup = lookup;
      newLookup.Title = this.parseCellForMatching(lookup.Title);
      return newLookup;
    });
  }

  private parseCellForMatching(data: unknown): string {
    let strVal = String(data);
    strVal = (strVal.match(/[a-z]/gi) || []).join("").toLowerCase();
    return strVal;
  }
}
