import { FileModel, ModalContainerService, NotificationComponent, NotificationType, UploadStatus } from "@aecom/core";
import { Component } from "@angular/core";
import AuthService from "@auth/auth.service";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalReopenStep from "@models/submittalReopenStep";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import AzureBlobService from "@services/azureBlob.service";
import LocalContractUserService from "@services/local-contractUser.service";
import { getVersionString, isFileNameInvalid } from "@shared/utils";
import _ from "lodash";
import { IPSubDocumentUploadReturn, SubmittalDocumentsService } from "src/app/api-generated";

@Component({
  selector: "app-reopen-modal",
  templateUrl: "./reopen-modal.component.html",
  styleUrls: ["./reopen-modal.component.scss"]
})
export default class ReopenModalComponent {
  item: SubmittalReopenStep;
  attemptToIssue = false;

  fileList: FileModel[] = [];

  fileListNameValid = true;

  private removedFileIds: string[] = [];

  constructor(
    private activeModal: ModalContainerService,
    public authService: AuthService,
    public submittalDocumentService: SubmittalDocumentsService,
    public localContractUserService: LocalContractUserService,
  ) {}

  save() {
    this.attemptToIssue = true;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else if (this.isValid()) {
      if(!this.item.submittal_reopen.ChangeType.includes('other'))
      {
        delete this.item.submittal_reopen.OtherChangeType;
      }
      this.updateFiles();
      this.activeModal.close(this.item);
    }
  }

  isValid(): boolean {
    const role = this.localContractUserService.currentUserContractRole;
    const isDoc = role === SubmittalRole.Doc_Control;
    return this.item.submittal_reopen.ChangeType?.length > 0 
      && this.item.submittal_reopen.Reason?.length > 0 
      && this.item.submittal_reopen.Reason.length <= 2000 
      && this.isFileNameValid()
      && (isDoc ? !_.isEmpty(this.item.submittal_reopen.DirectedBy) : true)
      // && this.item.submittal_reopen.Significance != undefined
  }

  cancel() {
    this.activeModal.close(null);
  }

  updateFiles(): void {
    const filesToAdd = [...this.fileList].filter((f) => {
      return f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    this.item.docSave = [...new Set([...filesToAdd])];
    this.item.docRemove = [...new Set([...this.removedFileIds])];
  }

  importFile(e: FileModel[]): void {
    const currentUser = this.authService.user.Id;
    if (!_.isEmpty(e)) {
      // console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            currentUser,
            SubmittalFileType.Attachment,
            getVersionString(this.item.SubmittalNumber),
            SubmittalStatus.Request_to_Reopen,
            this.item.Guid,
            this.item.tempId,
          );

          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item);
              AzureBlobService.uploadFile(r.URL, item);
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item);
        }
      });
    }
  }

  deleteFile(e: FileModel): void {
    this.updateFileList(e, true);
  }

  updateFileList(item: FileModel, isRemove = false): void {
    const index = this.fileList.findIndex((file) => {
      return file.Name === item.Name;
    });
    if (isRemove) {
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    } else if (index === -1) {
      this.fileList.push(item);
      this.fileList = this.fileList.slice();
    }
    if(isRemove)
    {
      this.removedFileIds.push(item.Guid);
    }
    console.log("filelise:",this.fileList);
    console.log("removeFiles:",this.removedFileIds);
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.fileListNameValid = true;
    this.fileList.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.fileListNameValid = false;
      }
    });
    return this.fileListNameValid;
  }

  hasFilesUploading(): boolean {
    return this.fileList.some((item) => item.Status === UploadStatus.UPLOADING);
  }

  showFileProgressNotification(): void {
    const body = "Please wait until all your files have been uploaded";
    const title = "File upload in progress";
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = title;
    modalInstance.instance.body = body;
    modalInstance.result;
  }
}
