<aecom-form *ngIf="isLoaded" columns="12" class="contentcontainer">
  <ng-container *ngIf="isOldVersion; else newContracts">
    <aecom-form-group columnSpan="6" columns="1" title="Assigned Team">
      <aecom-form-item [label]="'Originator'" columnSpan="1">
        <div class="itemText">
          {{ originator?.displayName }} ({{ originator?.mail }})
        </div>
      </aecom-form-item>
  
      <aecom-form-item [label]="'Doc Control'" columnSpan="1">
        <div class="itemText" *ngIf="docControlUser; else notAvailable">
          {{ docControlUser?.displayName }} ({{ docControlUser?.mail }})
        </div>
      </aecom-form-item>
  
      <aecom-form-item [label]="'Manager'" columnSpan="1">
        <div class="itemText" *ngIf="manager; else notAvailable">
          {{ manager.displayName }} ({{ manager.mail }})
        </div>
      </aecom-form-item>
  
      <aecom-form-item [label]="'Coordinator'" columnSpan="1">
        <div class="itemText" *ngIf="coordinator; else notAvailable">
          {{ coordinator.displayName }} ({{ coordinator.mail }})
        </div>
      </aecom-form-item>
    </aecom-form-group>
  
    <aecom-form-group columnSpan="6" columns="1" title="Schedule">
      <aecom-form-item [label]="'Issue Date'" columnSpan="1">
        <div class="itemText" *ngIf="submittal.DateSubmitted; else notProvide">
          {{ getAccTimeDate(submittal.DateSubmitted) }}
        </div>
      </aecom-form-item>
  
      <aecom-form-item *ngIf="!isDBAdmin" [label]="'Internal Due'" columnSpan="1">
        <div
          class="itemText"
          *ngIf="submittal.InternalDueDate; else notProvide"
        >
          {{ getFixTimeDate(submittal.InternalDueDate) }}
        </div>
      </aecom-form-item>
  
      <aecom-form-item [label]="'Due Date'" columnSpan="1">
        <div
          class="itemText"
          *ngIf="submittal.submittal_create && submittal.submittal_create.length > 0 && submittal.submittal_create[0].ScheduledReviewedDate; else notProvide"
        >
          {{ getFixTimeDate(submittal.submittal_create[0].ScheduledReviewedDate) }}
        </div>
      </aecom-form-item>
  
      <aecom-form-item [label]="'Response Date'" columnSpan="1">
        <div class="itemText" *ngIf="submittal.DateReviewed; else notProvide">
          {{ getAccTimeDate(submittal.DateReviewed) }}
        </div>
      </aecom-form-item>
    </aecom-form-group>
    <div style="grid-column: span 6 / auto;">
      <div class="marginTop">
        <aecom-form-item>
          <file-list
            *ngIf="coverSheet.length"
            [fileList]="coverSheet"
            title="Cover Sheet"
            [maxCharacters]="maxCharacters"
            [allowMovingFiles]="false"
            [showSeparator]="true"
          ></file-list>
        </aecom-form-item>
      </div>
      <div class="marginTop">
        <aecom-form-item>
          <file-list
            *ngIf="submittalDocuments.length"
            title="Attachments"
            [fileList]="submittalDocuments"
            [maxCharacters]="maxCharacters"
            [allowMovingFiles]="false"
            [showSeparator]="true"
          ></file-list>
        </aecom-form-item>
      </div>
    </div>
    
    <aecom-form-group
      columnSpan="6"
      columns="1"
      title="Additional Info"
      class="marginTop"
    >
      <aecom-form-item [label]="'Division'" columnSpan="1">
        <div class="itemText">
          {{ division }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'Specification'" columnSpan="1">
        <div class="itemText">
          {{ specification }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'Document Type'" columnSpan="1">
        <div class="itemText">
          {{ documentType }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'Submittal Type'" columnSpan="1">
        <div class="itemText">
          {{ submittalType }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'Drawing/Page Count'" columnSpan="1">
        <div class="itemText">
          {{ drawingPage }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'Priority Note'" columnSpan="1">
        <div class="itemText" *ngIf="priorityNote !== ''; else notAvailable">
          {{ priorityNote }}
        </div>
      </aecom-form-item>
    </aecom-form-group>
    <aecom-form-group class="marginTop" title="BIM URL" columnSpan="6" columns="1">
      <div class="itemText" style="word-break: break-all;" *ngIf="BIM360 !== ''; else notAvailable">
        <a [href]="BIM360" target="_blank" style="text-decoration: underline!important">{{ BIM360 }}</a>
      </div>
    </aecom-form-group>
    <aecom-form-group class="marginTop" title="References" columnSpan="6" columns="1">
      <aecom-form-item [label]="'Organization Reference Number'" columnSpan="1">
        <div class="itemText" *ngIf="referenceNumber !== ''; else notAvailable">
          {{ referenceNumber }}
        </div>
      </aecom-form-item>
      <aecom-form-item [label]="'Reference Note'" columnSpan="1">
        <div class="itemText" *ngIf="referenceNote !== ''; else notAvailable">
          {{ referenceNote }}
        </div>
      </aecom-form-item>
    </aecom-form-group>
  </ng-container>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>

<ng-template #notProvide>
  <div class="itemText">-</div>
</ng-template>

<ng-template #newMetadata>
  <aecom-form-item [label]="'Review Type'" columnSpan="1">
    <div class="itemText">
      {{ submittalType }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'Drawing/Page Count'" columnSpan="1">
    <div class="itemText">
      {{ drawingPage }}
    </div>
  </aecom-form-item>
  <aecom-form-item *ngIf="showPhaseAndContractRequirement" [label]="'Phase'" columnSpan="1">
    <div class="itemText" *ngIf="phase !== ''; else notAvailable">
      {{ phase }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'Document Type'" columnSpan="1">
    <div class="itemText">
      {{ documentType }}
    </div>
  </aecom-form-item>
  <aecom-form-item *ngIf="showPhaseAndContractRequirement" [label]="'Contract Requirements'" columnSpan="1">
    <div class="itemText" *ngIf="contractRequirement !== ''; else notAvailable">
      {{ contractRequirement }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'UniFormat Level 1 Category'" columnSpan="1">
    <div class="itemText" *ngIf="division !== ''; else notAvailable">
      {{ division }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'UniFormat Level 2 Category'" columnSpan="1">
    <div class="itemText" *ngIf="specification !== ''; else notAvailable">
      {{ specification }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'MasterFormat Specification Section (6-digit)'" columnSpan="1">
    <div class="itemText" *ngIf="masterSpec !== ''; else notAvailable">
      {{ masterSpec }}
    </div>
  </aecom-form-item>
</ng-template>

<ng-template #newAdditionalInfo>
  <aecom-form-item [label]="'Internal Reference Number'" columnSpan="1">
    <div class="itemText" *ngIf="referenceNumber !== ''; else notAvailable">
      {{ referenceNumber }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'Priority Note'" columnSpan="1">
    <div class="itemText" *ngIf="priorityNote !== ''; else notAvailable">
      {{ priorityNote }}
    </div>
  </aecom-form-item>
  <aecom-form-item [label]="'Processing Note'" columnSpan="1">
    <div class="itemText" *ngIf="referenceNote !== ''; else notAvailable">
      {{ referenceNote }}
    </div>
  </aecom-form-item>
</ng-template>

<ng-template #newContracts>
  <aecom-form-group columnSpan="12" columns="2" title="Summary">
    <aecom-form-item [label]="'Issued By'" columnSpan="1">
      <div class="itemText">
        {{ originator?.displayName }} ({{ originator?.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Issue Date'" columnSpan="1">
      <div class="itemText" *ngIf="submittal.DateSubmitted; else notProvide">
        {{ getAccTimeDate(submittal.DateSubmitted) }}
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Responded By'" columnSpan="1">
      <div class="itemText" *ngIf="responseBy; else notProvide">
        {{ responseBy.displayName }} ({{ responseBy.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Response Date'" columnSpan="1">
      <div class="itemText" *ngIf="submittal.DateReviewed; else notProvide">
        {{ getAccTimeDate(submittal.DateReviewed) }}
      </div>
    </aecom-form-item>
  </aecom-form-group>

  <div style="grid-column: span 6 / auto;">
    <div class="marginTop">
      <aecom-form-item>
        <file-list
          *ngIf="coverSheet.length"
          [fileList]="coverSheet"
          title="Cover Sheet"
          [maxCharacters]="maxCharacters"
          [allowMovingFiles]="false"
          [showSeparator]="true"
        ></file-list>
      </aecom-form-item>
    </div>
  </div>
  <div style="grid-column: span 6 / auto;">
    <div class="marginTop">
      <aecom-form-item>
        <file-list
          *ngIf="submittalDocuments.length"
          title="Attachments"
          [fileList]="submittalDocuments"
          [maxCharacters]="maxCharacters"
          [allowMovingFiles]="false"
          [showSeparator]="true"
        ></file-list>
      </aecom-form-item>
    </div>
  </div>
  <aecom-form-group class="marginTop" [title]="'BIM Submission'" columnSpan="6" columns="1" *ngIf="BIM360 !== ''">
    <div class="itemText" style="word-break: break-all;">
      <a [href]="BIM360" target="_blank" style="text-decoration: underline!important">{{ BIM360 }}</a>
    </div>
  </aecom-form-group>
  <aecom-form-group
    columnSpan="12"
    columns="2"
    [title]="'Metadata'"
    class="marginTop"
  >
    <aecom-form-item [label]="'Review Type'" columnSpan="1">
      <div class="itemText">
        {{ submittalType }}
      </div>
    </aecom-form-item>
    <aecom-form-item *ngIf="showPhaseAndContractRequirement" [label]="'Contract Requirements'" columnSpan="1">
      <div class="itemText" *ngIf="contractRequirement !== ''; else notAvailable">
        {{ contractRequirement }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Drawing/Page Count'" columnSpan="1">
      <div class="itemText">
        {{ drawingPage }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'UniFormat Level 1 Category'" columnSpan="1">
      <div class="itemText" *ngIf="division !== ''; else notAvailable">
        {{ division }}
      </div>
    </aecom-form-item>
    <aecom-form-item *ngIf="showPhaseAndContractRequirement" [label]="'Phase'" columnSpan="1">
      <div class="itemText" *ngIf="phase !== ''; else notAvailable">
        {{ phase }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'UniFormat Level 2 Category'" columnSpan="1">
      <div class="itemText" *ngIf="specification !== ''; else notAvailable">
        {{ specification }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Document Type'" columnSpan="1">
      <div class="itemText">
        {{ documentType }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'MasterFormat Specification Section (6-digit)'" columnSpan="1">
      <div class="itemText" *ngIf="masterSpec !== ''; else notAvailable">
        {{ masterSpec }}
      </div>
    </aecom-form-item>
  </aecom-form-group>

  <aecom-form-group class="marginTop" [title]="'ADDITIONAL DETAILS'" columnSpan="12" columns="1">
    <aecom-form-item [label]="'Internal Reference Number'" columnSpan="1">
      <div class="itemText" *ngIf="referenceNumber !== ''; else notAvailable">
        {{ referenceNumber }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Priority Note'" columnSpan="1">
      <div class="itemText" *ngIf="priorityNote !== ''; else notAvailable" [innerHtml]="priorityNote">
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Processing Note'" columnSpan="1">
      <div class="itemText" *ngIf="referenceNote !== ''; else notAvailable" [innerHtml]="referenceNote">
      </div>
    </aecom-form-item>
  </aecom-form-group>
</ng-template>