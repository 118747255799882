enum SubmittalRole {
  DBAdmin = '8a013207-0baf-11ea-917a-027e1b1809fa',
  Doc_Control = '8a013208-0baf-11ea-917a-027e1b1809fa',
  Manager = '8a013209-0baf-11ea-917a-027e1b1809fa',
  Coordinator = '8a013210-0baf-11ea-917a-027e1b1809fa',
  Reviewer = 'Reviewer',
  Watcher = 'Watcher',
  DDC_Manager='8a013212-0baf-11ea-917a-027e1b1809fa',
  User='8a013214-0baf-11ea-917a-027e1b1809fa',
  SR_Manager = '8a013226-0baf-11ea-917a-027e1b1809fa',
}

export default SubmittalRole;