import { FileModel } from "@aecom/core";
import { IPSubDocumentDownload } from "../api-generated";

export default class FileModelWithVersion extends FileModel {
  public Version: string;

  constructor(docs: IPSubDocumentDownload) {
    super(
      docs.Guid,
      docs.FileName,
      docs.DateCreated,
      docs.URL,
      "preloaded",
      0,
      null,
      docs.DownloadURL,
      docs.Size
    );

    this.Version = docs.Submittal_version ?? "";
  }
}
