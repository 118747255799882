<div class="contentBox">
  <div class="contentTitle">{{title}}</div>
  <ng-container *ngFor="let item of items">
    <div class="responseBox">
      <div class="responseHeader">
        <span>Rev.{{ item.RevisionNumber.replace("REV", "") }}</span>
        <span
          *ngIf="
          item.Name && item.Name !== ''
          "
          ><span class="responseHeaderSeparate">|</span
          >{{ item.Name }}</span
        >
        <span
          *ngIf="
            item.Organization && item.Organization !== ''
          "
          ><span class="responseHeaderSeparate_b">|</span
          >{{ item.Organization }}</span
        >
        <span *ngIf="item.Department && item.Department !== ''"
          ><span class="responseHeaderSeparate_b">|</span
          >{{ item.Department }}</span
        >
        <div class="responseHeaderDate">{{ item.FormatedDateUpdated }}</div>
        <div
          class="responseHeaderCollapse"
          (click)="item.Collapse = !item.Collapse"
        >
          <i
            [ngClass]="
              item.Collapse
                ? 'icon-action_arrow_down'
                : 'icon-action_arrow_up'
            "
            class="icon icon-blue icon-lg"
          ></i>
        </div>
      </div>
      <div class="responseContent" *ngIf="!item.Collapse">
        <div class="responseBody">
          <div class="responseBodySubTitle">{{isDisposition ? 'Final Disposition' : 'Recommended Disposition'}}</div>
          <div class="responseBodySubContent">
            <div class="dispositionText">{{item.DispositionText}}</div>
          </div>
        </div>
        <div class="responseBody">
          <div class="responseBodySubTitle">{{isDraft ? 'Note For DB' : 'Note'}}</div>
          <div class="responseBodySubContent" [innerHtml]="item.Note"></div>
        </div>
        <div class="responseBody" *ngIf="item.CoverSheet && item.CoverSheet.length > 0">
          <file-list
            [fileList]="item.CoverSheet"
            [showDetail]="true"
            [allowMovingFiles]="allowMovingFiles"
            title="Cover Sheet & Stamp Signed"
            (movedFile)="coverSheetOut($event)"
            (movedFiles)="coverSheetsOut($event)"
          ></file-list>
        </div>
        <div class="responseBody" *ngIf="item.CommentResolutionForm && item.CommentResolutionForm.length > 0">
          <file-list
            [fileList]="item.CommentResolutionForm"
            [showDetail]="true"
            [allowMovingFiles]="allowMovingFiles"
            title="Comment Resolution Form"
            (movedFile)="resolutionFormOut($event)"
            (movedFiles)="resolutionFormsOut($event)"
          ></file-list>
        </div>
        <div *ngIf="item.MarkedUpComments && item.MarkedUpComments.length > 0">
          <file-list
            [fileList]="item.MarkedUpComments"
            [showDetail]="true"
            [allowMovingFiles]="allowMovingFiles"
            title="Additional Files"
            (movedFile)="commentOut($event)"
            (movedFiles)="commentsOut($event)"
          ></file-list>
        </div>
      </div>
    </div>
  </ng-container>
</div>