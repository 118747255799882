import { IPSubItem, ISubReopenStep, SubmittalReopen } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalReopenDetail from "./submittalReopenDetail";

export default class SubmittalReopenStep
  extends SubmittalItemUpdateBase
  implements ISubReopenStep
{
  public submittal_reopen: SubmittalReopenDetail;

  public tempId?: string;

  constructor(contractId: string, sub: IPSubItem, item?: SubmittalReopen) {
    super(contractId, sub);
    this.submittal_reopen = new SubmittalReopenDetail(item);
  }
}
