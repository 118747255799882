import { SubmittalBluebeamDetail } from "../api-generated";
import { ISubBluebeamDetail } from "../api-generated/model/iSubBluebeamDetail";
import TimeStamp from "./timeStamp";

export default class SubmittalBluebeam
  extends TimeStamp
  implements ISubBluebeamDetail
{
  public Guid?: string;
  public SessionId?: string;
  public SessionName?: string;
  public Due?: string;
  public InvitationMessage?: string;

  constructor(item?: SubmittalBluebeamDetail) {
    super(item);

    this.Guid = item?.Guid;
    this.SessionId = item?.SessionId;
    this.SessionName = item?.SessionName ?? "";
    this.Due = item?.Due ?? new Date().toISOString();
    this.InvitationMessage = item?.InvitationMessage ?? "";
  }
}
