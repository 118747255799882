import { ISelectItem } from "@aecom/core";
import { getDateFormatted_MMDDYYYY, getReportDate, prepareMutiLineText } from "@shared/utils";
import { IPSubItem, SubmittalDocumentType, SubmittalType, TPContract } from "../api-generated";
import failureReason from "./failureReasons";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import SubmittalRejectDetails from "./submittalRejectDetail";

export default class SubmittalFailureForm
{
  public FormTitle: string;

  public DB: string | null;

  public Ref: string | null;

  public SubNo: string | null;

  public SubTitle: string | null;

  public DocType: string | null;

  public DateReceived: string | null;

  public ReviewType: string | null;

  public ReviewedBy: string | null;

  public IssuedDate: string | null;

  public Remarks: string | null;

  public FailureReason: ISelectItem[];

  constructor(userId: string, contract: TPContract, item: IPSubItem, contractUsers: IContractUserWithUserInfo[], documentTypes: SubmittalDocumentType[], submittalTypes: SubmittalType[], failureForm: SubmittalRejectDetails) {
    const today = new Date();
    this.FormTitle = `NYC BBJ Program  |  ${contract.Name}  |  [FMS ID: BBJ-${contract.Code}]`.toLocaleUpperCase();
    this.DB = contractUsers.find((u)=>u.UserId === item.SubmittedBy)?.UserInfo?.companyName ?? null;
    this.SubNo = item.SubmittalNumber ?? null;
    this.DateReceived = item.DateSubmitted ? getReportDate(item.DateSubmitted) : null;
    this.ReviewedBy = contractUsers.find((u)=>u.UserId === userId)?.UserInfo?.displayName ?? null;
    if(item.submittal_create && item.submittal_create.length > 0)
    {
      this.Ref = item.submittal_create[0].ReferenceNumber ?? null;
      this.SubTitle = item.submittal_create[0].Title ?? null;
      this.DocType = documentTypes.find((d)=>d.Guid===item.submittal_create[0].DocumentTypeId)?.Title ?? null;
      this.ReviewType = submittalTypes.find((t)=>t.Guid===item.submittal_create[0].SubmittalTypeId)?.Title ?? null;
    }
    this.IssuedDate = getDateFormatted_MMDDYYYY(today);
    this.Remarks = failureForm.Note ? prepareMutiLineText(failureForm.Note) : null;
    this.FailureReason = this.initFailureReasonStamp(failureForm.Reason ?? '');
  }

  private initFailureReasonStamp(reason: string): ISelectItem[] {
    const arr = [...failureReason];
    return arr.map((a)=>{
      return { ...a, checked: reason.includes(a.id) };
    });
  }
}
