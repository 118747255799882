import { ISelectItem } from "@aecom/core";
import { reviewSubmittalTypeId } from "@shared/staticValue";
import { getDateFormatted_MMDDYYYY, getReportDate, includeDenied, prepareMutiLineText } from "@shared/utils";
import { IPRFIContractRequirement, IPSubItem, Phase, SubmittalDocumentType, SubmittalPage, SubmittalType, TPContract } from "../api-generated";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import SubmittalDisposition from "./submittalDisposition";
import SubmittalStatus from "./submittalStatus";

export default class SubmittalDispositionForm
{
  public FormTitle: string;

  public FMSTitle: string;

  public DB: string | null;

  public Ref: string | null;

  public SubNo: string | null;

  public SubTitle: string | null;

  public Phase: string | null;

  public ContractRequirement: string | null;

  public Page: string | null;

  public DocType: string | null;

  public DateReceived: string | null;

  public ReviewType: string | null;

  public IssuedBy: string | null;

  public IssuedDate: string | null;

  public RespondedBy: string | null;

  public RespondedName: string | null;

  public RespondedDate: string | null;

  public InitRespondedDate: string | null;

  public Note: string | null;

  public DispositionStamp: ISelectItem[];

  constructor(
    contract: TPContract, 
    item: IPSubItem, 
    contractUsers: IContractUserWithUserInfo[], 
    documentTypes: SubmittalDocumentType[], 
    submittalTypes: SubmittalType[], 
    phases: Phase[], 
    contractRequirements: IPRFIContractRequirement[], 
    pages: SubmittalPage[], 
    disposition: SubmittalDisposition
    ) {
    const today = new Date();
    this.FormTitle = `BBJ Program  |  Contract: ${contract.Name}`.toLocaleUpperCase();
    this.FMSTitle = `FMS ID:  BBJ-${contract.Code}`.toLocaleUpperCase();
    this.DB = contractUsers.find((u)=>u.UserId === item.SubmittedBy)?.UserInfo?.companyName ?? null;
    this.SubNo = item.SubmittalNumber ?? null;
    this.IssuedDate = item.DateSubmitted ? getDateFormatted_MMDDYYYY(item.DateSubmitted) : null;
    const responseUser = contractUsers.find((u)=>u.UserId === (item.Status === SubmittalStatus.DDC_Disposition ? item.DDCManagerId : (item.Status === SubmittalStatus.SPM_Review ? item.SrManagerId : item.ManagerId)));
    if(responseUser?.UserInfo)
    {
      this.RespondedBy = `${responseUser.UserInfo.displayName??''}, ${responseUser.UserInfo.companyName??''}/${responseUser.UserInfo.department??''}`;
      this.RespondedName = responseUser.UserInfo.displayName??'';
    } 
    else
    {
      this.RespondedBy = "";
      this.RespondedName = "";
    }
    const issueUser = contractUsers.find((u)=>u.UserId === item.SubmittedBy);
    if(issueUser?.UserInfo)
    {
      this.IssuedBy = `${issueUser.UserInfo.displayName??''}, ${issueUser.UserInfo.companyName??''}/${issueUser.UserInfo.department??''}`;
    }
    else
    {
      this.IssuedBy = "";
    }
    
    if(item.submittal_create && item.submittal_create.length > 0)
    {
      this.Ref = item.submittal_create[0].ReferenceNumber ?? null;
      this.SubTitle = item.submittal_create[0].Title ?? null;
      this.DocType = documentTypes.find((d)=>d.Guid===item.submittal_create[0].DocumentTypeId)?.Title ?? null;
      this.ReviewType = submittalTypes.find((t)=>t.Guid===item.submittal_create[0].SubmittalTypeId)?.Title ?? null;
      this.Phase = phases.find((t)=>t.Guid===item.submittal_create[0].PhaseId)?.Title ?? null;
      this.ContractRequirement = contractRequirements.find((t)=>t.Guid===item.submittal_create[0].ContractRequirementId)?.Name ?? null;
      this.Page = pages.find((t)=>t.Guid===item.submittal_create[0].DrawingPageId)?.Title ?? null;
      this.DispositionStamp = this.initDispositionStamp(disposition.submittal_disposition.Disposition, item.submittal_create[0].SubmittalTypeId === reviewSubmittalTypeId, includeDenied(this.DocType));
    }
    this.RespondedDate = getDateFormatted_MMDDYYYY(today);
    this.InitRespondedDate = this.getInitialResponseDate(item);
    this.Note = disposition.submittal_disposition.Note ? prepareMutiLineText(disposition.submittal_disposition.Note) : null;
  }

  private initDispositionStamp(disposition: number, isReview: boolean, addDenied: boolean): ISelectItem[] {
    const arr = ['Approved', 'Reviewed', 'Revise and Resubmit', 'Approved with Comments', 'Reviewed with Comments'];
    if(addDenied)
    {
      arr.push('Denied');
    }
    return arr.map((a, i)=>{
      let checked = false;
      switch(i)
      {
        case 0:
          checked = disposition === 2 && !isReview;
          break;
        case 1:
          checked = disposition === 2 && isReview;
          break;
        case 2:
          checked = disposition === 1;
          break;
        case 3:
          checked = disposition === 3 && !isReview;
          break;
        case 4:
          checked = disposition === 3 && isReview;
          break;
        case 5:
          checked = disposition === 4;
          break;
        default:
          break;
      }
      return {id: a.replace(' ', '_').toLocaleLowerCase(), name: a, checked };
    });
  }

  private getInitialResponseDate(item: IPSubItem): string | null {
    const flows = item.submittal_flow.sort((a,b)=>(a.DateReceived?new Date(a.DateReceived).getTime():0)-(b.DateReceived?new Date(b.DateReceived).getTime():0));
    const statusArr: string[] = [SubmittalStatus.Canceled,SubmittalStatus.Denied];
    const canResponseStatusArr: string[] = [SubmittalStatus.Disposition,SubmittalStatus.SPM_Review,SubmittalStatus.DDC_Disposition];
    const reopenIndex = flows.findIndex((f,i,a)=>{
      return f.Status===SubmittalStatus.Request_to_Reopen && 
        a[i-1] && 
        (!statusArr.includes(a[i-1].Status) || (a[i-1].Status===SubmittalStatus.Denied && a[i-2] && canResponseStatusArr.includes(a[i-2].Status)))
        
    });
    if(reopenIndex > 0)
    {
      const initResponseDate = flows[reopenIndex-1].DateReceived;
      return initResponseDate ? getDateFormatted_MMDDYYYY(initResponseDate) : null;
    }
    return null;
  }
}
