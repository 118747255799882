import { ISubReopenApproval, SubmittalReopen } from "../api-generated";

export default class SubmittalReopenApprovalDetail
  implements ISubReopenApproval
{
  public Guid: string;

  constructor(item: SubmittalReopen) {
    this.Guid = item.Guid;
  }
}
