import { ISubDisposition, SubmittalCompile, SubmittalDisposition } from "../api-generated";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class SubmittalDispositionBase
  extends TimeStampWithDraft
  implements ISubDisposition
{
  public Guid?: string;

  public Disposition: number;

  public Note?: string;

  public ToDDC?: boolean;

  public ToSrManager?: boolean;

  constructor(
    defaultSrYes: boolean,
    isSRReview: boolean,
    isDDCReview: boolean,
    item?: SubmittalDisposition | SubmittalCompile,
  ) {
    super(item);
    this.Guid = (item?.IsDraft === true && item?.Guid) ? item.Guid : undefined;

    this.Disposition = item?.Disposition;

    this.Note = item?.Note ?? "";

    if(this.instanceOfDisposition(item))
    {
      this.ToDDC = item?.ToDDC ?? false;

      this.ToSrManager = item?.ToSrManager ?? false;
    }
    else{
      this.ToDDC = false;

      this.ToSrManager = false;
    }
    if(defaultSrYes)
    {
      this.ToSrManager = true;
    }
    if(isDDCReview)
    {
      this.ToDDC = undefined;
      this.ToSrManager = undefined;
    } else if(isSRReview)
    {
      this.ToSrManager = undefined;
    }
  }

  private instanceOfDisposition(object): object is SubmittalDisposition {
    return 'ToDDC' in object;
  }
}
