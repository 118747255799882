import LocalContractUserService from "@services/local-contractUser.service";
import {
  IPSubDocumentDownload,
  IPSubItem,
  SubmittalCompile,
  SubmittalDisposition,
  SubmittalNotes
} from "../api-generated";
import SubmittalRole from "./submittalRole";
import { getDispositionText, getFilesByType, prepareMutiLineText } from "@shared/utils";
import FileModelWithVersion from "./fileModelWithVersion";
import SubmittalFileType from "./submittalFileType";
import LocalApplicationRoleService from "@services/local-application_role.service";
import SubmittalStatus from "./submittalStatus";

export default class NotesListItem {
  public Name: string;

  public Role: string;

  public Title: string;

  public FormatedDateUpdated: string;

  public Notes: string;

  public Collapse: boolean;

  public Sort: number;

  public IsBack: boolean;

  public DispositionText?: string;

  public CoverSheet?: FileModelWithVersion[];

  public CommentResolutionForm?: FileModelWithVersion[];

  public MarkedUpComments?: FileModelWithVersion[];

  constructor(item: SubmittalNotes | SubmittalCompile | SubmittalDisposition, localContractUserService: LocalContractUserService, localApplicationRoleServices: LocalApplicationRoleService, submittal: IPSubItem, documents: IPSubDocumentDownload[]) {
    if(this.instanceOf_SubmittalNotes(item))
    {
      const user = localContractUserService.getUserById(item.NotedBy)?.UserInfo;
      this.Name = user?.displayName ?? "";
      this.Role = localApplicationRoleServices.getRoleById(item.Role);
      switch(item.Status)
      {
        case SubmittalStatus.Compiling_Disposition:
        case SubmittalStatus.Disposition:
        case SubmittalStatus.SPM_Review:
        case SubmittalStatus.DDC_Disposition:
          this.IsBack = true;
          this.Title = "Send Back Note to Coordinator"
          break;
        default:
          this.IsBack = false;
          break;
      }
      this.Notes = prepareMutiLineText(item.Notes);
      this.FormatedDateUpdated = item.NotedDate
        ? `${new Date(item.NotedDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })} ${new Date(item.NotedDate).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          })}`
        : "-";
      this.Sort = item.NotedDate ? new Date(item.NotedDate).getTime() : -1;
    }
    else
    {
      this.IsBack = false;
      if (item.UpdatedBy) {
        switch (item.UpdatedBy) {
          case submittal.ManagerId:
            const manager = localContractUserService.getUserById(
              submittal.ManagerId,
            )?.UserInfo;
  
            this.Name = manager?.displayName ?? "";
            this.Role = `${localApplicationRoleServices.getRoleById(SubmittalRole.Manager)}`;
            break;
          case submittal.SrManagerId:
            const srManager = localContractUserService.getUserById(
              submittal.SrManagerId,
            )?.UserInfo;
  
            this.Name = srManager?.displayName ?? "";
            this.Role = `${localApplicationRoleServices.getRoleById(SubmittalRole.SR_Manager)}`;
            break;
          case submittal.DDCManagerId:
            const ddcManager = localContractUserService.getUserById(
              submittal.DDCManagerId,
            )?.UserInfo;
  
            this.Name = ddcManager?.displayName ?? "";
            this.Role = `${localApplicationRoleServices.getRoleById(SubmittalRole.DDC_Manager)}`;
            break;
          case submittal.CoordinatorId:
            const coordinator = localContractUserService.getUserById(
              submittal.CoordinatorId,
            )?.UserInfo;
  
            this.Name = coordinator?.displayName ?? "";
            this.Role = `${localApplicationRoleServices.getRoleById(SubmittalRole.Coordinator)}`;
            break;
          default:
            const doc = localContractUserService.getUserById(
              submittal.DocControlId,
            )?.UserInfo;
  
            this.Name = doc?.displayName ?? "";
            this.Role = `${localApplicationRoleServices.getRoleById(SubmittalRole.Doc_Control)}`;
            break;
        }
      }
      // this.Title = this.instanceOf_SubmittalDisposition(item) ? "Note for DB" : "Note to Manager";
      this.Title = "Note for DB";
      this.FormatedDateUpdated = item.DateUpdated
        ? `${new Date(item.DateUpdated).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })} ${new Date(item.DateUpdated).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          })}`
        : "-";
      this.Notes = prepareMutiLineText(item.Note);
      this.Sort = item.DateUpdated ? new Date(item.DateUpdated).getTime() : -1;
      this.DispositionText = getDispositionText(item.Disposition, submittal.submittal_create[0].SubmittalTypeId);
  
      const documnetsForThis = documents.filter((doc) => {
        return doc.ReferenceId === item.Guid;
      });
  
      this.CoverSheet = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CoverSheetStampSigned,
      );
  
      this.CommentResolutionForm = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CommentResolutionForm,
      );
  
      this.MarkedUpComments = getFilesByType(
        documnetsForThis,
        SubmittalFileType.MarkedUpComments,
      );
    }
    this.Collapse = true;
  }

  instanceOf_SubmittalNotes(object: SubmittalNotes | SubmittalCompile | SubmittalDisposition): object is SubmittalNotes {
    return 'NotedBy' in object;
  }

  instanceOf_SubmittalDisposition(object: SubmittalNotes | SubmittalCompile | SubmittalDisposition): object is SubmittalDisposition {
    return 'ToDDC' in object;
  }
}
