import { IPSubItem, ISubReopenApprovalStep, SubmittalReopen } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalReopenApprovalDetail from "./submittalReopenApprovalDetail";

export default class SubmittalReopenApproval
  extends SubmittalItemUpdateBase
  implements ISubReopenApprovalStep
{
  public submittal_reopen: SubmittalReopenApprovalDetail;

  constructor(contractId: string, sub: IPSubItem, reopen:SubmittalReopen) {
    super(contractId, sub);
    this.submittal_reopen = new SubmittalReopenApprovalDetail(reopen);
  }
}
