<div class="maincontainer" *ngIf="submittal">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [entity]="entity" 
        [docs]="docs"
        [defaultTab]="defaultTab"
        [allowMovingFiles]="false"
      ></leftPanel>
    
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>

              <ng-container *ngIf="isDocControl">
                <aecom-button
                  data-cy="submit"
                  id="submitButton"
                  *ngIf="isDocControl"
                  innerClass="btn lg solid blue"
                  (click)="submit()"
                >
                  Submit
                </aecom-button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form>
            <ng-container
              *ngIf="!(isRecalled || isRejected); else recallReject"
            >
              <aecom-form-group
                *ngIf="!isDocControl"
                columnSpan="1"
                columns="2"
                title="Bluebeam Manager Contact Info"
                style="margin-bottom: 40px"
                class="matchToTab"
              >
                <aecom-form-item [label]="'Name'" columnSpan="1">
                  <div class="button-wrapper d-flex">
                    {{ docControlUser?.displayName || "N/A" }}
                  </div>
                </aecom-form-item>

                <aecom-form-item [label]="'Email Address'" columnSpan="1">
                  <div class="button-wrapper d-flex">
                    <a [href]="'mailto:' + docControlUser?.mail">
                      {{ docControlUser?.mail || "N/A" }}

                      <i class="icon icon-basic_mail"></i>
                    </a>
                  </div>
                </aecom-form-item>
              </aecom-form-group>

              <ng-container *ngIf="isDocControl">
                <aecom-form-group
                  columnSpan="6"
                  columns="4"
                  title="Bluebeam Session Detail Info"
                  style="margin-bottom: 70px"
                >
                  <aecom-form-item [label]="'Session ID'" columnSpan="2">
                    <aecom-textbox
                      data-cy="sessionId"
                      [theme]="'light'"
                      [ngValue]="submittal.submittal_bluebeam_detail.SessionId"
                      (ngValueChange)="setSessionId($event)"
                      [validate]="
                        (canSaveSubmittal &&
                          submittal.submittal_bluebeam_detail.SessionId?.length == 0) ||
                        (canIssueSubmittal &&
                          !isValidBluebeamSessionId(submittal.submittal_bluebeam_detail.SessionId))
                      "
                    ></aecom-textbox>

                    <div
                      *ngIf="
                        (canIssueSubmittal || canSaveSubmittal) &&
                        submittal.submittal_bluebeam_detail.SessionId?.length > 0 &&
                        !isValidBluebeamSessionId(submittal.submittal_bluebeam_detail.SessionId)
                      "
                      class="error-message"
                    >
                      Please enter your Session ID in format: xxx-xxx-xxx.
                    </div>
                  </aecom-form-item>

                  <aecom-form-item [label]="'End Date'" columnSpan="1">
                    <aecom-date
                      data-cy="endDate"
                      [theme]="'light'"
                      [weekendsOff]="true"
                      [ngValue]="bluebeamDueDate"
                      [notEmpty]="true"
                      (change)="setDate($event)"
                      [validate]="!bluebeamDueDate && canIssueSubmittal"
                    ></aecom-date>

                    <div
                      class="error-message"
                      *ngIf="!bluebeamDueDate && canIssueSubmittal"
                    >
                      Please choose correct date.
                    </div>
                  </aecom-form-item>

                  <aecom-form-item
                    data-cy="endTime"
                    [label]="'End Time'"
                    columnSpan="1"
                  >
                    <input
                      type="time"
                      [value]="bluebeamDueTime"
                      (change)="setTime($event)"
                      (input)="setTime($event)"
                    />

                    <div
                      class="error-message"
                      *ngIf="!bluebeamDueTime && canIssueSubmittal"
                    >
                      Required Field
                    </div>
                  </aecom-form-item>
                </aecom-form-group>
                <aecom-form-group
                  id="bluebeamRequestRequired"
                  *ngIf="isRequest"
                  columnSpan="6"
                  columns="2"
                  title="Upload bluebeam comments"
                  style="margin-bottom: 70px"
                >
                  <ng-container *ngIf="commentsFromCoordinator.length == 0">
                    <aecom-form-item columnSpan="1">
                      <aecom-upload
                        data-cy="comments"
                        [isNewStyle]="true"
                        [multiFiles]="true"
                        [canDelete]="true"
                        [data]="newComments"
                        [theme]="'light'"
                        (ngValueChange)="
                          importFile($event, submittalFileType.Comments)
                        "
                        (ngDeleteChange)="
                          deleteFile($event, submittalFileType.Comments)
                        "
                      ></aecom-upload>

                      <div
                        class="error-message"
                        *ngIf="newComments.length === 0 && canIssueSubmittal"
                      >
                        Required Field
                      </div>

                      <div class="error-message" *ngIf="!commentsNameValid">
                        File Name cannot include special characters other than
                        '-', '_', '.', '@'
                      </div>
                    </aecom-form-item>
                  </ng-container>

                  <ng-container *ngIf="commentsFromCoordinator.length > 0">
                    <aecom-form-item
                      [label]="'Note from Coordinator'"
                      columnSpan="1"
                    >
                      <div
                        class="itemText"
                        *ngFor="let comment of commentsFromCoordinator"
                        [innerHtml]="comment"
                      >
                      </div>

                      <aecom-upload
                        data-cy="comments"
                        [isNewStyle]="true"
                        [multiFiles]="true"
                        [canDelete]="true"
                        [data]="newComments"
                        [theme]="'light'"
                        (ngValueChange)="
                          importFile($event, submittalFileType.Comments)
                        "
                        (ngDeleteChange)="
                          deleteFile($event, submittalFileType.Comments)
                        "
                      ></aecom-upload>

                      <div
                        class="error-message"
                        *ngIf="newComments.length === 0 && canIssueSubmittal"
                      >
                        Required Field
                      </div>

                      <div class="error-message" *ngIf="!commentsNameValid">
                        File Name cannot include special characters other than
                        '-', '_', '.', '@'
                      </div>
                    </aecom-form-item>
                  </ng-container>
                </aecom-form-group>

                <aecom-form-group
                  columnSpan="6"
                  columns="1"
                  title="Manage Reviewer(s)"
                  style="margin-bottom: 15px; position: relative"
                  data-cy="assignReviewer"
                >
                  <div style="width: 100%; height: 250px" class="noTableFilter">
                    <aecom-table
                      class="asignUserTable"
                      [ngValue]="reviewerTableData"
                      (ngValueChange)="reviewerTableRows = $event"
                      [theme]="'light'"
                      innerClass="list"
                    >
                      <tbody>
                        <tr
                          *ngFor="let bRow of reviewerTableRows"
                          class="assignUser_tr_hover"
                        >
                          <ng-container *ngFor="let hCol of tableHeader">
                            <td *ngIf="hCol.Type !== columnType.Hide">
                              <span class="assignUser_td_Item">
                                {{ bRow[hCol.Name] }}
                              </span>
                            </td>
                          </ng-container>
                          <td>
                            <a
                              class="btn"
                              (click)="removeUserClick(bRow, 'reviewer')"
                              ><i
                                class="
                                  icon
                                  icon-social_user_remove
                                  icon-orange icon-lg
                                "
                              ></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </aecom-table>

                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                      *ngIf="reviewerTableData.length === 0"
                    >
                      <div class="noItem-body">
                        <div>
                          <div
                            class="
                              noItem-icon-row
                              icon
                              icon-social_user_team_big
                              icon-grey icon-xl
                            "
                          ></div>
                        </div>

                        <div class="noItem-title-row">None</div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="error-message"
                    style="position: absolute; top: -2px; left: 140px"
                    *ngIf="canIssueRFI && reviewerTableData.length === 0"
                  >
                    Required Field
                  </div>

                  <div
                    style="
                      position: absolute;
                      top: 7px;
                      right: 0;
                      cursor: pointer;
                    "
                  >
                    <div
                      data-cy="addReviewer"
                      class="formGroupManu"
                      (click)="openWindowClick('reviewer')"
                    >
                      + Add REVIEWER
                    </div>
                  </div>
                </aecom-form-group>

                <aecom-form-group
                  columnSpan="6"
                  columns="1"
                  title="Manage OBSERVER(s)"
                  style="position: relative"
                  data-cy="assignWatcher"
                >
                  <div style="width: 100%; height: 250px" class="noTableFilter">
                    <aecom-table
                      class="asignUserTable"
                      [ngValue]="watcherTableData"
                      (ngValueChange)="watcherTableRows = $event"
                      [theme]="'light'"
                      innerClass="list"
                    >
                      <tbody>
                        <tr
                          *ngFor="let bRow of watcherTableRows"
                          class="assignUser_tr_hover"
                        >
                          <ng-container *ngFor="let hCol of tableHeader">
                            <td *ngIf="hCol.Type !== columnType.Hide">
                              <span class="assignUser_td_Item">
                                {{ bRow[hCol.Name] }}
                              </span>
                            </td>
                          </ng-container>
                          <td>
                            <a
                              class="btn"
                              (click)="removeUserClick(bRow, 'watcher')"
                              ><i
                                class="
                                  icon
                                  icon-social_user_remove
                                  icon-orange icon-lg
                                "
                              ></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </aecom-table>

                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                      *ngIf="watcherTableData.length === 0"
                    >
                      <div class="noItem-body">
                        <div>
                          <div
                            class="
                              noItem-icon-row
                              icon
                              icon-social_user_team_big
                              icon-grey icon-xl
                            "
                          ></div>
                        </div>
                        <div class="noItem-title-row">None</div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      position: absolute;
                      top: 7px;
                      right: 0;
                      cursor: pointer;
                    "
                  >
                    <div
                      data-cy="addWatcher"
                      class="formGroupManu"
                      (click)="openWindowClick('watcher')"
                    >
                      + Add OBSERVERS
                    </div>
                  </div>
                </aecom-form-group>
              </ng-container>
            </ng-container>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #recallReject>
  <aecom-form-group
    columnSpan="6"
    columns="2"
    title="Recall Request"
    style="margin-bottom: 70px"
  >
    <aecom-form-item
      data-cy="recallCancel"
      *ngIf="isDocControl"
      [label]="'Recall Reason'"
      columnSpan="1"
    >
      <div class="button-wrapper d-flex">
        <aecom-button
          innerClass="btn solid btn-tab {{
            recallApproveItem.submittal_recall.RecallReason === 'Cancel' ? 'blue' : 'bg-grey-e'
          }}"
          (click)="recallApproveItem.submittal_recall.RecallReason = 'Cancel'"
        >
          Cancel
        </aecom-button>

        <aecom-button
          innerClass="btn solid btn-tab {{
            recallApproveItem.submittal_recall.RecallReason === 'Modify' ? 'blue' : 'bg-grey-e'
          }}"
          (click)="recallApproveItem.submittal_recall.RecallReason = 'Modify'"
        >
          Modify
        </aecom-button>
      </div>
    </aecom-form-item>

    <aecom-form-item
      *ngIf="recalledComments"
      [label]="'Note from DB User'"
      columnSpan="2"
    >
      <div class="button-wrapper d-flex" [innerHtml]="recalledComments.Notes">
      </div>
    </aecom-form-item>
  </aecom-form-group>

  <aecom-form-group
    columnSpan="6"
    columns="2"
    title="Processing"
  >
    <aecom-form-item
      data-cy="accept"
      *ngIf="isDocControl"
      [label]="'Do you accept Recall?'"
      columnSpan="1"
    >
      <div class="button-wrapper d-flex">
        <aecom-button
          innerClass="btn solid btn-tab {{
            !recallApproveItem.ShowRecallRejected ? 'blue' : 'bg-grey-e'
          }}"
          (click)="setAcceptRecall(false)"
        >
          Yes
        </aecom-button>

        <aecom-button
          innerClass="btn solid btn-tab {{
            recallApproveItem.ShowRecallRejected ? 'blue' : 'bg-grey-e'
          }}"
          (click)="setAcceptRecall(true)"
        >
          No
        </aecom-button>
      </div>
    </aecom-form-item>
  </aecom-form-group>
</ng-template>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="selectUserWindowTitle"
      (close)="closeWindow($event)"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>
