import { IPSubCopyDocument } from "../api-generated";

export default class SubmittalDocumentCopy implements IPSubCopyDocument {
  public SubItemId: string;

  public ReferenceId: string;

  public Status: string;

  public CopyFrom: string;

  public FileType: string;

  public Submittal_version: string;

  constructor(
    fileType: string,
    version: string,
    status: string,
    submittalId: string,
    referenceId: string,
    copyFrom: string,
  ) {
    this.Status = status;
    this.FileType = fileType;
    this.SubItemId = submittalId;
    this.ReferenceId = referenceId;
    this.Submittal_version = version;
    this.CopyFrom = copyFrom;
  }
}
