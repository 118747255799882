enum SubmittalFileType {
  CoverSheet = "Cover Sheet",
  SubmittalDocuments = "Submittal Documents",
  CoverSheetStampSigned = "Cover Sheet & Stamp Signed",
  CommentResolutionForm = "Comment Resolution Form",
  MarkedUpComments = "Marked Up Comments",
  AnsweredCommentResolutionForm = "Answered Comment Resolution Form",
  Reviewed_With_Comments = "Reviewed With Comments",
  Comments = "Comments",
  Response = "Response",
  FailureForm = "FailureForm",
  Attachment = "Attachment"
}

export default SubmittalFileType;
