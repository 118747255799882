import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import LocalContractService from "src/app/services/local-contract.service";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import { IPSubItem } from "src/app/api-generated";
import AuthService from "@auth/auth.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { failureFormName } from "@shared/utils";
import SubmittalFailureForm from "@models/submittalFailureForm";
import SubmittalRejectDetails from "@models/submittalRejectDetail";

@Component({
  selector: "app-failureForm",
  templateUrl: "./failureForm.component.html",
  styleUrls: ["./failureForm.component.scss"],
})
export default class FailureFormComponent implements OnInit {
  @Input() submittal: IPSubItem;

  @Input() failureForm: SubmittalRejectDetails;

  @Output() closeWindow = new EventEmitter<File | null>();

  @ViewChild('pdfFrom') pdfForm: ElementRef;

  data: SubmittalFailureForm;

  constructor(
    public localContractService: LocalContractService,
    private localContractUserService: LocalContractUserService,
    public authService: AuthService,
    public localSubmittalTypeService: LocalSubmittalTypeService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
  ) {}

  ngOnInit(): void {
    const { currentContract } = this.localContractService;
    const contractUsers = this.localContractUserService.getItems();
    const documentTypes = this.localSubmittalDocumentTypeService.getItems();
    const submittalTypes = this.localSubmittalTypeService.getItems();
    this.data = new SubmittalFailureForm(this.authService.user.Id, currentContract, this.submittal, contractUsers, documentTypes,submittalTypes, this.failureForm);
  }

  save() {
    const pdfElement = this.pdfForm.nativeElement;
    html2canvas(pdfElement, {scale: 2}).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight)
      const blob = pdf.output("blob");
      const file = new File([blob], failureFormName(this.submittal.SubmittalNumber));
      this.closeWindow.emit(file);
    });
  }

  download() {
    const pdfElement = this.pdfForm.nativeElement;
    html2canvas(pdfElement, {scale: 2}).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight)
      pdf.save(failureFormName(this.submittal.SubmittalNumber));
    });
  }

  back() {
    this.closeWindow.emit(null);
  }
}

