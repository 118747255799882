import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import DispositionListItem from "@models/dispositionListItem";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  IPSubDocumentDownload,
  IPSubItem,
  SubmittalDisposition,
} from "src/app/api-generated";

@Component({
  selector: "dispositionList",
  templateUrl: "./dispositionList.component.html",
  styleUrls: ["./dispositionList.component.scss"],
})
export default class DispositionListComponent implements OnInit {
  @Input() entity: IPSubItem;

  @Input() list: SubmittalDisposition[];

  @Input() docs: IPSubDocumentDownload[];

  @Input() title: string;

  @Input() expandedFirstItem = false;

  @Input() isDisposition = false;

  @Input() allowMovingFiles = true;

  @Input() isDraft = false;

  @Output() moveCoverSheet = new EventEmitter<FileModel>();

  @Output() moveCoverSheets = new EventEmitter<FileModel[]>();

  @Output() moveResolutionForm = new EventEmitter<FileModel>();

  @Output() moveResolutionForms = new EventEmitter<FileModel[]>();

  @Output() moveComment = new EventEmitter<FileModel>();

  @Output() moveComments = new EventEmitter<FileModel[]>();

  items: DispositionListItem[];

  constructor(public localContractUserService: LocalContractUserService) {}

  ngOnInit(): void {
    this.items = this.list.map((item) => {
      return new DispositionListItem(
        this.entity,
        item,
        this.docs,
        this.localContractUserService,
      );
    });
    if (this.expandedFirstItem && this.items.length > 0) {
      this.items[0].Collapse = false;
    }
    // console.log(this.items);
  }

  coverSheetOut(item: FileModel): void {
    // console.log(item);
    this.moveCoverSheet.emit(item);
  }

  coverSheetsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveCoverSheets.emit(item);
  }

  resolutionFormOut(item: FileModel): void {
    // console.log(item);
    this.moveResolutionForm.emit(item);
  }

  resolutionFormsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveResolutionForms.emit(item);
  }

  commentOut(item: FileModel): void {
    // console.log(item);
    this.moveComment.emit(item);
  }

  commentsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveComments.emit(item);
  }
}
