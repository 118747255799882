import { IPSubItem, ISubDelegate } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalDelegateDetail from "./submittalDelegateDetail";

export default class SubmittalDelegate
  extends SubmittalItemUpdateBase
  implements ISubDelegate
{
  public Guid: string;
  public submittal_delegate_detail: SubmittalDelegateDetail;

  constructor(contractId: string, item: IPSubItem) {
    super(contractId, item);
    this.Guid = item.Guid;
    this.submittal_delegate_detail = new SubmittalDelegateDetail(item);
  }
}
