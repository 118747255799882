<div
  *ngIf="isLoaded && submittal"
  class="maincontainer light-theme"
>
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [entity]="entity" 
        [docs]="docs"
        [defaultTab]="defaultTab"
        [allowMovingFiles]="true"
        (moveResolutionForm)="addFileToAttachment($event,submittalFileType.CommentResolutionForm)"
        (moveResolutionForms)="addFilesToAttachments($event,submittalFileType.CommentResolutionForm)"
        (moveComment)="addFileToAttachment($event, submittalFileType.MarkedUpComments)"
        (moveComments)="addFilesToAttachments($event,submittalFileType.MarkedUpComments)"
      ></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>
              <aecom-button
                  data-cy="submit"
                  innerClass="btn lg solid blue"
                  (click)="submit()"
                >
                  Submit
                </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form-group
            columnSpan="6"
            columns="2"
            title="Bluebeam Session Package"
            style="margin-bottom: 70px"
          >
            <aecom-form-item columnSpan="1">
              <aecom-upload
                data-cy="reviewedComments"
                [isNewStyle]="true"
                [multiFiles]="true"
                [canDelete]="true"
                [data]="reviewedComments"
                [theme]="'light'"
                (ngValueChange)="
                  importFile($event, submittalFileType.Reviewed_With_Comments)
                "
                (ngDeleteChange)="
                  deleteFile($event, submittalFileType.Reviewed_With_Comments)
                "
              ></aecom-upload>
              <div
                class="error-message"
                *ngIf="reviewedComments.length === 0 && canIssueSubmittal"
              >
                Required Field
              </div>
              <div class="error-message" *ngIf="!reviewedCommentsNameValid">
                File Name cannot include special characters other than '-', '_',
                '.', '@'
              </div>
            </aecom-form-item>
          </aecom-form-group>
        </div>
      </div>
    </div>
  </div>
</div>