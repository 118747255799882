<ng-container>
  <subHeader [submittal]="entity" [isDBAdmin]="isDBAdmin"></subHeader>

  <aecom-tabs class="cusTab" [defaultTab]="defaultTab">
    <aecom-tab title="details">
      <div style="overflow-y: auto; overflow-x: hidden">
        <sub-detail
          [submittal]="entity"
          [isDBAdmin]="isDBAdmin"
          [documents]="docs"
        ></sub-detail>
      </div>
    </aecom-tab>
    <aecom-tab title="processing" *ngIf="showProcessing">
      <div style="overflow-y: auto; overflow-x: hidden">
        <subProcessingTab
          [entity]="entity"
          [docs]="docs"
          [allowMovingFiles]="allowMovingFilesProcessingTab"
          (moveCoverSheet)="coverSheetOut($event)"
          (moveCoverSheets)="coverSheetsOut($event)"
          (moveResolutionForm)="resolutionFormOut($event)"
          (moveResolutionForms)="resolutionFormsOut($event)"
          (moveComment)="commentOut($event)"
          (moveComments)="commentsOut($event)"
        ></subProcessingTab>
      </div>
    </aecom-tab>
    <aecom-tab
      *ngIf="showReviewTab"
      [title]="requireBluebeam ? 'bluebeam' : 'reviews'"
    >
      <div style="overflow-y: auto; overflow-x: hidden">
        <subBluebeamTab
          [entity]="entity"
          [docs]="docs"
          [reviewers]="reviewers"
          [watchers]="watchers"
          [requireBluebeam]="requireBluebeam"
          [allowMovingFiles]="allowMovingFilesBluebeamTab"
          [turnOnNotifyUser]="turnOnNotifyUser"
          [showAddUserIcon]="showAddUserIcon"
          (addUsers)="addUsersClick($event)"
          (movedAttachment)="commentOut($event)"
          (movedAttachments)="commentsOut($event)"
        ></subBluebeamTab>
      </div>
    </aecom-tab>
    <aecom-tab
      title="draft"
      *ngIf="showDraftDispositionTab"
    >
      <div style="overflow-y: auto; overflow-x: hidden">
        <subCompiledTab
          [entity]="entity"
          [isDraft]="true"
          [docs]="docs"
          [isDisposition]="true"
          [allowMovingFiles]="allowMovingFilesDraftTab"
          (moveCoverSheet)="coverSheetOut($event)"
          (moveCoverSheets)="coverSheetsOut($event)"
          (moveResolutionForm)="resolutionFormOut($event)"
          (moveResolutionForms)="resolutionFormsOut($event)"
          (moveComment)="commentOut($event)"
          (moveComments)="commentsOut($event)"
        ></subCompiledTab>
      </div>
    </aecom-tab>
    <aecom-tab
      title="disposition"
      *ngIf="showDispositionTab"
    >
      <div style="overflow-y: auto; overflow-x: hidden">
        <subCompiledTab
          [entity]="entity"
          [docs]="docs"
          [isDisposition]="true"
          [allowMovingFiles]="false"
        ></subCompiledTab>
      </div>
    </aecom-tab>

    <aecom-tab title="log">
      <div style="overflow-y: auto; overflow-x: hidden">
        <subLogTab
          [entity]="entity"
          [docs]="docs"
        ></subLogTab>
      </div>
    </aecom-tab>
  </aecom-tabs>
</ng-container>