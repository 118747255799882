import LocalContractUserService from "@services/local-contractUser.service";
import {
  IPSubReviewer,
  IUser,
  SubmittalReviewerResponse,
} from "../api-generated";

export default class ReviewerWithUserInfo implements IPSubReviewer {
  public DeletedBy: string;
  public DateDeleted: string;
  public UpdatedBy: string;
  public DateUpdated: string;
  public CreatedBy: string;
  public DateCreated: string;
  public ContractId: string;
  public ReviewerId: string;
  public SubmittalId: string;
  public Guid: string;
  public IsDraft: boolean;
  public submittal_reviewer_response?: SubmittalReviewerResponse[];
  public UserInfo?: IUser;
  public UserId?: string;

  constructor(
    user: IPSubReviewer,
    localContractUserService: LocalContractUserService,
  ) {
    this.DeletedBy = user?.DeletedBy;
    this.DateDeleted = user?.DateDeleted;
    this.UpdatedBy = user?.UpdatedBy;
    this.DateUpdated = user?.DateUpdated;
    this.CreatedBy = user?.CreatedBy;
    this.DateCreated = user?.DateCreated;
    this.ContractId = user?.ContractId;
    this.ReviewerId = user?.ReviewerId;
    this.SubmittalId = user?.SubmittalId;
    this.Guid = user?.Guid;
    this.IsDraft = user?.IsDraft;
    this.submittal_reviewer_response = user?.submittal_reviewer_response ? 
      user.submittal_reviewer_response.sort((a, b) =>
        new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
      ) : [];
    this.UserInfo = localContractUserService.getUserById(
      user.ReviewerId,
    )?.UserInfo;

    this.UserId = user?.ReviewerId;
  }
}
