<div class="contentBox">
  <div class="contentTitle">{{title}}</div>
  <ng-container *ngFor="let item of list">
    <div class="responseBox">
      <div class="responseHeader">
        <span>Rev.{{ item.Submittal_version.replace("REV", "") }}</span>
        <span *ngIf="item.UserName && item.UserName !== ''">
          <span class="responseHeaderSeparate">|</span>
          {{ item.UserName }}
        </span>
        <span *ngIf="item.UserOrganization && item.UserOrganization !== ''">
          <span class="responseHeaderSeparate">|</span>
          {{ item.UserOrganization }}
        </span>
        <span *ngIf="item.UserTrackName && item.UserTrackName !== ''">
          <span class="responseHeaderSeparate">|</span>
          {{ item.UserTrackName }}
        </span>
        <div class="responseHeaderDate">{{ item.Reponse_Date }}</div>
        <div
          class="responseHeaderCollapse"
          (click)="item.Collapse = !item.Collapse"
        >
          <i
            [ngClass]="
              item.Collapse
                ? 'icon-action_arrow_down'
                : 'icon-action_arrow_up'
            "
            class="icon icon-blue icon-lg"
          ></i>
        </div>
      </div>
      <div class="responseContent" *ngIf="!item.Collapse">
        <div class="responseBody" *ngIf="!item.HasComments">
          <div class="responseBodySubContent">
            <div class="dispositionText">No Comments</div>
          </div>
        </div>
        <div class="responseBody" *ngIf="item.HasComments" [innerHtml]="item.Response"></div>
        <file-list
          *ngIf="item.HasComments && item.Attachments && item.Attachments.length > 0"
          [fileList]="item.Attachments"
          title="Comments"
          [allowMovingFiles]="allowMovingFiles"
          (movedFile)="fileOut($event)"
          (movedFiles)="filesOut($event)"
        ></file-list>
      </div>
    </div>
  </ng-container>
</div>