import { IPSubItem, ISubRecallApproveStep, SubmittalRecall } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRecallItem from "./submittalRecallItem";

export default class SubmittalRecallApproval
  extends SubmittalItemUpdateBase
  implements ISubRecallApproveStep
{
  public IsRecalled: boolean;
  public ShowRecallRejected: boolean;
  public submittal_recall: SubmittalRecallItem;

  constructor(contractId: string, item: IPSubItem, recall: SubmittalRecall) {
    super(contractId, item);
    this.IsRecalled = false;
    this.ShowRecallRejected = false;
    this.submittal_recall = new SubmittalRecallItem(recall);
    
  }
}
