import { getVersionString } from "@shared/utils";
import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubCompileStep } from "../api-generated";
import SubmittalCompileBase from "./submittalCompileBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRejectDetail from "./submittalRejectDetail";
import SubmittalNotesBase from "./submittalNotesBase";

export default class SubmittalCompile
  extends SubmittalItemUpdateBase
  implements ISubCompileStep
{
  public submittal_compile: SubmittalCompileBase;

  public IsQAQCCompliance?: boolean;

  public KeyWords?: string;

  public submittal_reject_detail?: SubmittalRejectDetail;

  public tempId?: string;

  public submittal_notes?: SubmittalNotesBase;

  constructor(contractId: string, item?: IPSubItem) {
    super(contractId, item);

    const version = getVersionString(this.SubmittalNumber);
    this.KeyWords = "";
    if (item?.submittal_compile && item.submittal_compile.length > 0) {
      const lastCompile = item.submittal_compile.sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
      )[0];

      if (
        lastCompile.IsDraft === true &&
        lastCompile.Submittal_version === version
      ) {
        this.submittal_compile = new SubmittalCompileBase(1,lastCompile);
        this.KeyWords = item?.KeyWords ?? "";
      } else {
        this.submittal_compile = new SubmittalCompileBase(1);
        this.tempId = uuidv4();
      }
    } else {
      this.submittal_compile = new SubmittalCompileBase(1);
      this.tempId = uuidv4();
    }

    this.IsQAQCCompliance = item?.IsQAQCCompliance;

    if (!this.submittal_reject_detail) {
      this.submittal_reject_detail = new SubmittalRejectDetail();
    }
    const notes = item.submittal_notes?.find((notes)=>{return notes.IsDraft === true && notes.Status === item.Status && notes.DeletedBy === null});
    if(notes)
    {
      this.submittal_notes = new SubmittalNotesBase(notes);
    }
    else 
    {
      this.submittal_notes = new SubmittalNotesBase();
    }
  }
}
