<div class="report-margin">
  <div class="innercontainer no-print-margin" class="col-12" style="padding: 0; height: 100%;">
    <div class="no-print row justify-content-end action-btns" style="margin: 0; padding: 29px 60px;">
      <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()">
        CANCEL
      </aecom-button>
      <aecom-button innerClass="btn solid blue lg unchnage" (click)="download()">
        DOWNLOAD
      </aecom-button>
      <aecom-button data-cy="savePDF" innerClass="btn solid blue lg unchnage" (click)="save()">
        SAVE
      </aecom-button>
    </div>
    <!-- left -->
    <div class="no-print-margin" style="display: flex; align-items: center; overflow-y: auto; height: 100%;">
      <div class="masthead1 relative" *ngIf="data">
        <table class="report-page-size" id="pdfFrom" #pdfFrom>
          <thead>
            <div class="d-flex" style="margin-top: 30px; padding-top: 0px">
              <div class="p-2 adjust-header-right-margin">
                <span class="header-page-no-text"><img src="./../../../../assets/img/product-icons/ddc_logo.svg">
                </span>
              </div>

              <div class="p-2 ml-auto nopadding">
                <span class="header-page-no-text" style="padding-right: 45px">
                  <img src="./../../../../assets/img/product-icons/aecom_hill_av.svg">
                </span>
              </div>

            </div>
          </thead>

          <tbody>
            <!-- First Row -->
            <tr>
              <div class="d-flex report-p-margin" style="height: 35pt">
                <div class="p-2">
                  <span class="h-grey-font">
                    {{data.FormTitle}}
                  </span>
                </div>
              </div>
            </tr>
            <!-- Second Row -->
            <tr>
              <div class="d-flex report-p-margin">
                <div class="p-2">
                  <span class="title-text">
                    Submittal QA/QC Check Failure Form
                  </span>
                </div>
              </div>
            </tr>
            <!-- Third Row -->
            <tr>
              <div class="d-flex report-p-margin" [ngStyle]="{ 'margin-top': data.Remarks ? '18px' : '30px' }">
                <div class="p-2">
                  <span class="h-black-font">Submittal Details</span>
                </div>
              </div>
              <div class="blue-border report-p-margin"></div>

              <div class="row report-p-margin">
                <table class="sub-info p-t-10">
                  <tr>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-2">Design-Builder:</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.DB}}</div>
                        </div>
                      </div>
                    </td>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-2">Design-Builder Ref #</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.Ref}}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-2">Submittal No.</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.SubNo}}</div>
                        </div>
                      </div>
                    </td>
                    <td class="col-6">
                      <!-- note: Dont remove. Kept this empty for css -->
                    </td>
                  </tr>
                  <tr>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-2">Submittal Title</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.SubTitle}}</div>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-2">Document Type</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.DocType}}</div>
                        </div>
                      </div>
                    </td>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-0">Date Received from Design-Builder</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.DateReceived}}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="col-6">
                      <div class="d-flex">
                        <div class="p-2">
                          <div class=" sub-info-title mt-2">Review Type</div>

                        </div>
                        <div class="p-2">
                          <div class="sub-info-text">{{data.ReviewType}}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>

              </div>

              <!-- Fourtth Row -->
              <div class="d-flex report-p-margin" [ngStyle]="{ 'margin-top': data.Remarks ? '18px' : '30px' }">
                <div class="p-2">
                  <span class="h-black-font">Processing Detail</span>
                </div>
              </div>
              <div class="blue-border report-p-margin"></div>

              <div class="row report-p-margin">
                <div class="col-6 no-left-padding p-t-10">

                  <div class="d-flex p-b-16">
                    <div class="p-2 sub-info-title">Reviewed by</div>
                    <div class="p-2 sub-info-text">
                      <span>{{ data.ReviewedBy }}</span>
                    </div>
                  </div>


                </div>
                <div class="col-6 no-left-padding p-t-10">
                  <div class="d-flex p-b-16">
                    <div class="p-2 sub-info-title">Issued Date by DDC / PMC</div>
                    <div class="p-2 sub-info-text">
                      <span>{{data.IssuedDate}}</span>
                    </div>
                  </div>

                </div>
                <div class="col-12 no-left-padding p-t-10" *ngIf="data.Note">
                  <div class="d-flex p-b-16">
                    <div class="p-2 sub-info-title">Note</div>
                    <div class="p-2 info" 
                    >
                      <span>{{data.Note}}</span>
                
                    </div>
                  </div>

                </div>
              </div>

              <!-- Second Row -->
              <div class="d-flex report-p-margin" [ngStyle]="{ 'margin-top': data.Remarks ? '18px' : '30px' }">
                <div class="p-2">
                  <span class="h-black-font">Failure Reason</span>
                </div>
              </div>
              <div class="blue-border report-p-margin"></div>

              <div class="row report-p-margin">
                <ng-container *ngFor="let item of data.FailureReason">
                  <div class="col-4"  style="padding: 10px 0;">
                    <img src="./../../../../assets/img/checked-box.svg" class="checked" *ngIf="
                    item.checked; else unchecked"/>
                    &nbsp;<span class="disposition-text">{{item.name}}</span>
                    <ng-template #unchecked>
                      <img src="./../../../../assets/img/unchecked-box.svg" class="unchecked" />
                    </ng-template>
                  </div>
                </ng-container>
              </div>

              <!-- Second Row -->
              <div *ngIf="data.Remarks" class="d-flex report-p-margin" style="margin-top: 18px">
                <div class="p-2">
                  <span class="h-black-font">Remarks</span>
                </div>
              </div>
              <div class="blue-border report-p-margin"></div>

              <div class="row report-p-margin">
                <div class="p-2 info" [innerHtml]="data.Remarks">
                </div>
              </div>

              <!-- Third Row -->
              <div class="d-flex report-p-margin" [ngStyle]="{ 'margin-top': data.Remarks ? '18px' : '30px' }">
                <div class="p-2">
                  <span class="h-black-font">Digital Signature</span>
                </div>
              </div>
              <div class="blue-border report-p-margin"></div>

              <div class="row report-p-margin" [ngStyle]="{ 'margin-top': data.Remarks ? '5px' : '28px' }">
                <div class="d-flex">
                  <div class="p-2 ">
                    <div class="signature-tb">
                      {{data.ReviewedBy}}
                    </div>
                    <div class="info">Signature</div>
                  </div>
                  <div class="p-2">
                    <div class="date-tb">
                      {{data.IssuedDate}}
                    </div>
                    <div class="info">Date</div>
                  </div>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #notAvailable>
  <div class="rfi-detail-value-2">N/A</div>
</ng-template>