import { ModalContainerService } from "@aecom/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-requestComments-modal",
  templateUrl: "./requestComments-modal.component.html",
  styleUrls: ["./requestComments-modal.component.css"],
})
export default class RequestCommentsModalComponent {
  comments = "Please upload the comment package from Bluebeam.";
  defaultText = "Please upload the comment package from Bluebeam.";

  constructor(private activeModal: ModalContainerService) {}

  save() {
    this.activeModal.close(
      !this.comments || this.comments.trim() === ""
        ? this.defaultText
        : this.comments,
    );
  }

  cancel() {
    this.activeModal.close(0);
  }
}
