import { ModalContainerService } from "@aecom/core";
import { Component } from "@angular/core";
import RecallRequest from "@models/recallRequest";

@Component({
  selector: "app-recall-modal",
  templateUrl: "./recall-modal.component.html",
  styleUrls: ["./recall-modal.component.scss"]
})
export default class RecallModalComponent {
  result = new RecallRequest();
  isValid = false;
  validate = false;

  constructor(private activeModal: ModalContainerService) {}

  save() {
    this.validate = true;

    if (this.isValid && this.result.reason) {
      this.activeModal.close(this.result);
    }
  }

  checkInput(e): void {
    this.result.comments = e;

    if (this.result.comments.length == 0) {
      this.isValid = false;
    } else {
      this.isValid = this.result.comments.toLowerCase().trim() !== "enter note";
    }
  }

  cancel() {
    this.activeModal.close(0);
  }
}
