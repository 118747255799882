import { Injectable } from "@angular/core";
import { ContractUserApplicationRole, IPSubListItem } from "../api-generated";
import BaseService from "./base.service";

@Injectable({
  providedIn: "root",
})
export default class LocalSubmittalItemService extends BaseService<IPSubListItem> {
  public firstLoad = false;
  public nestedView: boolean = true;
}
