import { IPSubItem, ISubDispositionReleaseStep } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalNotesBase from "./submittalNotesBase";

export default class SubmittalDispositionRelease
  extends SubmittalItemUpdateBase
  implements ISubDispositionReleaseStep
{
  public IsBluebeamRequired: boolean;

  public submittal_notes?: SubmittalNotesBase;

  constructor(contractId: string, item: IPSubItem) {
    super(contractId, item);

    this.IsBluebeamRequired = item.IsBluebeamRequired;

    this.submittal_notes = new SubmittalNotesBase();
  }
}
