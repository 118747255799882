import { Injectable } from '@angular/core';
import DispositionReport from '@models/dispositionReport';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export default class DispositionReportService {

  public drsSubject = new BehaviorSubject<DispositionReport>(null);
  drsdata$: Observable<DispositionReport> = this.drsSubject.asObservable();

  public dispositonSubject = new BehaviorSubject<number>(null);
  dispositon$: Observable<number> = this.dispositonSubject.asObservable();
  constructor(
  ) {
    //
  }
  changeDispositionReport(dispositionReportObj: DispositionReport) {
    return this.drsSubject.next(dispositionReportObj);
  }
  changeDisposition(disposition: number) {
    return this.dispositonSubject.next(disposition);
  }
  getDispositionvalue(){
    return this.dispositonSubject.getValue();
  }

}
