<div class="row" style="margin-bottom: 20px">
  <div class="col-12">
    <aecom-form columns="12">
      <aecom-form-group
        columnSpan="12"
        columns="1"
        title="Rejection History"
      >
        <div columnSpan="1" class="timelineTable" *ngIf="data && data.length > 0; else noneData">
          <div class="row">
            <div class="col-1 th" style="text-align: left; position: relative; left: 40px;">#</div>
            <div class="col-5 th">Cover Sheet</div>
            <div class="col-3 th">Rejected By</div>
            <div class="col-3 th">Rejected Date</div>
          </div>
          <ng-container *ngFor="let row of data">
            <div class="row tr">
              <div class="col-1 td">
                <div (click)="row.Collapse = !row.Collapse"style="cursor: pointer; margin-left: 10px">
                  <i [ngClass]="row.Collapse ? 'icon-action_sort_desc' : 'icon-action_sort_asc offsetTop'" class="icon icon-lg" style="background-color: #c4c4c4"></i>
                </div>
                <div class="circle">
                  <div style="margin: 0 auto">{{ row.Sequence }}</div>
                </div>
              </div>
              <div class="col-5 td">
                <ng-container *ngFor="let coverSheet of row.CoverSheet">
                  <div class="hiddenTextOutterBox">
                    <a (click)="preview(coverSheet)" class="hiddenText" style="text-decoration: underline !important;">{{ coverSheet.Name }}</a>
                  </div>
                </ng-container>
              </div>
              <div class="col-3 td hiddenTextOutterBox"><div class="hiddenText">{{ row.RejectedBy }}</div></div>
              <div class="col-3 td hiddenTextOutterBox"><div class="hiddenText">{{ row.RejectedDate }}</div></div>
              <div class="col-1 td" *ngIf="!row.Collapse"></div>
              <div class="col-11 td" *ngIf="!row.Collapse">
                <div class="row" style="padding-bottom: 30px">
                  <div class="col-12 innerTh">Rejection Note</div>
                  <div class="col-12" style="line-height: 1.4;" [innerHtml]="row.Note"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #noneData>
          <div style="width: 100%;height: 100%;display: flex;align-items: center;">
            <div class="noItem-body">
              <div>
                <div class="noItem-icon-row icon icon-social_user_team_big icon-grey icon-xl"></div>
              </div>
              <div class="noItem-title-row">None</div>
            </div>
          </div>
        </ng-template>
      </aecom-form-group>
    </aecom-form>
  </div>
</div>