import { IPSubDocumentDownload, IPSubItem, SubmittalReopen } from "../api-generated";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import { getFilesByType, getRoleForSubmittal, isFinalStep, prepareMutiLineText } from "@shared/utils";
import LocalApplicationRoleService from "@services/local-application_role.service";
import reopenSignificance from "./reopenSignificance";
import reopenChangeType from "./reopenChangeType";
import SubmittalStatus from "./submittalStatus";
import { FileModel } from "@aecom/core";
export default class ReopenHistoryTableItem
{
  public Sequence: number;
  public Status: string;
  public RequestedBy: string;
  public RequestedOn: string;
  public ReviewedBy: string;
  public ReviewedOn: string;
  public Significance: string;
  public ChangeType: string[];
  public Reason: string;
  public Approved: boolean;
  public RejectReason: string;
  public Collapse: boolean;
  public DirectedBy?: string;
  public Attachments?: FileModel[];
  
  constructor(item: SubmittalReopen, sub: IPSubItem, documents: IPSubDocumentDownload[], users: IContractUserWithUserInfo[], localApplicationRoleService: LocalApplicationRoleService, index: number) {
    this.Sequence = index + 1;
    this.RequestedOn = item.DateRequest ? this.getFormatedDateAndTime(item.DateRequest) : "N/A";
    const requestedBy = users.find((u)=>{return u.UserId === item.RequestBy});
    if(requestedBy)
    {
      const role = getRoleForSubmittal(requestedBy.UserId,sub,users,item.DateRequest);
      this.RequestedBy = `${requestedBy?.UserInfo?.displayName ?? ""} (${role? localApplicationRoleService.getRoleById(role) : ""})`;
    }
    else {
      this.RequestedBy = "N/A";
    }
    const directedBy = users.find((u)=>{return u.UserId === item.DirectedBy});
    if(directedBy)
    {
      this.DirectedBy = `${directedBy?.UserInfo?.displayName ?? ""}`;
    }
    this.Approved = item.Approved;
    this.Status = SubmittalStatus.Request_Completed;
    if(index === sub.submittal_reopen?.length-1 && !isFinalStep(sub.Status))
    {
      this.Status = SubmittalStatus.Reopen_in_Progress;
    }
    //this.Status = item.Approved === null ? SubmittalStatus.Request_to_Reopen : (item.Approved ? "Request Completed" : "Request Denied by DB");
    this.Significance = reopenSignificance.find((s)=>{return s.id === item.Significance?.toString()})?.name ?? "N/A";
    this.Reason = item.Reason ? prepareMutiLineText(item.Reason) : "N/A";
    const types = item.ChangeType?.split(",")??[];
    this.ChangeType = types.flatMap((t)=>{
      const changeType = reopenChangeType.find((ct)=>{return ct.id === t});
      if(changeType) {
        if(t==='other' && item.OtherChangeType?.length > 0)
        {
          return [`${changeType.name}: ${item.OtherChangeType}`];
        } else {
          return [`${changeType.name}`];
        }
      } else {
        return [];
      }
    });
    const documnetsForThis = documents.filter((doc) => {
      return doc.ReferenceId === item.Guid;
    });
    if(documnetsForThis?.length > 0)
    {
      this.Attachments = getFilesByType(documnetsForThis);
    }

    if(item.Approved !== null)
    {
      this.ReviewedOn = item.DateResponse ? this.getFormatedDateAndTime(item.DateResponse) : "N/A";
      const reviewedBy = users.find((u)=>{return u.UserId === item.ResponseBy});
      if(reviewedBy)
      {
        const role = getRoleForSubmittal(reviewedBy.UserId,sub,users,item.DateResponse);
        this.ReviewedBy = `${reviewedBy?.UserInfo?.displayName ?? ""} (${role? localApplicationRoleService.getRoleById(role) : ""})`;
      }
      else {
        this.ReviewedBy = "N/A";
      }
      this.RejectReason = item.RejectReason ? prepareMutiLineText(item.RejectReason) : "N/A";
    } else {
      this.ReviewedOn = "-";
      this.ReviewedBy = "-";
    }
    this.Collapse = true;
  }



  private getFormatedDateAndTime(date: string): string {
    return `${new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
      .replace(", ", "/")
      .replace(" ", "/")} ${new Date(date).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit"
    })}`;
  }
}
