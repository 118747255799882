import { IPSubItem, ISubBluebeamRequestStep } from "../api-generated";
import SubmittalBluebeamRequestCommentsBase from "./submittalBluebeamRequestCommentsBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";

export default class SubmittalBluebeamRequestComments
  extends SubmittalItemUpdateBase
  implements ISubBluebeamRequestStep
{
  public submittal_bluebeam_request: SubmittalBluebeamRequestCommentsBase;

  constructor(contractId: string, item?: IPSubItem) {
    super(contractId, item);

    this.submittal_bluebeam_request =
      new SubmittalBluebeamRequestCommentsBase();
  }
}
