<div class="bluebeam-information" style="margin-bottom: 10px" *ngIf="bluebeam">
  <aecom-form class="contentcontainer">
    <aecom-form-group columnSpan="12" columns="12" label="">
      <aecom-form-item [label]="'Session Name'" columnSpan="6">
        <div class="itemText">
          {{ bluebeam.SessionName }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'End Date'" columnSpan="3">
        <div class="itemText">
          {{ getDate(bluebeam.Due) }}
        </div>
      </aecom-form-item>

      <aecom-form-item [label]="'End Time'" columnSpan="3">
        <div class="itemText">
          {{ getDisplayedTime(bluebeam.Due) }}
        </div>
      </aecom-form-item>

      <aecom-form-item
        [label]="'Invitation message for Reviewers'"
        columnSpan="12"
      >
        <div class="itemText">
          {{ bluebeam.InvitationMessage }}
        </div>
      </aecom-form-item>
    </aecom-form-group>
  </aecom-form>

  <footer
    *ngIf="showFooter && bluebeam.SessionId && bluebeam.SessionId.trim() !== ''"
  >
    <span> Bluebeam Session ID: {{ bluebeam.SessionId }} </span>

    <aecom-button
      innerClass="btn btn-icon solid lg text-white icon-blubeam-blue"
      [icon]="'icon icon-bluebeam_app'"
      (click)="openBlueBeam()"
    >
      Open Bluebeam
    </aecom-button>
  </footer>
</div>
