import { ThemeService } from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import PreviousRouteService from "@services/previousRoute.service";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export default class AppComponent implements OnInit {
  mainheight: number;

  previousUrl: string = null;
  currentUrl: string = null;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.mainheight = window.innerHeight;
  }

  constructor(public themeService: ThemeService, private router: Router, private urlService: PreviousRouteService) {}

  ngOnInit(): void {
    this.mainheight = window.innerHeight;
    this.themeService.setActiveFullTheme("light");
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      console.log("PreviousUrl:",this.previousUrl);
      console.log("currentUrl:", this.currentUrl);
      this.urlService.setPreviousUrl(this.previousUrl);
    });
  }
}
