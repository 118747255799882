import { ISelectItem } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";
import reopenChangeType from "@models/reopenChangeType";
import reopenSignificance from "@models/reopenSignificance";
import SubmittalReopenDetail from "@models/submittalReopenDetail";
import SubmittalRole from "@models/submittalRole";
import LocalContractUserService from "@services/local-contractUser.service";
import { prepareMutiLineText } from "@shared/utils";

@Component({
  selector: "reopen-detail",
  templateUrl: "./reopen-detail.component.html",
  styleUrls: ["./reopen-detail.component.scss"],
})
export default class ReopenDetailComponent implements OnInit {
  @Input() reopen: SubmittalReopenDetail;
  @Input() readonly = false;
  @Input() attemptToIssue = false;

  changeTypes: ISelectItem[];

  significances: ISelectItem[];

  userList: ISelectItem[] = [];

  isDoc = false;

  reasonMaxLength = 2000

  constructor(
    public localContractUserService: LocalContractUserService,
  ) {}

  ngOnInit(): void {
    const role = this.localContractUserService.currentUserContractRole;
    
    this.isDoc = role === SubmittalRole.Doc_Control;
    this.userList = this.localContractUserService
      .getUsers([SubmittalRole.Manager, SubmittalRole.SR_Manager, SubmittalRole.DDC_Manager])
      .map((u) => {
        return {
          id: u.UserId,
          name: `${u.UserInfo.displayName ?? ""} (${u.Role})`,
          checked: false,
        };
      })
      .sort((a, b)=>a.name.localeCompare(b.name));
    this.changeTypes = JSON.parse(JSON.stringify(reopenChangeType));
    this.significances = JSON.parse(JSON.stringify(reopenSignificance));
    if(this.reopen.ChangeType)
    {
      this.changeTypes.forEach((type)=>{
        type.checked = this.reopen.ChangeType.includes(type.id);
      });
    }
    if(this.reopen.Significance)
    {
      this.significances.forEach((sig)=>{
        sig.checked = this.reopen.Significance.toString() === sig.id;
      });
    }
  }
  setChangeType(e: ISelectItem): void {
    console.log(e);
    this.reopen.ChangeType = this.changeTypes.filter((type)=>{return type.checked}).map((type)=>type.id).join(',');
  }
  getReasonLength(): number {
    return this.reopen.Reason
      ? this.reopen.Reason.length
      : 0;
  }
  setSignificance(e: ISelectItem): void {
    console.log(e);
    this.reopen.Significance = parseInt(e.id);
  }

  showOtherType(): boolean {
    return this.reopen.ChangeType?.includes('other');
  }

  getReasonText(): string {
    return this.reopen.Reason ? prepareMutiLineText(this.reopen.Reason) : "N/A";
  }
  setUserSelect(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.reopen.DirectedBy = e?.id;
    }
  }
}
