import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import LocalContractService from "src/app/services/local-contract.service";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import { IPSubItem } from "src/app/api-generated";
import SubmittalDisposition from "@models/submittalDisposition";
import AuthService from "@auth/auth.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import SubmittalDispositionForm from "@models/submittalDispositionForm";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { dispositionFormName, getAccTimeDate } from "@shared/utils";
import LocalPhaseService from "@services/local-phase.service";
import LocalContractRequirementService from "@services/local-contractRequirement.service";
import LocalSubmittalPageService from "@services/local-submittalPage.service";
import { FileModel } from "@aecom/core";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export default class ReportComponent implements OnInit {
  @Input() submittal: IPSubItem;

  @Input() disposition: SubmittalDisposition;

  @Input() attachments: FileModel[] = [];

  @Output() closeWindow = new EventEmitter<File | null>();

  @ViewChild('pdfFrom') pdfForm: ElementRef;

  data: SubmittalDispositionForm;

  attachmentNames: string;
  footLeft: string;
  footMiddle: string;
  footRight: string;

  constructor(
    public localContractService: LocalContractService,
    private localContractUserService: LocalContractUserService,
    public authService: AuthService,
    public localSubmittalTypeService: LocalSubmittalTypeService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
    public localPhaseService: LocalPhaseService,
    public localContractRequirementService: LocalContractRequirementService,
    public localSubmittalPageService: LocalSubmittalPageService
  ) {}

  ngOnInit(): void {
    const { currentContract } = this.localContractService;
    const contractUsers = this.localContractUserService.getItems();
    const documentTypes = this.localSubmittalDocumentTypeService.getItems();
    const submittalTypes = this.localSubmittalTypeService.getItems();
    const phases = this.localPhaseService.getItems();
    const contractRequirements = this.localContractRequirementService.getItems();
    const pages = this.localSubmittalPageService.getItems();
    this.data = new SubmittalDispositionForm(currentContract, this.submittal, contractUsers, documentTypes,submittalTypes,phases,contractRequirements,pages, this.disposition);
    this.footLeft = `${this.submittal.SubmittalNumber} - Disposition Form.pdf`;
    this.footMiddle = "Page 1 of 1";
    this.footRight = `Created On: ${getAccTimeDate(new Date().toISOString())}`;
    if(this.attachments?.length > 0)
    {
      this.attachmentNames = this.attachments.map((a)=>{return a.Name}).join('; ');
    }
  }

  save() {
    const pdfElement = this.pdfForm.nativeElement;
    html2canvas(pdfElement, {scale: 2}).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight)
      const blob = pdf.output("blob");
      const file = new File([blob], dispositionFormName(this.submittal.SubmittalNumber));
      this.closeWindow.emit(file);
    });
  }

  download() {
    const pdfElement = this.pdfForm.nativeElement;
    html2canvas(pdfElement, {scale: 2}).then(canvas => {
      const imgWidth = 208;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight)
      pdf.save(dispositionFormName(this.submittal.SubmittalNumber));
    });
  }

  back() {
    this.closeWindow.emit(null);
  }
}

