import { Component, Input, OnInit } from "@angular/core";
import { SubmittalBluebeamDetail } from "src/app/api-generated";
import { getDate, getDisplayedTime, getTime } from "../utils";

@Component({
  selector: "bluebeamSession",
  templateUrl: "./bluebeamSession.component.html",
  styleUrls: ["./bluebeamSession.component.scss"],
})
export default class BluebeamSessionComponent implements OnInit {
  @Input() bluebeam: SubmittalBluebeamDetail;
  @Input() isReviewer = true;
  @Input() isDocControl = true;
  @Input() isRequest = true;

  showFooter: boolean;

  ngOnInit(): void {
    this.showFooter = this.isReviewer || (this.isDocControl && this.isRequest);
  }

  openBlueBeam(): void {
    window.open(
      `https://studio.bluebeam.com/join.html?ID=${this.bluebeam.SessionId}`,
      "_blank",
    );
  }

  getTime(a) {
    return getTime(a);
  }

  getDisplayedTime(a) {
    return getDisplayedTime(a);
  }

  getDate(a) {
    return getDate(a);
  }
}
