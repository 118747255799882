import TimeStamp from "@models/timeStamp";
import { ISubRejectDetail } from "../api-generated";

export default class SubmittalRejectDetails
  extends TimeStamp
  implements ISubRejectDetail
{
  public Guid?: string;
  public Reason?: string;
  public Note?: string;

  constructor(item?: ISubRejectDetail) {
    super(item);

    this.Guid = item?.Guid;
    this.Reason = item?.Reason;
    this.Note = item?.Note ?? "The submittal did not pass the program's QA/QC checks.";
  }
}
