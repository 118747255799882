import { getDispositionText, isFinalStep, shouldShowAltDDCManager, shouldShowAltManager, shouldShowCoordinator, shouldShowDDCManager, shouldShowManager, shouldShowSrManager } from "@shared/utils";
import {
  IPSubListItem,
  IPRFISpecification,
  SubmittalPage,
  IUser,
  IPRFIContractRequirement,
  Phase,
} from "../api-generated";
import SubmittalListRowItem from "./submittalListViewModel";

//Submittal view model
export default class SubmittalListLog {
  public priority: string;
  public submittalNumber: string;
  public submittalTitle: string;
  public division: string;
  public specification: string;
  public docType: string;
  public submittalType: string;
  public page: string;
  public issueDate: string | Date;
  public internalDue: string | Date;
  public dueDate: string | Date;
  public responseDate: string | Date;
  public bic: string;
  public status: string;
  public reference: string;
  public reviewers: string[];
  public watchers: string[];
  public disposition: string;
  public contractRequirement: string;
  public phase: string;
  public manager: string;
  public coordinator: string;
  public ddcManager: string;
  public altManager: string;
  public altDDCManager: string;
  public srManager: string;

  constructor(
    item: SubmittalListRowItem,
    subItem: IPSubListItem[],
    spec: IPRFISpecification[],
    pages: SubmittalPage[],
    contractRequirements: IPRFIContractRequirement[],
    phases: Phase[],
    users: IUser[],
  ) {
    const sub = subItem.find((s) => {
      return s.Guid === item.id;
    });

    switch (item.priority) {
      case 2:
        this.priority = "Medium";
        break;
      case 3:
        this.priority = "High";
        break;
      default:
        this.priority = "Low";
        break;
    }

    this.submittalNumber = item.submittalNumber;
    this.submittalTitle = item.submittalTitle;

    const specification = spec.find((s) => {
      return s.Guid === item.specificationId;
    });

    this.division = specification?.Division ?? "";
    this.specification = item.specificationTitle;
    this.docType = `${item.doctypeCode}_${item.doctypetitle}`;
    this.submittalType = item.submittalTypeTitle;
    this.issueDate = item.issueDateLog;
    this.internalDue = item.internalDueDateLog;
    this.dueDate = item.expectedResponseDateLog;
    this.responseDate = item.responseDateLog;

    this.bic = item.ballInCourtUser
      ? `${item.ballInCourtUser.displayName}\r\n${item.ballInCourtUser.mail}`
      : item.ballInCourt;
    this.status =
      item.substatusText && item.substatusText !== null
        ? `${item.statusText}\r\n${item.substatusText}`
        : item.statusText;
    const showManager = shouldShowManager(sub);
    const showAltManager = shouldShowAltManager(sub);
    const showCoordinator = shouldShowCoordinator(sub);
    const showDDCManager = shouldShowDDCManager(sub);
    const showAltDDCManager = shouldShowAltDDCManager(sub);
    const showSrManager = shouldShowSrManager(sub);
    this.manager = this.getUserString(sub.ManagerId, users, showManager);
    this.coordinator = this.getUserString(sub.CoordinatorId, users, showCoordinator);
    this.ddcManager = this.getUserString(sub.DDCManagerId, users, showDDCManager);
    this.altManager = this.getUserString(sub.AlternatedManager, users, showAltManager);
    this.altDDCManager = this.getUserString(sub.AlternatedDDCManager, users, showAltDDCManager);
    this.srManager = this.getUserString(sub.SrManagerId, users, showSrManager);

    if (sub && sub.submittal_create && sub.submittal_create.length > 0) {
      const page = pages.find((p) => {
        return p.Guid === sub.submittal_create[0].DrawingPageId;
      });

      this.page = page?.Title ?? "";
      const cr = contractRequirements.find((p) => {
        return p.Guid === sub.submittal_create[0].ContractRequirementId;
      });

      this.contractRequirement = cr?.Name ?? "";
      const phase = phases.find((p) => {
        return p.Guid === sub.submittal_create[0].PhaseId;
      });

      this.phase = phase?.Title ?? "";

      this.reference = sub.submittal_create[0].ReferenceNumber ?? "";
      if(isFinalStep(sub.Status) && sub.submittal_disposition?.length > 0)
      {
        const finalDisposition = sub.submittal_disposition.find((d)=>{return d.IsDraft === false && d.ToDDC !== true && d.ToSrManager !== true});
        this.disposition = getDispositionText(finalDisposition?.Disposition ?? 0, sub.submittal_create[0].SubmittalTypeId);
      }
      else {
        this.disposition = "";
      }
    } else {
      this.page = "";
      this.reference = "";
      this.disposition = "";
    }

    if (sub && sub.submittal_reviewer && sub.submittal_reviewer.length > 0) {
      this.reviewers = sub.submittal_reviewer
        .filter((r) => {
          return !r.DeletedBy;
        })
        .map((r) => {
          const userInfo = users.find((c) => {
            return c.id === r.ReviewerId;
          });

          return userInfo
            ? `${userInfo.displayName}: ${userInfo.mail}`
            : "";
        });
    } else {
      this.reviewers = [];
    }

    if (sub && sub.submittal_watcher && sub.submittal_watcher.length > 0) {
      this.watchers = sub.submittal_watcher
        .filter((r) => {
          return !r.DeletedBy;
        })
        .map((r) => {
          const userInfo = users.find((c) => {
            return c.id === r.WatcherId;
          });

          return userInfo
            ? `${userInfo.displayName}: ${userInfo.mail}`
            : "";
        });
    } else {
      this.watchers = [];
    }
  }

  private getUserString(userId: string | undefined, users: IUser[], show: boolean): string {
    const result = show ? 'N/A' : '-';
    if(userId)
    {
      const userInfo = users.find((u)=>{return u.id === userId});
      return userInfo ? `${userInfo.displayName}: ${userInfo.mail}` : result;
    }
    return result;
  }
}
