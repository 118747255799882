<div class="contentcontainer">
  <bluebeamSession
    *ngIf="requireBluebeam && entity.submittal_bluebeam_detail[0]"
    [bluebeam]="entity.submittal_bluebeam_detail[0]"
  ></bluebeamSession>

  <file-list
    *ngIf="requireBluebeam && entity.submittal_bluebeam_detail[0] && entity.submittal_bluebeam_detail[0].SessionId"
    [showDetail]="true"
    [showVersion]="true"
    [showSeparator]="false"
    [allowMovingFiles]="allowMovingFiles"
    [fileList]="comments"
    title="Comments"
    (movedFile)="fileOut($event)"
    (movedFiles)="filesOut($event)"
  ></file-list>

  <div
    style="display: block; height: 40px; text-align: center; color: #777"
    *ngIf="requireBluebeam && entity.submittal_bluebeam_detail[0] && entity.submittal_bluebeam_detail[0].SessionId && comments.length === 0"
  >
    No Comments Yet.
  </div>

  <reviewList
    *ngIf="!requireBluebeam"
    [list]="submittedReviewerList"
    [expandFirst]="true"
    title="Completed Reviews"
    [allowMovingFiles]="allowMovingFiles"
    (moveAttachment)="fileOut($event)"
    (moveAttachments)="filesOut($event)"
  ></reviewList>

  <notifyList
    *ngIf="requireBluebeam"
    [list]="reviewerList"
    title="Reviewer List"
    [turnOnNotifyUser]="turnOnNotifyUser"
  ></notifyList>

  <notifyList
    *ngIf="!requireBluebeam"
    [isReviewer]="true"
    [showAddUserIcon]="showAddUserIcon"
    [list]="unSubmittedReviewerList"
    title="Pending Reviewer List"
    [turnOnNotifyUser]="turnOnNotifyUser"
    (openReviewerWindow)="openWindow($event)"
  ></notifyList>

  <notifyList
    [list]="observerList"
    [showAddUserIcon]="showAddUserIcon && !requireBluebeam"
    title="Observer List"
    [turnOnNotifyUser]="false"
    (openReviewerWindow)="openWindow($event)"
  ></notifyList>

  <reviewList
    *ngIf="!requireBluebeam"
    [list]="history"
    title="Response History"
    [allowMovingFiles]="allowMovingFiles"
    (moveAttachment)="fileOut($event)"
    (moveAttachments)="filesOut($event)"
  ></reviewList>
</div>
