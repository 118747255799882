import { ISubRecall } from "../api-generated";
import RecallRequest from "./recallRequest";
import TimeStamp from "./timeStamp";

export default class SubmittalRecallBase extends TimeStamp implements ISubRecall {
  public Guid?: string;

  public Notes: string;

  public RecallReason: string | null;

  constructor(recall: RecallRequest) {
    super();

    this.Notes = recall.comments;

    this.RecallReason = recall.reason;
  }
}
