import { prepareMutiLineText } from "@shared/utils";
import { SubmittalCompile } from "../api-generated";
import { ISubCompile } from "../api-generated/model/iSubCompile";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class SubmittalCompileBase
  extends TimeStampWithDraft
  implements ISubCompile
{
  public Guid?: string;

  public Disposition: number;

  public Note?: string;

  constructor(
    disposition: number,
    item?: SubmittalCompile,
  ) {
    super(item);
    this.Guid = item?.Guid;

    this.Disposition = item?.Disposition ?? disposition;

    this.Note = prepareMutiLineText(item?.Note ?? "");
  }
}
