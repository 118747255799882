import {
  FileModel,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalItemUpdateBase from "@models/submittalItemUpdateBase";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  getFilesByType,
  getVersionString,
  hasChangesFileModel,
  isFileNameInvalid,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  EmailService,
  IPSubDocumentDownload,
  IPSubDocumentUploadReturn,
  IPSubItem,
  SubmittalDocumentsService,
} from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "app-review-submittal",
  templateUrl: "./review-submittal.component.html",
  styleUrls: ["./review-submittal.component.scss"],
})
export default class ReviewSubmittalComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  currentUser: string;

  submittal: SubmittalItemUpdateBase;

  entity: IPSubItem;
  contractId: string;
  docs: IPSubDocumentDownload[] = [];

  defaultTab = "";

  saved = false;
  canIssueSubmittal = false;
  
  reviewedComments: FileModel[];
  oldReviewedComments: FileModel[];
  
  reviewedCommentsNameValid = true;

  isLoaded = false;
  today: Date;

  isDBAdmin = false;

  private removedFileIds: string[] = [];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalDocumentService: SubmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractService: LocalContractService,
    public emailService: EmailService,
    public localContractUserService: LocalContractUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;
    this.currentUser = data.currentUserId;
    this.today = new Date();
    this.today.setDate(new Date().getDate() + 1);

    this.entity = data.submittal;
    this.docs = data.documents;
    this.contractId = this.entity.ContractId;
    this.submittal = new SubmittalItemUpdateBase(this.entity.ContractId, this.entity);

    this.oldReviewedComments = getFilesByType(
      data.documents.filter((item) => {
        return item.FileType === SubmittalFileType.Reviewed_With_Comments;
      }),
    );
    this.reviewedComments = _.cloneDeep(this.oldReviewedComments);
    
    this.defaultTab = "details";
    this.isLoaded = true;
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    return hasChangesFileModel(this.reviewedComments, this.oldReviewedComments);
  }

  IsValid(): boolean {
    return this.reviewedComments.length > 0 && this.isFileNameValid();
  }

  submit(): void {
    this.canIssueSubmittal = true;

    const validation = this.IsValid();

    if (!validation) {
      return;
    } else if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";

      modalInstance.instance.title = "Submit Submittal?";

      modalInstance.instance.body = "Blubeam session package will be uploaded and it will complete the submittal process.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.update();
        }
      });
    }
  }

  update() {
    this.loadingService.start();
    this.updateFiles();
    this.baseFunctionService.update(this.submittal);
  }

  updateFiles(): void {
    const allCurrentFiles = [...this.reviewedComments];
    const allOldFiles = [
      ...this.oldReviewedComments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];

    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            getVersionString(this.submittal.SubmittalNumber),
            this.submittal.Status,
            this.submittal.Guid,
          );

          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case SubmittalFileType.Reviewed_With_Comments:
        index = this.reviewedComments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.reviewedComments.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.reviewedComments.push(item);
            this.reviewedComments = this.reviewedComments.slice();
          }
        }
        break;
      default:
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
  }
  isFileNameValid(): boolean {
    this.reviewedCommentsNameValid = true;
    this.reviewedComments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.reviewedCommentsNameValid = false;
      }
    });
    return this.reviewedCommentsNameValid;
  }

  hasFilesUploading(): boolean {
    return (
      this.reviewedComments.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      )
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }
}
