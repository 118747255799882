<div class="contentcontainer">
  <dispositionList
    [entity]="entity"
    [list]="currentList"
    [title]="currentListTitle"
    [expandedFirstItem]="true"
    [docs]="docs"
    [isDisposition]="isDisposition && !isDraft"
    [isDraft]="isDraft"
    [allowMovingFiles]="allowMovingFiles"
    (moveCoverSheet)="coverSheetOut($event)"
    (moveCoverSheets)="coverSheetsOut($event)"
    (moveResolutionForm)="resolutionFormOut($event)"
    (moveResolutionForms)="resolutionFormsOut($event)"
    (moveComment)="commentOut($event)"
    (moveComments)="commentsOut($event)"
  ></dispositionList>
<!-- Note :#5599 section  hidden until further notice-->
  <!-- 
    <dispositionList
    [entity]="entity"
    [list]="prevList"
    [title]="prevListTitle"
    [docs]="docs"
    [isDisposition]="isDisposition"
    [allowMovingFiles]="allowMovingFiles"
    (moveCoverSheet)="coverSheetOut($event)"
    (moveCoverSheets)="coverSheetsOut($event)"
    (moveResolutionForm)="resolutionFormOut($event)"
    (moveResolutionForms)="resolutionFormsOut($event)"
    (moveComment)="commentOut($event)"
    (moveComments)="commentsOut($event)"
  ></dispositionList> -->
</div>
