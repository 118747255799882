
import { IPDocument, IPSubDocumentUpload } from "../api-generated";
import SDocument from "./sDocument";

export default class SubmittalDocumentUpload implements IPSubDocumentUpload {
  public SubItemId?: string;

  public ReferenceId?: string;

  public Status?: string;

  public FileId?: string;

  public FileType?: string;

  public Submittal_version?: string;

  public Guid?: string;

  public Order?: number;

  public DateCreated?: string;

  public DateUpdated?: string;

  public IsArchived?: boolean;

  public Document: IPDocument;

  constructor(
    name: string,
    userId: string,
    fileType: string,
    version: string,
    status?: string,
    submittalId?: string,
    referenceId?: string,
  ) {
    this.Status = status;
    this.FileType = fileType
    this.SubItemId = submittalId;
    this.ReferenceId = referenceId;
    this.Submittal_version = version;
    this.Document = new SDocument(name, userId);
  }
}
