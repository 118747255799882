import { ISubBluebeamRequest } from "../api-generated";
import TimeStamp from "./timeStamp";

export default class SubmittalBluebeamRequestCommentsBase
  extends TimeStamp
  implements ISubBluebeamRequest
{
  public Comments: string;

  constructor() {
    super();

    this.Comments = "";
  }
}
