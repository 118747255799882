import { IPSubItem, ISubItemBase } from "../api-generated";
import TimeStamp from "./timeStamp";

export default class SubmittalItemUpdateBase
  extends TimeStamp
  implements ISubItemBase
{
  public Guid?: string;

  public ContractId: string;

  public Status?: string;

  public BallInCourt?: string;

  public SubmittalNumber?: string;

  public docSave?: string[];
  
  public docRemove?: string[];

  constructor(contractId: string, item?: IPSubItem) {
    super(item);
    this.Guid = item?.Guid;
    this.ContractId = item?.ContractId ?? contractId;
    this.Status = item?.Status;
    this.BallInCourt = item?.BallInCourt;
    this.SubmittalNumber = item?.SubmittalNumber;
  }
}
