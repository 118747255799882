import { submittalAppId } from "@shared/staticValue";
import { SubmittalFlow, SubmittalRecall } from "../api-generated";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import { prepareMutiLineText } from "@shared/utils";
export default class RecallHistoryTableItem
{
  public Sequence: number;
  public Status: string;
  public RequestedBy: string;
  public RequestedOn: string;
  public ReviewedBy: string;
  public ReviewedOn: string;
  public Note: string;
  public Collapse: boolean;
  
  constructor(item: SubmittalRecall, users: IContractUserWithUserInfo[], flows: SubmittalFlow[], index: number) {
    this.Sequence = index + 1;
    const requestedBy = users.find((u)=>{return u.UserId === item.CreatedBy});
    if(requestedBy)
    {
      const role = requestedBy.contract_user_application_role.find((cr)=>{return cr.ApplicationId === submittalAppId});
      this.RequestedBy = `${requestedBy?.UserInfo?.displayName ?? ""} (${role?.application_role?.Role ?? ""})`;
    }
    else {
      this.RequestedBy = "N/A";
    }
    this.RequestedOn = item.DateCreated ? this.getFormatedDateAndTime(item.DateCreated) : "N/A";
    const recallItemIndex = flows.findIndex((flow)=>{return flow.DateReceived === item.DateCreated});
    if(recallItemIndex && recallItemIndex !== 0 && recallItemIndex !== flows.length - 1)
    {
      const before = flows[recallItemIndex - 1];
      const after  = flows[recallItemIndex + 1];
      const reviewedBy = users.find((u)=>{return u.UserId === after.SubmittedBy});
      this.Status = before.Status === after.Status ? `Rejected ${item.RecallReason ? `(${item.RecallReason})` : ''}` : `Accepted ${item.RecallReason ? `(${item.RecallReason})` : ''}`;
      if(reviewedBy)
      {
        const role = reviewedBy.contract_user_application_role.find((cr)=>{return cr.ApplicationId === submittalAppId});
        this.ReviewedBy = `${reviewedBy?.UserInfo?.displayName ?? ""} (${role?.application_role?.Role ?? ""})`;
      }
      else {
        this.ReviewedBy = "N/A";
      }
      this.ReviewedOn = after.DateReceived ? this.getFormatedDateAndTime(after.DateReceived) : "N/A";
    }
    this.Note = item.Notes ? prepareMutiLineText(item.Notes) : "N/A";
    this.Collapse = true;
  }



  private getFormatedDateAndTime(date: string): string {
    return `${new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
      .replace(", ", "/")
      .replace(" ", "/")} ${new Date(date).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit"
    })}`;
  }
}
