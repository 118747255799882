import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import AuthService from "@content/auth/auth.service";
import LoginUserResolve from "@resolvers/login-user-resolver";

@Injectable({
  providedIn: "root",
})
export default class WaitForUserGuard {
  constructor(
    private authService: AuthService,
    private loginUserResolve: LoginUserResolve,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return this.authService
      .getUser()
      .toPromise()
      .then(this.authService.waitForLogin.bind(this.authService))
      .then(() => {
        // console.log("User Found");
        return true;
      });
  }
}
