import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  IPSubDocumentDownload,
  IPSubItem,
  SubmittalCompile,
} from "src/app/api-generated";

@Component({
  selector: "subCompiledTab",
  templateUrl: "./subCompiledTab.component.html",
  styleUrls: ["./subCompiledTab.component.scss"],
})
export default class SubCompiledTabComponent implements OnInit {
  @Input() entity: IPSubItem;

  @Input() docs: IPSubDocumentDownload[];

  @Input() isDisposition = false;

  @Input() isDraft = false;

  @Input() allowMovingFiles = true;

  @Output() moveCoverSheet = new EventEmitter<FileModel>();

  @Output() moveCoverSheets = new EventEmitter<FileModel[]>();

  @Output() moveResolutionForm = new EventEmitter<FileModel>();

  @Output() moveResolutionForms = new EventEmitter<FileModel[]>();

  @Output() moveComment = new EventEmitter<FileModel>();

  @Output() moveComments = new EventEmitter<FileModel[]>();

  currentList: SubmittalCompile[] = [];

  prevList: SubmittalCompile[] = [];

  currentListTitle: string;

  prevListTitle: string;

  ngOnInit(): void {
    if (this.isDisposition) {
      if (
        this.entity.submittal_disposition &&
        this.entity.submittal_disposition.length > 0
      ) {
        const disposition = [
          ...this.entity.submittal_disposition
            .filter((item) => {
              return !item.IsDraft && (this.isDraft ? item.ToDDC === true : item.ToDDC !== true);
            })
            .sort(
              (a, b) =>
                new Date(b.DateUpdated).getTime() -
                new Date(a.DateUpdated).getTime(),
            ),
        ];
        
        this.currentList.push(disposition.shift());

        this.prevList = disposition;

        this.currentListTitle = this.isDraft ? "Draft Response" : "Latest Disposition";

        this.prevListTitle = "Disposition History";
      }
    } else {
      if (
        this.entity.submittal_compile &&
        this.entity.submittal_compile.length > 0
      ) {
        const compile = [
          ...this.entity.submittal_compile
            .filter((item) => {
              return !item.IsDraft;
            })
            .sort(
              (a, b) =>
                new Date(b.DateUpdated).getTime() -
                new Date(a.DateUpdated).getTime(),
            ),
        ];

        this.currentList.push(compile.shift());

        this.prevList = compile;

        this.currentListTitle = "Latest Recommended Disposition";

        this.prevListTitle = "Recommended Disposition History";
      }
    }
  }

  coverSheetOut(item: FileModel): void {
    // console.log(item);
    this.moveCoverSheet.emit(item);
  }

  coverSheetsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveCoverSheets.emit(item);
  }

  resolutionFormOut(item: FileModel): void {
    // console.log(item);
    this.moveResolutionForm.emit(item);
  }

  resolutionFormsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveResolutionForms.emit(item);
  }

  commentOut(item: FileModel): void {
    // console.log(item);
    this.moveComment.emit(item);
  }

  commentsOut(item: FileModel[]): void {
    // console.log(item);
    this.moveComments.emit(item);
  }
}
