import { ISelectItem, ModalContainerService } from "@aecom/core";
import { Component, Input } from "@angular/core";
import SubmittalDelegate from "@models/submittalDelegate";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import LocalContractUserService from "@services/local-contractUser.service";
import { isLocal } from "@shared/utils";
import { IPSubItem } from "src/app/api-generated";

@Component({
  selector: "app-delegate-modal",
  templateUrl: "./delegate-modal.component.html",
  styleUrls: ["./delegate-modal.component.css"],
})
export default class DelegateModalComponent {
  @Input() title = "Reassign Task(s)?";
  @Input() body = "The BIC tasks will be reassigned to the person you select below (the new BIC). Once the reassignment is complete, the former BIC will no longer have the ability to carry out any tasks related to this Submittal.";
  @Input() set subItem(value: IPSubItem) {
    this.submittal = new SubmittalDelegate(value.ContractId, value);
    let reassignedRole: string[] = [];
    let bic: string | null = null;
    switch(value.Status)
    {
      case SubmittalStatus.In_Review:
      case SubmittalStatus.Compiling:
      case SubmittalStatus.Bluebeam_Compiling:
      case SubmittalStatus.Bluebeam:
      case SubmittalStatus.Bluebeam_Request:
      case SubmittalStatus.Bluebeam_Uploaded:
        reassignedRole=[SubmittalRole.Coordinator, SubmittalRole.Manager];
        bic = value.CoordinatorId;
        break;
      case SubmittalStatus.Disposition:
      case SubmittalStatus.Compiling_Disposition:
      case SubmittalStatus.Bluebeam_Disposition:
        reassignedRole=[SubmittalRole.Coordinator, SubmittalRole.Manager, SubmittalRole.SR_Manager];
        bic = value.ManagerId;
        break;
      case SubmittalStatus.SPM_Review:
        reassignedRole=[SubmittalRole.Manager, SubmittalRole.SR_Manager];
        bic = value.SrManagerId;
        break;
      case SubmittalStatus.DDC_Disposition:
        reassignedRole=[SubmittalRole.DDC_Manager];
        bic = value.DDCManagerId;
        break;
      default:
        break;
    }
    if(isLocal())
    {
      reassignedRole.push(SubmittalRole.Doc_Control);
    }
    if(reassignedRole.length > 0)
    {
      this.userList = this.localContractUserService
      .getUsers(reassignedRole)
      .map((u) => {
        return {
          id: u.UserId,
          name: `${u.UserInfo.displayName ?? ""} (${u.Role})`,
          checked: false,
        };
      }).filter((u)=>{return u.id !== bic}).sort((a, b)=>a.name.localeCompare(b.name));
    }
  }

  submittal: SubmittalDelegate | null = null;

  userList: ISelectItem[] = [];

  attemptToIssue = false;

  constructor(private activeModal: ModalContainerService, public localContractUserService: LocalContractUserService) {}

  save() {
    this.attemptToIssue = true;
    const isValid = this.submittal.submittal_delegate_detail.UserId && this.submittal.submittal_delegate_detail.UserId!=='';
    if (!isValid) {
      return;
    }
    this.activeModal.close(this.submittal);
  }

  cancel() {
    this.activeModal.close(null);
  }

  setUserSelect(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittal.submittal_delegate_detail.UserId = e?.id;
      // this.enableSaveDraft = this.hasChanges();
    }
  }
}
