import { Component, Input } from "@angular/core";
import IWorkflowHistoryItem from "@models/IWorkflowHistoryItem";

@Component({
  selector: "workflowHistory",
  templateUrl: "./workflowHistory.component.html",
  styleUrls: ["./workflowHistory.component.scss"],
})
export default class WorkflowHistoryComponent {
  @Input() data: IWorkflowHistoryItem[] = [];
  @Input() showDays = false;
}
