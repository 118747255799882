<div class="contentBox">
  <div class="contentTitle">{{title}}</div>
  <ng-container *ngFor="let item of list">
    <div class="pendingBox">
      <div class="pendingHeader">
        <span>{{ item.UserName }}</span>
        <span
          *ngIf="
            item.UserOrganization && item.UserOrganization !== ''
          "
          ><span class="responseHeaderSeparate">|</span
          >{{ item.UserOrganization }}</span
        >
        <span *ngIf="item.UserTrackName && item.UserTrackName !== ''"
          ><span class="responseHeaderSeparate">|</span
          >{{ item.UserTrackName }}</span
        >
        <div
          *ngIf="turnOnNotifyUser"
          class="responseNotify"
          (click)="notifyUser(item)"
        >
          <i
            class="icon icon-notify icon-green icon-lg"
            style="margin-right: 8px"
          ></i>
          <span>Notify User</span>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="showAddUserIcon" style="position: absolute; top: 22px; right: 0; cursor: pointer;">
    <div class="formGroupManu" (click)="openWindowClick()">
      + Add {{isReviewer? 'Reviewers':'Observers'}}
    </div>
  </div>
</div>