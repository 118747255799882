<aecom-form *ngIf="isLoaded" columns="12" class="contentcontainer">
  <aecom-form-group *ngIf="items.length > 0" columnSpan="12" columns="1" title="Internal Notes" style="margin-bottom: 40px">
    <aecom-form-item columnSpan="1">
      <ng-container *ngFor="let item of items">
        <div class="responseBox" [ngClass]="{'rejectedBox': item.IsBack}">
          <div class="responseHeader">
            <span>{{ item.Name }}</span>
            <span class="responseHeaderSeparate_b">|</span>
            <span>{{ item.Role }}</span>
            <div class="responseHeaderDate">{{ item.FormatedDateUpdated }}</div>
            <div class="responseHeaderCollapse" (click)="item.Collapse = !item.Collapse">
              <i
                [ngClass]="
                  item.Collapse
                    ? 'icon-action_arrow_down'
                    : 'icon-action_arrow_up'
                "
                class="icon icon-blue icon-lg"
              ></i>
            </div>
          </div>
          <div class="responseContent" *ngIf="!item.Collapse">
            <div class="responseBody" *ngIf="item.DispositionText">
              <div class="responseBodySubTitle">Recommended Disposition</div>
              <div class="responseBodySubContent">
                <div class="dispositionText">{{item.DispositionText}}</div>
              </div>
            </div>
            <div class="responseBody">
              <div class="responseBodySubTitle">{{item.Title}}</div>
              <div class="responseBodySubContent" [innerHtml]="item.Notes"></div>
            </div>
            <div class="responseBody" *ngIf="item.CoverSheet && item.CoverSheet.length > 0">
              <file-list
                [fileList]="item.CoverSheet"
                [showDetail]="true"
                [allowMovingFiles]="allowMovingFiles"
                title="Cover Sheet & Stamp Signed"
                (movedFile)="coverSheetOut($event)"
                (movedFiles)="coverSheetsOut($event)"
              ></file-list>
            </div>
            <div class="responseBody" *ngIf="item.CommentResolutionForm && item.CommentResolutionForm.length > 0">
              <file-list
                [fileList]="item.CommentResolutionForm"
                [showDetail]="true"
                [allowMovingFiles]="allowMovingFiles"
                title="Comment Resolution Form"
                (movedFile)="resolutionFormOut($event)"
                (movedFiles)="resolutionFormsOut($event)"
              ></file-list>
            </div>
            <div *ngIf="item.MarkedUpComments && item.MarkedUpComments.length > 0">
              <file-list
                [fileList]="item.MarkedUpComments"
                [showDetail]="true"
                [allowMovingFiles]="allowMovingFiles"
                title="Additional Files"
                (movedFile)="commentOut($event)"
                (movedFiles)="commentsOut($event)"
              ></file-list>
            </div>
          </div>
        </div>
      </ng-container>
    </aecom-form-item>
  </aecom-form-group>
  <aecom-form-group columnSpan="12" columns="2" title="Processing Metadata" style="margin-bottom: 40px">
    <aecom-form-item [label]="'Internal Due Date'" columnSpan="1">
      <div
        class="itemText"
        *ngIf="entity.InternalDueDate; else notProvide"
      >
        {{ getFixedDateTime(entity.InternalDueDate) }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Key Words'" columnSpan="1">
      <div class="itemText" style="display: flex; flex-wrap: wrap" *ngIf="keywords.length > 0; else notProvide">
        <ng-container *ngFor="let tag of keywords">
          <div style="position: relative; margin-right: 6px; margin-bottom: 6px;">
            <div class="tag">
              {{tag | titlecase}}
            </div>
          </div>
        </ng-container>
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Target Due Date'" columnSpan="1">
      <div
        class="itemText"
        *ngIf="entity.submittal_create && entity.submittal_create.length > 0 && entity.submittal_create[0].ScheduledReviewedDate; else notProvide"
      >
        {{ getFixedDateTime(entity.submittal_create[0].ScheduledReviewedDate) }}
      </div>
    </aecom-form-item>
  </aecom-form-group>
  <aecom-form-group columnSpan="12" columns="2" title="Processing Roles" style="margin-bottom: 40px">
    <aecom-form-item [label]="'Doc Control'" columnSpan="1">
      <div class="itemText" *ngIf="docControlUser; else (showDocControl===true && notAvailable) || notProvide">
        {{ docControlUser?.displayName }} ({{ docControlUser?.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Coordinator'" columnSpan="1">
      <div class="itemText" *ngIf="coordinator; else (showCoordinator===true && notAvailable) || notProvide">
        {{ coordinator.displayName }} ({{ coordinator.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Project Manager'" columnSpan="1">
      <div class="itemText" *ngIf="manager; else (showManager===true && notAvailable) || notProvide">
        {{ manager.displayName }} ({{ manager.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Sr. Project Manager'" columnSpan="1" *ngIf="showSRManager">
      <div class="itemText" *ngIf="srManager; else (showSrManager===true && notAvailable) || notProvide">
        {{ srManager.displayName }} ({{ srManager.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'DDC Manager'" columnSpan="1">
      <div class="itemText" *ngIf="ddcManager; else (showDDCManager===true && notAvailable) || notProvide">
        {{ ddcManager.displayName }} ({{ ddcManager.mail }})
      </div>
    </aecom-form-item>
  </aecom-form-group>
  <aecom-form-group *ngIf="showWorkflow" columnSpan="12" columns="1" title="Workflow History" style="margin-bottom: 40px">
    <workflowHistory [data]="workflowHistorys" [showDays]="true"></workflowHistory>
  </aecom-form-group>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>

<ng-template #notProvide>
  <div class="itemText">-</div>
</ng-template>
