import { submittalAppId } from "@shared/staticValue";
import { getFilesByType, prepareMutiLineText } from "@shared/utils";
import { IPSubDocumentDownload, SubmittalRejectDetail } from "../api-generated";
import FileModelWithVersion from "./fileModelWithVersion";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import SubmittalFileType from "./submittalFileType";
export default class RejectHistoryTableItem
{
  public Sequence: number;
  public CoverSheet: FileModelWithVersion[];
  public RejectedBy: string;
  public RejectedDate: string;
  public Note: string;
  public Collapse: boolean;
  
  constructor(item: SubmittalRejectDetail, users: IContractUserWithUserInfo[], docs: IPSubDocumentDownload[], index: number) {
    this.Sequence = index + 1;
    const documnetsForThis = docs.filter((d) => {
      return d.ReferenceId === item.Guid;
    });
    console.log(documnetsForThis);
    if (documnetsForThis.length > 0) {
      this.CoverSheet = getFilesByType(
        documnetsForThis,
        SubmittalFileType.CoverSheetStampSigned,
      );
    } else {
      this.CoverSheet = [];
    }
    const rejectedBy = users.find((u)=>{return u.UserId === item.UpdatedBy});
    if(rejectedBy)
    {
      const role = rejectedBy.contract_user_application_role.find((cr)=>{return cr.ApplicationId === submittalAppId});
      this.RejectedBy = `${rejectedBy?.UserInfo?.displayName ?? ""} (${role?.application_role?.Role ?? ""})`;
    }
    else {
      this.RejectedBy = "N/A";
    }
    this.RejectedDate = item.DateUpdated ? this.getFormatedDateAndTime(item.DateUpdated) : "N/A";
    this.Note = item.Note ? prepareMutiLineText(item.Note) : "N/A";
    this.Collapse = true;
  }

  private getFormatedDateAndTime(date: string): string {
    return `${new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
      .replace(", ", "/")
      .replace(" ", "/")} ${new Date(date).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit"
    })}`;
  }
}
