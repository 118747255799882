<div class="maincontainer" *ngIf="submittal">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [entity]="entity" 
        [docs]="docs"
        [defaultTab]="defaultTab"
        [allowMovingFiles]="true"
        (moveResolutionForm)="addFileToAttachment($event,submittalFileType.CommentResolutionForm)"
        (moveResolutionForms)="addFilesToAttachments($event,submittalFileType.CommentResolutionForm)"
        (moveComment)="addFileToAttachment($event, submittalFileType.MarkedUpComments)"
        (moveComments)="addFilesToAttachments($event,submittalFileType.MarkedUpComments)"
      ></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>

              <aecom-button
                data-cy="sendBack"
                *ngIf="!NeedDelegate"
                innerClass="btn lg outline blue"
                (click)="sendBack()"
              >
                Send Back
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!enableSaveDraft || NeedDelegate ? true : null"
                innerClass="btn lg solid green"
                (click)="enableSaveDraft && !NeedDelegate && saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="submittal">
        <div class="col-12" *ngIf="submittal.submittal_disposition">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="Processing"
              class="matchToTab mb24"
            >
              <aecom-form-item
                data-cy="needDelegate"
                [label]="'1. Would you like to delegate to another DDC Manager?'"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      NeedDelegate
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setNeedDelegate(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      !NeedDelegate
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setNeedDelegate(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <ng-container
                *ngIf="NeedDelegate"
              >
                <aecom-form-item
                  id="ddcManager"
                  [label]="'DDC Manager'"
                  columnSpan="1"
                  [required]="true"
                >
                  <aecom-dropdown
                    data-cy="ddcManager"
                    [theme]="'light'"
                    placeholder="Select"
                    [data]="ddcManagersList"
                    (ngValueChange)="setDDCManager($event)"
                    [validate]="attemptToIssue && (!submittalDelegate.submittal_delegate_detail.UserId || submittalDelegate.submittal_delegate_detail.UserId==='')"
                  ></aecom-dropdown>
                </aecom-form-item>
              </ng-container>
            </aecom-form-group>
            <aecom-form-group
              *ngIf="!NeedDelegate"
              columnSpan="6"
              columns="2"
              title="Final Response"
              class="matchToTab mb24"
            >
              <aecom-form-item
                data-cy="disposition"
                [label]="'1. Select the Disposition'"
                columnSpan="6"
                [required]="true"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 1
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(1)"
                  >
                    Revise & Re-Submit
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 2
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(2)"
                  >
                    {{ showReview ? "Reviewed" : "Approved" }}
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 3
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(3)"
                  >
                    {{
                      showReview
                        ? "Reviewed w/ Comments"
                        : "Approved w/ Comments"
                    }}
                  </aecom-button>
                  <aecom-button *ngIf="showDenied"
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 4
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(4)"
                  >
                    Denied
                  </aecom-button>
                </div>
                <div class="error-message" *ngIf="!submittal.submittal_disposition.Disposition && attemptToIssue">
                  Required Field
                </div>
              </aecom-form-item>
              <aecom-form-item
                [label]="'2. Note for DB'"
                [property]="
                  getNoteLength() + '/' + noteMaxLength + ' characters'
                "
                columnSpan="6"
                class="formItemNoMargin"
                data-cy="noteForDB"
                [required]="true"
              >
                <aecom-textbox
                  data-cy="note"
                  rows="6"
                  [theme]="'light'"
                  [notEmpty]="true"
                  [maxlength]="noteMaxLength"
                  [isMultiLine]="true"
                  [(ngValue)]="submittal.submittal_disposition.Note"
                  [disabled]="dispositionForm !== null"
                  (ngValueChange)="setNote($event)"
                  [validate]="
                    attemptToIssue &&
                    (!submittal.submittal_disposition.Note?.length || getNoteLength() > noteMaxLength || baseFunctionService.isInputInvalid(submittal.submittal_disposition.Note))
                  "
                ></aecom-textbox>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
            *ngIf="!NeedDelegate"
              columnSpan="3"
              columns="1"
              title="Comment Resolution Form"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="resolutionForm"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="commentResolutionForm"
                  [theme]="'light'"
                  (ngValueChange)="
                    importFile($event, submittalFileType.CommentResolutionForm)
                  "
                  (ngDeleteChange)="
                    deleteFile($event, submittalFileType.CommentResolutionForm)
                  "
                  [required]="submittal.submittal_disposition.Disposition && submittal.submittal_disposition.Disposition !== 2 && submittal.submittal_disposition.Disposition !== 4"
                ></aecom-upload>
                <div
                  class="error-message"
                  *ngIf="
                    reviewFlow !== 3 &&
                    submittal.submittal_disposition.Disposition !== 2 &&
                    submittal.submittal_disposition.Disposition !== 4 &&
                    commentResolutionForm?.length === 0 &&
                    attemptToIssue
                  "
                >
                  Required Field
                </div>
                <div
                  class="error-message"
                  *ngIf="!commentResolutionFormNameValid"
                >
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
            *ngIf="!NeedDelegate"
              columnSpan="3"
              columns="1"
              title="Additional Files"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="attachment"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="markedUpComments"
                  [theme]="'light'"
                  (ngValueChange)="
                    importFile($event, submittalFileType.MarkedUpComments)
                  "
                  (ngDeleteChange)="
                    deleteFile($event, submittalFileType.MarkedUpComments)
                  "
                ></aecom-upload>
                <div class="error-message" *ngIf="!markedUpCommentsNameValid">
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
              columnSpan="3"
              columns="1"
              *ngIf="!NeedDelegate && submittal.submittal_disposition.Disposition && submittal.submittal_disposition.Note && submittal.submittal_disposition.Note.trim().length > 0"
              title="Cover Sheet & STAMP Signed"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="coverSheet"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="coverSheet"
                  [theme]="'light'"
                  [onlyGenerated]="true"
                  (generateFile)="generateDispositionForm()"
                  (ngDeleteChange)="deleteFile($event, submittalFileType.CoverSheetStampSigned)"
                  [required]="true"
                ></aecom-upload>
                
                <div
                  class="error-message"
                  *ngIf="coverSheet?.length === 0 && attemptToIssue"
                >
                  Required Field
                </div>
                <div class="error-message" *ngIf="!coverSheetNameValid">
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>

          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showDispositionForm" class="up-contentcontainer">
  <app-report [submittal]="entity" [disposition]="dispositionForm" [attachments]="attachments" (closeWindow)="closeDispositionForm($event)"></app-report>
</div>
