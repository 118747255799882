import { FileModel } from "@aecom/core";
import { Component, Input } from "@angular/core";
import RejectHistoryTable from "@models/rejectHistoryTableItem";
import AzureBlobService from "@services/azureBlob.service";
import environment from "src/environments/environment";

@Component({
  selector: "rejectHistoryTable",
  templateUrl: "./rejectHistoryTable.component.html",
  styleUrls: ["./rejectHistoryTable.component.scss"],
})
export default class RejectHistoryTableComponent {
  @Input() data: RejectHistoryTable[] = [];
  
  constructor(public azureBlobService: AzureBlobService) {}

  async preview(item: FileModel): Promise<void> {
    if (environment.fileService === "azure") {
      const url = await this.azureBlobService.generateURL(item);
      window.open(url, "_blank");
    } else {
      window.open(item.URL, "_blank");
    }
  }
}
