import TimeStampWithDraft from "@models/timeStampWithDraft";
import { SubmittalInReview } from "../api-generated";
import { ISubInReview } from "../api-generated/model/iSubInReview";

export default class SubmittalInReviewBase
  extends TimeStampWithDraft
  implements ISubInReview
{
  public Guid?: string;

  public IsCompleted?: boolean;

  constructor(item?: SubmittalInReview) {
    super(item);

    this.Guid = item?.Guid;
    this.IsCompleted = this.IsDraft === true ? (item?.IsCompleted ?? true) : true;
  }
}
