import { Component, Input, OnInit } from "@angular/core";
import RecallHistoryTableItem from "@models/recallHistoryTableItem";
import RejectHistoryTableItem from "@models/rejectHistoryTableItem";
import ReopenHistoryTableItem from "@models/reopenHistoryTableItem";
import LocalApplicationRoleService from "@services/local-application_role.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  IPSubDocumentDownload,
  IPSubItem,
} from "src/app/api-generated";

@Component({
  selector: "subLogTab",
  templateUrl: "./subLogTab.component.html",
  styleUrls: ["./subLogTab.component.scss"],
})
export default class SubLogTabComponent implements OnInit {
  @Input() entity: IPSubItem;

  @Input() docs: IPSubDocumentDownload[];

  rejectHistory: RejectHistoryTableItem[] = [];

  recallHistory: RecallHistoryTableItem[] = [];

  reopenHistory: ReopenHistoryTableItem[] = [];

  constructor(public localContractUserService: LocalContractUserService, public localApplicationRoleService: LocalApplicationRoleService) {}

  ngOnInit(): void {
    const users = this.localContractUserService.getItems();
    if (this.entity.submittal_reject_detail && this.entity.submittal_reject_detail.length > 0) {
      const rejects = [
        ...this.entity.submittal_reject_detail
          .sort(
            (a, b) =>
              new Date(a.DateUpdated).getTime() -
              new Date(b.DateUpdated).getTime(),
          )
      ];
      this.rejectHistory = rejects.map((r, i)=>{
        return new RejectHistoryTableItem(r, users, this.docs, i);
      });
    }
    if (this.entity.submittal_recall?.length > 0 && this.entity.submittal_flow?.length > 0) {
      const flows = [
        ...this.entity.submittal_flow
          .sort(
            (a, b) =>
              new Date(a.DateReceived).getTime() -
              new Date(b.DateReceived).getTime(),
          )
      ];
      const recalls = [
        ...this.entity.submittal_recall
          .sort(
            (a, b) =>
              new Date(a.DateUpdated).getTime() -
              new Date(b.DateUpdated).getTime(),
          )
      ];
      if(this.entity.IsRecalled) {
        recalls.splice(-1);
      }
      this.recallHistory = recalls.map((r, i)=>{
        return new RecallHistoryTableItem(r, users, flows, i);
      });
    }
    if(this.entity.submittal_reopen?.length > 0)
      {
        const reopens = [
          ...this.entity.submittal_reopen
            //.filter((reopen)=>{return reopen.Approved !== null})
            .sort(
              (a, b) =>
                new Date(a.DateRequest).getTime() -
                new Date(b.DateRequest).getTime(),
            )
        ];
        this.reopenHistory = reopens.map((r, i)=>{
          return new ReopenHistoryTableItem(r, this.entity, this.docs, users, this.localApplicationRoleService, i);
        })
      }
  }

}
