import { v4 as uuidv4 } from 'uuid';
import { IPSubReviewer } from "../api-generated";
import {
  IPSubItem,
  IPSubReviewerResponseInit,
  SubmittalReviewerResponse,
} from "../api-generated";
import { getVersionString } from "../shared/utils";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class SubmittalResponse
  extends TimeStampWithDraft
  implements IPSubReviewerResponseInit
{
  public Guid?: string;

  public SubmittalReviewerId: string;

  public hasComments: boolean;

  public Response?: string;

  public Submittal_version: string;

  public tempId?: string;

  public SubmittalId: string;
  
  public docSave?: string[];
  
  public docRemove?: string[];

  constructor(
    subItem: IPSubItem,
    reviewerItem: IPSubReviewer,
    responseItem?: SubmittalReviewerResponse,
  ) {
    super(responseItem);

    this.SubmittalId = subItem.Guid;

    if (responseItem) {
      if(responseItem.IsDraft === false)
      {
        this.tempId = uuidv4();
      } else {
        this.Guid = responseItem.Guid;
      }
      this.hasComments = responseItem.hasComments;
      this.SubmittalReviewerId = responseItem.SubmittalReviewerId;
      this.Response = responseItem.Response ?? '';
      this.Submittal_version = responseItem.Submittal_version;
    } else {
      this.SubmittalReviewerId = reviewerItem.Guid;
      this.Submittal_version = getVersionString(subItem.SubmittalNumber);
      this.hasComments = true;
      this.tempId = uuidv4();
    }
  }
}
