import ReviewerWithUserInfo from "@models/reviewerWithUserInfo";
import WatcherWithUserInfo from "@models/watcherWithUserInfo";

export default class WatcherItemDetail {
  public Guid: string;

  public SubmittalGuid: string;

  public UserId: string;

  public DateUpdated: string;

  public UserName: string;

  public UserOrganization: string;

  public UserTrackName: string;

  constructor(item: ReviewerWithUserInfo | WatcherWithUserInfo) {
    this.Guid = item.Guid ?? "";

    this.SubmittalGuid = item.SubmittalId ?? "";

    this.UserId = item.UserId ?? "";

    this.DateUpdated = item.DateUpdated
      ? `${new Date(item.DateUpdated).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })} ${new Date(item.DateUpdated).toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        })}`
      : "-";

    this.UserName = item.UserInfo?.displayName ?? "";

    this.UserOrganization = item.UserInfo?.companyName ?? "[Organization]";

    this.UserTrackName = item.UserInfo?.department ?? "[Track Name]";
  }
}
