import { FileModel } from "@aecom/core";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import FileModelWithVersion from "@models/fileModelWithVersion";
import ReviewerItemDetail from "@models/reviewerItemDetail";
import ReviewerWithUserInfo from "@models/reviewerWithUserInfo";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalStatus from "@models/submittalStatus";
import WatcherItemDetail from "@models/watcherItemDetail";
import WatcherWithUserInfo from "@models/watcherWithUserInfo";
import { getFilesByType, getVersionString } from "@shared/utils";
import { IPSubDocumentDownload, IPSubItem } from "src/app/api-generated";

@Component({
  selector: "subBluebeamTab",
  templateUrl: "./subBluebeamTab.component.html",
  styleUrls: ["./subBluebeamTab.component.scss"],
})
export default class SubBluebeamTabComponent implements OnInit, OnChanges {
  @Input() reviewers: ReviewerWithUserInfo[];

  @Input() watchers: WatcherWithUserInfo[];

  @Input() turnOnNotifyUser = false;

  @Input() docs: IPSubDocumentDownload[] = [];

  @Input() entity: IPSubItem;

  @Input() requireBluebeam = false;

  @Input() showAddUserIcon = false;

  @Input() allowMovingFiles = true;

  @Output() movedAttachment = new EventEmitter<FileModel>();

  @Output() movedAttachments = new EventEmitter<FileModel[]>();

  @Output() addUsers = new EventEmitter<boolean>();

  public reviewerList: WatcherItemDetail[];

  public submittedReviewerList: ReviewerItemDetail[];

  public unSubmittedReviewerList: WatcherItemDetail[];

  public observerList: WatcherItemDetail[];

  public comments: FileModelWithVersion[];

  public history: ReviewerItemDetail[];

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(): void {
    this.loadData();
  }

  loadData(): void {
    console.log(this.docs);
    console.log(this.allowMovingFiles);
    this.comments = getFilesByType(
      this.docs.filter((item) => {
        return (
          item.Status === SubmittalStatus.Bluebeam_Request &&
          item.FileType === SubmittalFileType.Comments
        );
      }),
    );
    this.reviewerList = [];
    this.submittedReviewerList = [];
    this.unSubmittedReviewerList = [];
    this.history = [];
    this.observerList = this.watchers.map((item) => {
      return new WatcherItemDetail(item);
    });

    this.reviewers.forEach((item) => {
      this.reviewerList.push(new WatcherItemDetail(item));

      if (item.submittal_reviewer_response && item.submittal_reviewer_response.length > 0) {
        const currentResponse = item.submittal_reviewer_response
        .sort((a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
        ).find((res) => {
            return (
              !res.IsDraft &&
              res.Submittal_version ===
                getVersionString(this.entity.SubmittalNumber)
            );
          });

        if (currentResponse) {
          this.submittedReviewerList.push(
            new ReviewerItemDetail(item, this.docs, currentResponse),
          );
        } else {
          this.unSubmittedReviewerList.push(new WatcherItemDetail(item));
        }
        const prevResponse = item.submittal_reviewer_response.filter((res) => {
          return !res.IsDraft;
        });
        if (prevResponse && prevResponse.length > 1) {
          prevResponse.shift();
          prevResponse.forEach((res) => {
            this.history.push(new ReviewerItemDetail(item, this.docs, res));
          });
        }
      } else {
        this.unSubmittedReviewerList.push(new WatcherItemDetail(item));
      }
    });
  }

  openWindow(isReviewerWindow: boolean): void {
    this.addUsers.emit(isReviewerWindow);
  }

  fileOut(item: FileModel): void {
    // console.log(item);
    this.movedAttachment.emit(item);
  }

  filesOut(item: FileModel[]): void {
    // console.log(item);
    this.movedAttachments.emit(item);
  }
}
