import LocalContractUserService from "@services/local-contractUser.service";
import { IPSubWatcherInit, IUser } from "../api-generated";

export default class WatcherWithUserInfo implements IPSubWatcherInit {
  public DateCreated?: string;
  public CreatedBy?: string;
  public DateUpdated?: string;
  public UpdatedBy?: string;
  public DateDeleted?: string;
  public DeletedBy?: string;
  public Guid?: string;
  public SubmittalId: string;
  public ContractId: string;
  public WatcherId: string;
  public UserInfo?: IUser;
  public UserId?: string;

  constructor(
    user: IPSubWatcherInit,
    localContractUserService: LocalContractUserService,
  ) {
    this.DateCreated = user?.DateCreated;
    this.CreatedBy = user?.CreatedBy;
    this.DateUpdated = user?.DateUpdated;
    this.UpdatedBy = user?.UpdatedBy;
    this.DateDeleted = user?.DateDeleted;
    this.DeletedBy = user?.DeletedBy;
    this.Guid = user?.Guid;
    this.SubmittalId = user?.SubmittalId;
    this.ContractId = user?.ContractId;
    this.WatcherId = user?.WatcherId;

    this.UserInfo = localContractUserService.getUserById(
      user.WatcherId,
    )?.UserInfo;

    this.UserId = user?.WatcherId;
  }
}
