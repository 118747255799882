<div class="maincontainer" *ngIf="entity && docs">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="docs" [defaultTab]="defaultTab"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                innerClass="btn lg outline orange"
                (click)="back()"
                data-cy="back"
                >back</aecom-button
              >
              
              <aecom-button
                *ngIf="showReassignBtn"
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>
              <aecom-button
                *ngIf="(isDocControl || isDbAdmin) && (isReject || showRevise)"
                innerClass="btn lg solid blue"
                (click)="reviseSubmittal()"
                data-cy="revise"
              >
                Revise
              </aecom-button>

              <aecom-button
                *ngIf="showRecallButton && isDbAdmin"
                innerClass="btn lg outline blue"
                (click)="recallSubmittal()"
                data-cy="recall"
              >
                Request to Recall
              </aecom-button>
              <aecom-button
                *ngIf="showReopenBtn"
                innerClass="btn lg outline blue"
                (click)="reopen()"
              >
                Reopen
              </aecom-button>
              <!-- <aecom-button
                *ngIf="showReopenAcknowledge"
                [innerClass]="acknowledge?'btn lg solid blue':'btn lg outline blue'"
                (click)="reopenApproval()"
              >
                Submit
              </aecom-button> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="12">
            <aecom-form-group
              columnSpan="12"
              columns="1"
              [title]="title"
              style="margin-bottom: 40px"
            >
              <aecom-form-item *ngIf="showResult" [label]="noteLabel">
                <div class="itemText" [innerHtml]="note">
                </div>
              </aecom-form-item>

              <div *ngIf="showResult" class="row" style="margin-top: 80px">
                <div class="col-6" style="margin-bottom: 60px">
                  <aecom-form-item
                    *ngIf="coverSheet && coverSheet.length > 0"
                    [label]="coverSheetLable"
                  >
                    <file-list
                      [fileList]="coverSheet"
                      [allowMovingFiles]="false"
                    ></file-list>
                  </aecom-form-item>
                </div>

                <div class="col-6" style="margin-bottom: 60px">
                  <aecom-form-item
                    *ngIf="
                      commentResolutionForm && commentResolutionForm.length > 0
                    "
                    [label]="'Comment Resolution Form'"
                  >
                    <file-list
                      [fileList]="commentResolutionForm"
                      [allowMovingFiles]="false"
                    ></file-list>
                  </aecom-form-item>
                </div>

                <div class="col-6" style="margin-bottom: 60px">
                  <aecom-form-item
                    *ngIf="markUpComments && markUpComments.length > 0"
                    [label]="'Additional Files'"
                  >
                    <file-list
                      [fileList]="markUpComments"
                      [allowMovingFiles]="false"
                    ></file-list>
                  </aecom-form-item>
                </div>
              </div>

              <div
                class="text-center"
                style="padding-top: 35px"
                *ngIf="!showResult"
              >
                <div>
                  <i class="icon icon-action-basic_todo"></i>
                  <div style="color: #aaa; padding-top: 10px">
                    No Response Yet
                  </div>
                </div>
              </div>
            </aecom-form-group>

            <reopen-detail *ngIf="showReopenItem" [reopen]="reopenItem" [readonly]="true" style="grid-column: span 12; margin-bottom: 40px;"></reopen-detail>
            
            <aecom-form-group
              *ngIf="showReopenAcknowledge"
              columnSpan="12"
              columns="4"
              title="Acknowledge Change?"
              class="headerSubTitle"
              style="margin: 40px 0; display: block;"
            >
              <aecom-form-item data-cy="acknowledge" columnSpan="1">
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab blue"
                    (click)="reopenApproval()"
                  >
                    Yes
                  </aecom-button>
                </div>
              </aecom-form-item>
            </aecom-form-group>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>
