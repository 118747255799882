<div class="maincontainer" *ngIf="submittal && reviewers && watchers && docs">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [entity]="entity" 
        [docs]="docs"
        [defaultTab]="defaultTab"
        [reviewers]="reviewers"
        [watchers]="watchers"
        [allowMovingFiles]="true"
        [turnOnNotifyUser]="true"
        [showAddUserIcon]="true"
        (addUsers)="openWindowClick($event)"
        (moveResolutionForm)="addFileToAttachment($event,submittalFileType.CommentResolutionForm)"
        (moveResolutionForms)="addFilesToAttachments($event,submittalFileType.CommentResolutionForm)"
        (moveComment)="addFileToAttachment($event, submittalFileType.MarkedUpComments)"
        (moveComments)="addFilesToAttachments($event,submittalFileType.MarkedUpComments)"
      ></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>
              <aecom-button
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>
              <!-- <aecom-button
                *ngIf="isDoc"
                data-cy="qaqcFail"
                innerClass="btn lg outline blue"
                (click)="qaqcFail()"
              >
                QA/QC FAIL
              </aecom-button> -->

              <aecom-button
                data-cy="requestComments"
                *ngIf="requireBluebeam"
                innerClass="btn lg outline blue"
                (click)="requestComments()"
              >
                Request Comments
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!enableSaveDraft || !submittal.IsQAQCCompliance ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="submittal">
        <div class="col-12" *ngIf="submittal.submittal_compile">
          <aecom-form columns="6">
            <ng-container *ngIf="submittal.IsQAQCCompliance">
              <aecom-form-group
                columnSpan="6"
                columns="2"
                title="Processing"
                style="margin-bottom: 40px"
                class="matchToTab"
              >
                <aecom-form-item label="Key Words" columnSpan="1">
                  <bbj-tag id="topic" data-cy="topic" [(tagsIn)]="submittal.KeyWords" [allTags]="allKeyWords" (tagsInChange)="enableSaveDraft = hasChanges()"></bbj-tag>
                </aecom-form-item>
                <aecom-form-item columnSpan="1"></aecom-form-item>
                <aecom-form-item
                  [label]="'Internal Notes'"
                  [property]="
                    getInternalNotesLength() + '/' + internalNotesMaxLength + ' characters'
                  "
                  columnSpan="2"
                  class="formItemNoMargin"
                >
                  <aecom-textbox
                    data-cy="notes"
                    rows="3"
                    [theme]="'light'"
                    [notEmpty]="true"
                    [maxlength]="internalNotesMaxLength"
                    [isMultiLine]="true"
                    [(ngValue)]="submittal.submittal_notes.Notes"
                    (ngValueChange)="setInternalNotes($event)"
                    [validate]="attemptToIssue && (getInternalNotesLength() > internalNotesMaxLength || baseFunctionService.isInputInvalid(submittal.submittal_notes.Notes))"
                  ></aecom-textbox>
                </aecom-form-item>
              </aecom-form-group>

              <aecom-form-group
                columnSpan="6"
                columns="2"
                title="Draft Response"
                style="margin-bottom: 40px"
                class="matchToTab"
              >
                <aecom-form-item
                  data-cy="disposition"
                  [label]="'Recommended Disposition'"
                  [required]="true"
                  columnSpan="6"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        submittal.submittal_compile.Disposition === 1
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setDisposition(1)"
                    >
                      Revise & Re-Submit
                    </aecom-button>

                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        submittal.submittal_compile.Disposition === 2
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setDisposition(2)"
                    >
                      {{ showReview ? "Reviewed" : "Approved" }}
                    </aecom-button>

                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        submittal.submittal_compile.Disposition === 3
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setDisposition(3)"
                    >
                      {{
                        showReview
                          ? "Reviewed w/ Comments"
                          : "Approved w/ Comments"
                      }}
                    </aecom-button>
                    <aecom-button *ngIf="showDenied"
                      innerClass="btn solid btn-tab {{
                        submittal.submittal_compile.Disposition === 4
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setDisposition(4)"
                    >
                      Denied
                    </aecom-button>
                  </div>
                </aecom-form-item>

                <aecom-form-item
                  [label]="'Note for DB'"
                  [required]="true"
                  [property]="
                    getNoteLength() + '/' + noteMaxLength + ' characters'
                  "
                  columnSpan="6"
                  class="formItemNoMargin"
                >
                  <aecom-textbox
                    data-cy="note"
                    rows="6"
                    [theme]="'light'"
                    [notEmpty]="true"
                    [maxlength]="noteMaxLength"
                    [isMultiLine]="true"
                    [(ngValue)]="submittal.submittal_compile.Note"
                    (ngValueChange)="setNote($event)"
                    [validate]="
                      attemptToIssue &&
                      (!submittal.submittal_compile.Note?.length || getNoteLength() > noteMaxLength || baseFunctionService.isInputInvalid(submittal.submittal_compile.Note))
                    "
                  ></aecom-textbox>
                </aecom-form-item>
              </aecom-form-group>

              <aecom-form-group
                columnSpan="3"
                columns="1"
                title="Comment Resolution Form"
                style="margin-bottom: 40px"
              >
                <aecom-form-item>
                  <aecom-upload
                    data-cy="resolutionForm"
                    [isNewStyle]="true"
                    [multiFiles]="true"
                    [canDelete]="true"
                    [data]="commentResolutionForm"
                    [theme]="'light'"
                    (ngValueChange)="
                      importFile($event, submittalFileType.CommentResolutionForm)
                    "
                    (ngDeleteChange)="
                      deleteFile($event, submittalFileType.CommentResolutionForm)
                    "
                  ></aecom-upload>
                </aecom-form-item>

                <div
                  class="error-message"
                  *ngIf="
                    (submittal.submittal_compile.Disposition == 1 ||
                      submittal.submittal_compile.Disposition == 3) &&
                    commentResolutionForm?.length === 0 &&
                    attemptToIssue
                  "
                >
                  Required Field
                </div>

                <div
                  class="error-message"
                  *ngIf="!commentResolutionFormNameValid"
                >
                  File Name cannot include special characters other than '-', '_',
                  '.', '@'
                </div>
              </aecom-form-group>

              <aecom-form-group
                columnSpan="3"
                columns="1"
                title="Additional Files"
                style="margin-bottom: 40px"
              >
                <aecom-form-item>
                  <aecom-upload
                    data-cy="attachment"
                    [isNewStyle]="true"
                    [multiFiles]="true"
                    [canDelete]="true"
                    [data]="markedUpComments"
                    [theme]="'light'"
                    (ngValueChange)="
                      importFile($event, submittalFileType.MarkedUpComments)
                    "
                    (ngDeleteChange)="
                      deleteFile($event, submittalFileType.MarkedUpComments)
                    "
                  ></aecom-upload>

                  <div class="error-message" *ngIf="!markedUpCommentsNameValid">
                    File Name cannot include special characters other than '-',
                    '_', '.', '@'
                  </div>
                </aecom-form-item>
              </aecom-form-group>
            </ng-container>
            <ng-container *ngIf="!submittal.IsQAQCCompliance">
              <rejectForm style="grid-column: span 6 / auto;"
                [(submittalRejectDetail)]="submittal.submittal_reject_detail" 
                [(coverSheet)]="coverSheet" 
                [(removedFileIds)]="removedFileIds" 
                [entity]="entity" 
                [tempId]="submittal.tempId" 
                [canIssueSubmittal]="attemptToIssue">
              </rejectForm>
            </ng-container>
            
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow">
    <select-user
      [tableData]="tableRows"
      [title]="selectUserWindowTitle"
      notificationTitle="Save Changes?"
      notificationBody="Confirmation will add the reviewer or observer to the list and they will be notified by email. To return to the Home page, please use the Back button."
      (close)="closeWindow($event)"
      [(firstOpen)]="firstOpen"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>
