<div class="page-header">
  <div class="header-title">
    <p>
      <a [routerLink]="['/' + submittal.ContractId + '/list']">
        Submittal list &gt;
      </a>

      <span>{{ number }}</span>
    </p>

    <h1>
      <i class="icon icon-lg" [ngClass]="icon"></i>
      <span style="text-transform: uppercase">{{ status }}</span>
    </h1>
  </div>

  <div style="flex: 1">
    <div class="header-actions">
      <ng-container *ngIf="isconfidential && isconfidential != null">
        <aecom-button innerClass="btn sm solid red unchange con-padding ">
          Confidential
        </aecom-button>
      </ng-container>

      <aecom-button [innerClass]="priorityBackground">
        {{ priority }}
      </aecom-button> &nbsp;
  
    </div>

    <div class="header-subTitle">{{ number }}</div>
  </div>
  <div style="position: absolute; right: 0; bottom: -20px; font-size: 17px;">{{ title }}</div>
</div>