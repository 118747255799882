<div class="contentcontainer" *ngIf="files">
  <div
    *ngIf="title"
    [ngClass]="showSeparator ? 'header section-bottom-border' : 'header'"
  >
    <span>{{ title }}</span>

    <div class="actionsBlock" *ngIf="files.length > 0">
      <i
        class="
          icon
          icon-action_download
          icon-blue
          icon-cus_green
          icon-lg
          pointer
        "
        (click)="downloadAll()"
      ></i>

      <i
        *ngIf="allowMovingFiles"
        class="
          icon icon-moveFile icon-blue
          icon-cus_green icon-cus_green
          icon-lg
          pointer
        "
        style="margin-left: 20px"
        (click)="moveFiles()"
      ></i>
    </div>
  </div>

  <div class="file-list" *ngIf="files.length">
    <div
      *ngIf="showDetail"
      style="height: 40px; display: flex; align-items: center"
    >
      <div *ngIf="showVersion" class="col-1" style="padding: 0">
        <div class="fileTitle" style="margin-left: 15px">REV #</div>
      </div>

      <div [ngClass]="showVersion ? 'col-5' : 'col-6'" style="padding: 0">
        <div
          class="fileTitle"
          [ngStyle]="{ 'margin-left': showVersion ? '0' : '15px' }"
        >
          File Name
        </div>
      </div>

      <div class="col-2" style="padding: 0">
        <div class="fileTitle">File Size</div>
      </div>

      <div class="col-4" style="padding: 0">
        <div class="fileTitle">Uploaded Date</div>
      </div>
    </div>

    <ng-container *ngFor="let doc of files; let i = index">
      <div style="position: relative" class="hoverFront">
        <li *ngIf="showDetail">
          <div
            *ngIf="showVersion"
            class="col-1"
            style="display: flex; padding: 0"
          >
            <div style="margin-left: 15px">
              Rev.{{ doc.Version.replace("REV", "") }}
            </div>
          </div>

          <div
            [ngClass]="showVersion ? 'col-5' : 'col-6'"
            style="padding: 0; align-items: center; display: flex"
          >
            <a
              class="fileName"
              [tooltip]="doc.Name"
              [maxCharacters]="maxCharacters"
              [ngStyle]="{ 'margin-left': showVersion ? '0' : '15px' }"
              (click)="preview(doc)"
            >
              {{
                doc.Name.length >= maxCharacters
                  ? doc.Name.slice(0, maxCharacters) + "..."
                  : doc.Name
              }}
            </a>
          </div>

          <div class="col-2" style="display: flex; padding: 0">
            <div>{{ doc.Size }}</div>
          </div>

          <div class="col-4" style="display: flex; padding: 0">
            <div class="dateOverflow">{{ getFormatDate(doc.Base64) }}</div>

            <div class="actionsBlock">
              <i
                class="
                  icon
                  icon-action_download
                  icon-blue
                  icon-cus_green
                  icon-lg
                  hoverIcon
                "
                (click)="download(doc)"
              ></i>

              <i
                *ngIf="allowMovingFiles"
                class="
                  icon icon-moveFile icon-blue
                  icon-cus_green icon-cus_green
                  icon-lg
                  hoverIcon
                "
                (click)="moveFile(doc)"
              ></i>
            </div>
          </div>
        </li>

        <li *ngIf="!showDetail">
          <i [class]="getFileIcon(doc.Name)" style="margin-left: 15px"></i>

          <a
            class="fileName"
            [tooltip]="doc.Name"
            [maxCharacters]="maxCharacters"
            (click)="preview(doc)"
          >
            {{
              doc.Name.length >= maxCharacters
                ? doc.Name.slice(0, maxCharacters) + "..."
                : doc.Name
            }}
          </a>

          <div class="actionsBlock">
            <i
              class="
                icon
                icon-action_download
                icon-blue
                icon-cus_green
                icon-lg
                hoverIcon
              "
              (click)="download(doc)"
            ></i>

            <i
              *ngIf="allowMovingFiles"
              class="
                icon icon-moveFile icon-blue
                icon-cus_green icon-cus_green
                icon-lg
                hoverIcon
              "
              (click)="moveFile(doc)"
            ></i>
          </div>
        </li>
      </div>
    </ng-container>
  </div>
</div>
