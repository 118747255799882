import { Injectable } from "@angular/core";
import SubmittalStatus from "../models/submittalStatus";
import SubmittalRole from "@models/submittalRole";

@Injectable({
  providedIn: "root"
})
export default class GetStatusService {
  constructor() {}
  getStatusFilters(userRole: string, isReviewer?: boolean): any[] {
    const allstatusList = [
      {
        //0
        name: "Draft",
        icon: "icon-lg icon-purple icon-pencil",
        active: true,
        isHidden: true
      },
      {
        //1
        name: "Submitted",
        icon: "icon-lg icon-purple icon-action_send",
        active: true,
        isHidden: true
      },
      {
        //2
        name: "QA/QC Fail",
        icon: "icon-lg icon-purple icon-document_close",
        active: true,
        isHidden: true
      },
      {
        //3
        name: "In Review",
        icon: "icon-lg icon-purple icon-action_rotate_anticlockwise",
        active: true,
        isHidden: true
      },
      {
        //4
        name: "For Information",
        icon: "icon-lg icon-purple icon-action_forinfo ",
        active: true,
        isHidden: true
      },
      {
        //5
        name: "Superseded",
        icon: "icon-lg icon-purple icon-action_superseded ",
        active: true,
        isHidden: true
      },
      {
        //6
        name: "Compiling",
        icon: "icon-lg icon-purple icon-action_combine ",
        active: true,
        isHidden: true
      },
      {
        //7
        name: "Bluebeam",
        icon: "icon-lg icon-blubeam-blue icon-document_bluebeam",
        active: true,
        isHidden: true
      },
      {
        //8
        name: "Bluebeam Compiling",
        icon: "icon-lg icon-blubeam-blue icon-document_bluebeam",
        active: true,
        isHidden: true
      },
      {
        //9
        name: "Bluebeam Request",
        icon: "icon-lg icon-blubeam-blue  icon-document_bluebeam",
        active: true,
        isHidden: true
      },
      {
        //10
        name: "Bluebeam Uploaded",
        icon: "icon-lg icon-blubeam-blue icon-document_bluebeam",
        active: true,
        isHidden: true
      },
      {
        //11
        name: "Bluebeam File Revised",
        icon: "icon-lg icon-blubeam-blueicon-document_bluebeam",
        active: true,
        isHidden: true
      },
      {
        //12
        name: "File Revised",
        icon: "icon-lg icon-purple icon-action_re-send",
        active: true,
        isHidden: true
      },
      {
        //13
        name: "Disposition",
        icon: "icon-lg icon-purple icon-pmc_disposition",
        active: true,
        isHidden: true
      },
      {
        //14
        name: "In Revision",
        icon: "icon-lg icon-purple icon-action_clockwise",
        active: true,
        isHidden: true
      },
      {
        //15
        name: "Reviewed",
        icon: "icon-lg icon-purple icon-star",
        active: true,
        isHidden: true
      },
      {
        //16
        name: "Reviewed With Comments",
        icon: "icon-lg icon-purple icon-star",
        active: true,
        isHidden: true
      },

      {
        //17
        name: "Canceled",
        icon: "icon-lg icon-purple icon-action_remove_circle",
        active: true,
        isHidden: true
      },
      {
        //18
        name: "Recalled",
        icon: "icon-lg icon-purple icon-action_remove_circle",
        active: true,
        isHidden: true
      },
      {
        //19
        name: "Bluebeam Disposition",
        icon: "icon-lg icon-purple icon-action_remove_circle",
        active: true,
        isHidden: true
      },
      {
        //20
        name: "Compiling Disposition",
        icon: "icon-lg icon-purple icon-action_remove_circle",
        active: true,
        isHidden: true
      }
    ];
    console.log(allstatusList);
    let statusList = [];
    // TODO:// ask about cancelled and reacalled

    switch (userRole) {
      case SubmittalRole.DBAdmin:
        allstatusList[0].isHidden = false;
        allstatusList[1].isHidden = false;
        allstatusList[2].isHidden = false;
        allstatusList[4].isHidden = false;
        allstatusList[5].isHidden = false;
        allstatusList[12].isHidden = false;
        allstatusList[14].isHidden = false;
        allstatusList[15].isHidden = false;
        allstatusList[16].isHidden = false;
        allstatusList[17].isHidden = false;
        allstatusList[18].isHidden = false;
        statusList = allstatusList.filter((i) => !i.isHidden);
        break;
      case SubmittalRole.Doc_Control:
        allstatusList[0].isHidden = false;
        allstatusList[1].isHidden = false;
        allstatusList[2].isHidden = false;
        allstatusList[3].isHidden = false;
        allstatusList[4].isHidden = false;
        allstatusList[5].isHidden = false;
        allstatusList[6].isHidden = false;
        allstatusList[7].isHidden = false;
        allstatusList[9].isHidden = false;
        allstatusList[10].isHidden = false;
        allstatusList[11].isHidden = false;
        allstatusList[12].isHidden = false; //file reivsed
        allstatusList[13].isHidden = false;
        allstatusList[14].isHidden = false;
        allstatusList[15].isHidden = false;
        allstatusList[16].isHidden = false;
        allstatusList[17].isHidden = false;
        allstatusList[18].isHidden = false;
        allstatusList[20].isHidden = false;
        statusList = allstatusList.filter((i) => !i.isHidden);
        break;
      case SubmittalRole.Manager:
      case SubmittalRole.DDC_Manager:
      case SubmittalRole.SR_Manager:
        allstatusList[1].isHidden = false;
        allstatusList[2].isHidden = false;
        allstatusList[3].isHidden = false;
        allstatusList[4].isHidden = false;
        allstatusList[5].isHidden = false;
        allstatusList[6].isHidden = false;
        allstatusList[7].isHidden = false;
        allstatusList[9].isHidden = false;
        allstatusList[10].isHidden = false;
        allstatusList[11].isHidden = false;
        allstatusList[12].isHidden = false;
        allstatusList[13].isHidden = false;
        allstatusList[14].isHidden = false;
        allstatusList[15].isHidden = false;
        allstatusList[16].isHidden = false;
        allstatusList[19].isHidden = false;
        allstatusList[20].isHidden = false;
        statusList = allstatusList.filter((i) => !i.isHidden);
        break;
      case SubmittalRole.Coordinator:
        allstatusList[1].isHidden = false;
        allstatusList[2].isHidden = false;
        allstatusList[3].isHidden = false;
        allstatusList[4].isHidden = false;
        allstatusList[5].isHidden = false;
        allstatusList[6].isHidden = false;
        allstatusList[7].isHidden = false;
        allstatusList[8].isHidden = false;
        allstatusList[9].isHidden = false;
        allstatusList[10].isHidden = false;
        allstatusList[11].isHidden = false;
        allstatusList[12].isHidden = false;
        allstatusList[13].isHidden = false;
        allstatusList[14].isHidden = false;
        allstatusList[15].isHidden = false;
        allstatusList[16].isHidden = false;
        statusList = allstatusList.filter((i) => !i.isHidden);
        break;

      default:
        allstatusList[1].isHidden = false;
        allstatusList[2].isHidden = false;
        allstatusList[3].isHidden = false;
        allstatusList[4].isHidden = false;
        allstatusList[5].isHidden = false;
        allstatusList[6].isHidden = false;
        allstatusList[7].isHidden = false;
        allstatusList[16].isHidden = false;
        allstatusList[18].isHidden = false;
        statusList = allstatusList.filter((i) => !i.isHidden);
        break;
      //  return statusFullList.map((i) => {i.isHidden = false; return i});
    }
    if (isReviewer) {
      allstatusList.forEach((item) => {
        item.isHidden = true;
        return item;
      });
      let allstatusreviewer: any[];
      allstatusList[6].isHidden = false;
      allstatusList[8].isHidden = false;
      allstatusList[9].isHidden = false;
      allstatusList[10].isHidden = false;
      allstatusList[11].isHidden = false;
      allstatusList[19].isHidden = false;
      allstatusList[20].isHidden = false;
      allstatusreviewer = allstatusList.filter((i) => !i.isHidden);
      statusList = [...new Set([...statusList, ...allstatusreviewer])];
    }
    return statusList;
  }

  getIcon(status) {
    switch (status) {
      case SubmittalStatus.Draft:
        return this.getIconStr("icon-action_write", false);
      case SubmittalStatus.Submitted:
        return this.getIconStr("icon-action_send", false);
      case SubmittalStatus.QAQC_Fail:
      case SubmittalStatus.Denied:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.In_Review:
        return this.getIconStr("icon-action_rotate_anticlockwise", false);
      case SubmittalStatus.Compiling:
        return this.getIconStr("icon-action_combine", false);
      case SubmittalStatus.Bluebeam:
        return this.getIconStr("icon-bluebeam", true);
      case SubmittalStatus.Bluebeam_Compiling:
        return this.getIconStr("icon-document_close", true);
      case SubmittalStatus.Bluebeam_Request:
        return this.getIconStr("icon-document_close", true);
      case SubmittalStatus.Bluebeam_Uploaded:
        return this.getIconStr("icon-document_close", true);
      case SubmittalStatus.Disposition:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.In_Revision:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.Reviewed:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.Reviewed_With_Comments:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.Recalled:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.Resubmitted:
        return this.getIconStr("icon-document_close", false);
      case SubmittalStatus.Revise_Resubmit:
        return this.getIconStr("icon-document_close", false);
      default:
        return null;
    }
  }
  getIconStr(iconname: string, isBluebeam: boolean): string {
    return isBluebeam
      ? "icon" + " " + iconname + " " + "icon-blubeam-blue icon-lg"
      : "icon" + " " + iconname + " " + "icon-purple icon-lg";
  }
}
