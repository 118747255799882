import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import IRoutingResolverResult from "@models/routingResolverResult";
import SubmittalRole from "@models/submittalRole";
import LoadingService from "@services/loading.service";
import { getBtnUrlP, getCanEditP, isReviewer } from "@shared/utils";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IPSubItem, SubmittalService } from "../api-generated";
import { HttpErrorResponse } from "@angular/common/http";
import SubmittalStatus from "@models/submittalStatus";
import BaseFunctionService from "@services/baseFunction.service";
import AuthService from "@auth/auth.service";

@Injectable({
  providedIn: "root",
})
export default class RoutingResolver
{
  private currentUserId: string;
  constructor(
    public router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private submittalService: SubmittalService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<IRoutingResolverResult> {
    this.loadingService.start();
    this.currentUserId = this.authService.user.Id;

    const subId = route.params.itemId;
    const contractId = state.url.split("/")[1];

    if(!(state.url.includes("report") || state.url.includes("disposition"))){
      localStorage.removeItem('dispositon');
      localStorage.removeItem('note');
      localStorage.removeItem('hasgeneratedpdf_report');
    }

    return forkJoin([
      this.submittalService.getSubmittalItem(subId),
      this.submittalService.getSubmittalRole(contractId),
    ]).pipe(
      map(([sub, role]: [IPSubItem, string]) => {
        const hasReviewerRole = isReviewer(sub, this.currentUserId);
        const viewOnly = !getCanEditP(sub, role, this.currentUserId, hasReviewerRole);
        const createStep =
                  sub.submittal_create && sub.submittal_create.length > 0
                    ? sub.submittal_create[0]
                    : null;
        let newURL = `/${contractId}/0/view/${sub.Guid}`;
        let altUrl = '';

        if (!viewOnly) {
          newURL =
            "/" +
            getBtnUrlP(
              0,
              contractId,
              sub,
              viewOnly,
              role,
              this.currentUserId,
              hasReviewerRole,
            );
        }
        if (
          role === SubmittalRole.Doc_Control &&
          (sub.Status === SubmittalStatus.Bluebeam_Compiling ||
            sub.Status === SubmittalStatus.Bluebeam_Disposition ||
            sub.Status === SubmittalStatus.Bluebeam_Uploaded) &&
            sub.IsRecalled !== true
        ) {
          altUrl = `/${contractId}/0/bluebeam/${sub.Guid}`;
        }
        if(this.currentUserId === sub.CoordinatorId && sub.Status === SubmittalStatus.Compiling)
        {
          altUrl = `/${contractId}/0/response/${sub.Guid}`;
        }
        if (
          role === SubmittalRole.DBAdmin && this.currentUserId !== sub.SubmittedBy && createStep?.IsConfidentail === true
        ) {
          this.router.navigateByUrl(`/${contractId}/list`);
        }
        else if(state.url !== newURL && state.url !== altUrl) {
          this.router.navigateByUrl(newURL);
        }
        this.baseFunctionService.setEntity(sub);
        return { submittalRole: role, submittal: sub, contractId };
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate([contractId, "error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }
}
