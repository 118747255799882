import { AecomDropDownComponent, IMenuItem, LayoutService } from "@aecom/core";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import AuthService from "@auth/auth.service";
import SubmittalRole from "@models/submittalRole";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalTaskService from "@services/local-task.service";
import NavService from "@services/nav.service";
import { combineLatest, Subscription } from "rxjs";
import {
  ApplicationNavigation,
  Borough,
  BoroughNavigation,
  GlobalNavigation,
  NavigationService,
  TPContract,
} from "src/app/api-generated";
import { ITaskTab } from "src/app/api-generated/model/iTaskTab";
import environment from "src/environments/environment";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export default class BaseComponent implements OnInit, OnDestroy {
  productname = "SUBMITTAL";
  productsubtitle = "";
  productmaintitle = "NYC BBJ PROGRAM";
  productmainicon = "";

  @ViewChild("boroughDropDown") boroughDropDownEL: AecomDropDownComponent;

  @ViewChild("contractDropDown") contractDropDownEL: AecomDropDownComponent;

  private boroughList: BoroughNavigation[] = [];

  private globalList: GlobalNavigation[] = [];

  appItems: IMenuItem[] = [];

  isBoroughMenu = true;

  boroughSelectionList: IMenuItem[] = [];

  boroughSelectionId = "";

  contractSelectionList: IMenuItem[] = [];

  contractSelectionId = "";

  leftNav: IMenuItem[] = [];

  globalNav: IMenuItem[] = [];

  bottomNav: IMenuItem[] = [];

  isBroughManu = true;

  withIcons = true;

  copyright = "© NYC BBJ PROGRAM";

  currentContractId = "";

  subscriptions: Subscription = new Subscription();

  taskList: ITaskTab[];

  isDBAdmin = false;

  constructor(
    public localContractService: LocalContractService,
    public localContractUserService: LocalContractUserService,
    public navService: NavService,
    public navigationService: NavigationService,
    public router: Router,
    public authService: AuthService,
    public layoutService: LayoutService,
    public localTaskService: LocalTaskService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(): Promise<void> {
    const { currentContract } = this.localContractService;
    this.currentContractId = currentContract.Guid;

    this.productmaintitle = currentContract?.Name
      ? currentContract?.Name
      : "---";

    if (!environment.production) {
      this.productmaintitle =
        environment.stage === "staging"
          ? "NYC BBJ PROGRAM - SANDBOX"
          : `${this.productmaintitle} - 8/24/2022 3:00 PM`;
    }

    this.productmainicon = this.getContarctBoroughIcon(currentContract?.Code);
    this.onDataLoaded();

    const currentUser = this.authService.user.Id;
    const userRole =
      this.localContractUserService.getUserById(currentUser).RolesId || SubmittalRole.User;

    if (userRole === SubmittalRole.DBAdmin) {
      this.isDBAdmin = true;
    } else {
      this.taskList = this.localTaskService.getItems();
    }

    this.subscriptions.add(
      combineLatest([
        this.navService.selectedItem$,
        this.localContractService.item$,
      ]).subscribe(([selectedBorough, selectedContract]) => {
        console.log("before", selectedBorough, selectedContract);
        this.resetSideNav(selectedBorough, selectedContract);
        console.log("tasks inside");
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDataLoaded(): void {
    this.boroughList = this.navService.getBroughData().filter((b) => {
      return b.projects.length;
    });

    this.boroughList.sort((a, b) => a.boroughItem.Order - b.boroughItem.Order);
    this.globalList = this.navService.getGlobalData();
    this.globalList.sort((a, b) => a.globalModule.Order - b.globalModule.Order);

    this.globalList.forEach(this.mapGlobalList.bind(this));

    this.globalNav.push({
      name: "Onboarding",
      externalicon: "assets/icons/bbj_onboarding_icon.svg",
      id: "onboarding",
      externalurl:
        "https://apps.powerapps.com/play/e7e45d8e-50c2-4d1b-bafb-dd3cdafd5b66?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
    });
    this.appItems.push({
      name: 'Onboarding',
      icon: 'bbj_onboarding_icon',
      id: 'onboarding',
      externalurl:
        'https://apps.powerapps.com/play/e7e45d8e-50c2-4d1b-bafb-dd3cdafd5b66?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
    });
    const isAdmin = this.authService.user.Permissions.includes("_Admin");
    if (isAdmin) {
      this.appItems.push({
        name: "Settings",
        icon: "bbj_product_icon_settings",
        id: "settings",
        externalurl: `https://${
          environment.production ? "" : "dev."
        }settings.bbjnyc.com/contracts`,
      });
      this.globalNav.push({
        name: "Settings",
        icon: "bbj_product_icon_settings",
        id: "settings",
        externalurl: `https://${
          environment.production ? "" : "dev."
        }settings.bbjnyc.com/contracts`,
      });
    }
    this.globalNav.push({
      name: "Support",
      externalicon: "assets/icons/bbj_action_app_support.svg",
      id: "support",
      externalurl:
        "https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
    });
    this.appItems.push({
      name: "Support",
      icon: "icon-action_app_support",
      id: "support",
      externalurl:
        "https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
    });

    this.resetSideNav(
      this.navService.getSelected(),
      this.localContractService.getItem(),
    );
  }

  mapGlobalList(globalItem: GlobalNavigation): void {
    const subManus: IMenuItem[] = [];

    globalItem.applications.forEach((a) => {
      subManus.push({
        name: BaseComponent.getAppName(a),
        id: a.applicationId,
        url: null,
        externalurl: a.url ?? `${this.currentContractId}/error/comingSoon`,
        children: [],
      });
    });

    if (globalItem.globalModule.Order > 99) {
      this.bottomNav.push({
        name: globalItem.globalModule.Name,
        id: globalItem.globalModule.Guid,
        url: globalItem.globalModule.UrlTemplate,
        children: subManus,
        externalicon: `icon-${globalItem.globalModule.Icon}`.replace(
          ".svg",
          "",
        ),
      });
    } else {
      const newNav: IMenuItem = {
        name: globalItem.globalModule.Name,
        id: globalItem.globalModule.Guid,
        children: subManus,
        externalicon: `assets/icons/${globalItem.globalModule.Icon}`,
      };

      if (subManus.length === 0) {
        if (globalItem.globalModule.Embed) {
          newNav.url = globalItem.globalModule.UrlTemplate;
        } else {
          newNav.externalurl = globalItem.globalModule.UrlTemplate;
        }
      }
      this.globalNav.push(newNav);
    }
  }

  resetSideNav(selectedBorough: Borough, selectedContract: TPContract): void {
    this.boroughSelectionList = [];
    this.boroughSelectionId = "";
    this.contractSelectionList = [];
    this.contractSelectionId = "";
    this.leftNav = [];

    this.boroughSelectionList = this.boroughList.map((b) => {
      return {
        id: b.boroughItem.Guid,
        name: b.boroughItem.Name,
        checked: false,
      };
    });

    if (selectedBorough) {
      let activeBorough = this.boroughSelectionList.filter((b) => {
        return b.id === selectedBorough.Guid;
      });

      if (!activeBorough.length) {
        activeBorough = this.boroughSelectionList.filter((b) => {
          return b.id === selectedContract.BoroughId;
        });
      }

      if (activeBorough.length) {
        this.boroughSelectionId = activeBorough[0].id;
        activeBorough[0].checked = true;

        const projectList = this.boroughList.filter((n) => {
          return n.boroughItem.Guid === activeBorough[0].id;
        })[0].projects;

        this.contractSelectionList = projectList.map((p) => {
          return {
            contract: p.contractItem,
            id: p.contractItem.Guid,
            name: p.contractItem.Name,
            checked: false,
          };
        });

        if (selectedContract) {
          const activeProject = this.contractSelectionList.filter((c) => {
            return c.id === selectedContract.Guid;
          });

          if (activeProject.length) {
            this.contractSelectionId = activeProject[0].id;
            activeProject[0].checked = true;

            const applicationList = projectList.filter((p) => {
              return p.contractItem.Guid === activeProject[0].id;
            })[0].applications;

            this.leftNav = applicationList.map((a) => {
              return {
                name: a.applicationName,
                id: a.applicationId,
                url: null,
                externalurl: a.url ?? `${this.currentContractId}/error/comingSoon`,
                // icon: `${a.logo} ${a.color}`,
                children: [],
                externalicon: `assets/icons/${a.logo}`,
              };
            });
          }
        }
      }
    }
  }

  onBoroughSelected(e: IMenuItem | IMenuItem[]): void {
    if (e && !Array.isArray(e)) {
      const item = e;
      this.navService.setSelectedId(item.id);

      const selectedBorough = this.boroughList.filter((b) => {
        return b.boroughItem.Guid === item.id;
      });

      console.log(JSON.stringify(selectedBorough));
      console.log("after", selectedBorough);

      if (selectedBorough.length && selectedBorough[0].projects.length > 0) {
        this.localContractService.setItem(
          selectedBorough[0].projects[0].contractItem,
        );
      } else {
        this.localContractService.setItem(null);
      }
    }
  }

  onContractSelected(e: IMenuItem | IMenuItem[]): void {
    if (e && !Array.isArray(e)) {
      this.localContractService.setItem(e);
    }
  }

  navigate(node: IMenuItem): void {
    if (
      node.id.toLocaleLowerCase() === "f5960107-e029-11eb-a7b4-ace2d34b68ef"
    ) {
      this.unselectOtherNodes(node);
      this.onBoroughSelected(node);
      return;
    }
    if(node.id.toLocaleLowerCase() === 'f5960497-e029-11eb-a7b4-ace2d34b68ef')
    {
      this.unselectOtherNodes(node);
      window.location.href = node.url;
    }
    // console.log(JSON.stringify(node));
    if (node.url && !node.externalurl) {
      this.navigateByUrl(node);
    } else if (node.externalurl || Boolean(node.url)) {
      window.open(node.externalurl, "_blank");
    } else {
      this.navigateByUrl(node);
    }
  }

  navigateByUrl(node: IMenuItem): void {
    this.unselectOtherNodes(node);
    this.router.navigateByUrl(node.url);
  }

  static getAppName(appObj: ApplicationNavigation): string {
    switch (appObj.applicationId) {
      case "8a013201-0baf-11ea-917a-027e1b1809fa":
        return appObj.applicationName;
      case "8a013205-0baf-11ea-917a-027e1b1809fa":
        return "AHJV hub";
      default:
        return BaseComponent.capSentence(appObj.applicationName);
    }

    // return BaseComponent.capSentence(appObj.applicationName);
  }

  static capSentence(str: string): string {
    const wordsArray = str.toLowerCase().split(" ");

    const capsArray = wordsArray.map((word) => {
      return word[0].toUpperCase() + word.slice(1);
    });

    return capsArray.join(" ");
  }

  unselectOtherNodes(selected: IMenuItem): void {
    if (this.contains(this.leftNav, selected)) {
      this.uncheckAll(this.globalNav);
    } else if (this.contains(this.globalNav, selected)) {
      this.uncheckAll(this.leftNav);
    }
  }

  private uncheckAll(nodes: IMenuItem[]) {
    nodes.forEach((item) => {
      if (item.children) {
        this.uncheckAll(item.children);
      }

      item.checked = false;
    });
  }

  private contains(nodes: IMenuItem[], selected: IMenuItem) {
    return nodes.some((node) => {
      if (node.id === selected.id) {
        return true;
      }

      if (node.children) {
        return this.contains(node.children, selected);
      }

      return false;
    });
  }

  getContarctBoroughIcon(code: string): string {
    if (code.includes("QN")) {
      return "qn_contract_icon";
    } else if (code.includes("BK")) {
      return "bk_contract_icon";
    } else if (code.includes("MN")) {
      return "mn_contract_icon";
    } else if (code.includes("BX")) {
      return "bx_contract_icon";
    } else {
      return "";
    }
  }

  goToHome(): void {
    this.router.navigateByUrl(`${this.currentContractId}/list`)
  }
}
