<div class="report-margin">
  <div class="no-print row justify-content-end action-btns" style="margin: 0; padding: 29px 60px;">
    <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()">
      CANCEL
    </aecom-button>
    <aecom-button innerClass="btn solid blue lg unchnage" (click)="download()">
      DOWNLOAD
    </aecom-button>
    <aecom-button data-cy="savePDF" innerClass="btn solid blue lg unchnage" (click)="save()">
      SAVE
    </aecom-button>
  </div>
  <!-- left -->
  <div class="no-print-margin" style="display: flex; align-items: center; overflow-y: auto; height: 100%; flex: 1">
    <div class="report-page-size" id="pdfFrom" #pdfFrom *ngIf="data">
      <div>
        <div class="d-flex">
          <div>
            <span class="header-page-no-text"><img src="./../../../../assets/img/product-icons/ddc_logo.svg">
            </span>
          </div>
          <div class="ml-auto nopadding">
            <span class="header-page-no-text" style="padding-right: 45px">
              <img src="./../../../../assets/img/product-icons/aecom_hill_av.svg">
            </span>
          </div>
        </div>
        <!-- First Row -->
        <div class="d-flex">
          <div>
            <span class="title-text">
              Submittal Disposition Form
            </span>
          </div>
        </div>
        <!-- Second Row -->
        <div class="d-flex" style="margin-bottom: 30px;">
          <div>
            <span class="h-grey-font">
              {{data.FormTitle}}
            </span>
          </div>
          <div class="ml-auto">
            <span class="h-grey-font">
              {{data.FMSTitle}}
            </span>
          </div>
        </div>
        <!-- Third Row -->
        <div style="margin-bottom: 18px;">
          <div class="blue-border">
            <span class="h-black-font">Submittal Details</span>
          </div>
          <div class="grey-bg">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-4">
                    <div class="sub-info-title">ID</div>
                  </div>
                  <div class="col-8">
                    <div class="sub-info-text">{{data.SubNo}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <div class="sub-info-title">Internal Ref. # (DB)</div>
                  </div>
                  <div class="col-7">
                    <div class="sub-info-text">{{data.Ref}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-4">
                    <div class="sub-info-title">Title</div>
                  </div>
                  <div class="col-8">
                    <div class="sub-info-text">{{data.SubTitle}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <div class="sub-info-title">Phase</div>
                  </div>
                  <div class="col-7">
                    <div class="sub-info-text">{{data.Phase}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-4">
                    <div class="sub-info-title">Issued By</div>
                  </div>
                  <div class="col-8">
                    <div class="sub-info-text">{{data.IssuedBy}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <div class="sub-info-title">Issue Date</div>
                  </div>
                  <div class="col-7">
                    <div class="sub-info-text">{{data.IssuedDate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-4">
                    <div class="sub-info-title">Review Type</div>
                  </div>
                  <div class="col-8">
                    <div class="sub-info-text">{{data.ReviewType}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <div class="sub-info-title">Contract Requirements</div>
                  </div>
                  <div class="col-7">
                    <div class="sub-info-text">{{data.ContractRequirement}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-4">
                    <div class="sub-info-title">Document Type</div>
                  </div>
                  <div class="col-8">
                    <div class="sub-info-text">{{data.DocType}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-5">
                    <div class="sub-info-title">Drawing/Page Count</div>
                  </div>
                  <div class="col-7">
                    <div class="sub-info-text">{{data.Page}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Fourth Row -->
        <div style="margin-bottom: 18px;">
          <div class="blue-border">
            <span class="h-black-font">Disposition</span>
          </div>
          <div style="display: flex; flex-wrap: wrap; padding: 0 10px;">
            <ng-container *ngFor="let item of data.DispositionStamp">
              <div class="col-4"  style="padding: 10px 0;">
                <img src="./../../../../assets/img/checked-box.svg" class="checked" *ngIf="
                item.checked; else unchecked"/>
                &nbsp;<span class="disposition-text">{{item.name}}</span>
                <ng-template #unchecked>
                  <img src="./../../../../assets/img/unchecked-box.svg" class="unchecked" />
                </ng-template>
              </div>
            </ng-container>
            
            <div class="info">
              This information has been reviewed for general conformance with the Contract Documents and does not
              relieve Design-Builder from its responsibilities stipulated in the Contract Documents. By performing this
              review DDC/PMC is not acting in a manner so as to assume responsibility or liability, in whole or in part,
              for all or any part of the Project design or for any documents submitted by Design-Builder.
            </div>
          </div>
        </div>
        
        <!-- Fifth Row -->
        <div class="blackBoxBorder">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-4">
                  <div class="sub-info-title">Responded By</div>
                </div>
                <div class="col-8">
                  <div class="sub-info-text">{{data.RespondedBy}}</div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <div class="sub-info-title">{{data.InitRespondedDate?'Revise Response Date':'Response Date'}}</div>
                </div>
                <div class="col-7">
                  <div class="sub-info-text">{{data.RespondedDate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-4">
                  <div class="sub-info-title">Digital Signature</div>
                </div>
                <div class="col-8">
                  <div class="signature-tb">
                    {{data.RespondedName}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row" *ngIf="data.InitRespondedDate">
                <div class="col-5">
                  <div class="sub-info-title">Initial Response Date</div>
                </div>
                <div class="col-7">
                  <div class="sub-info-text">{{data.InitRespondedDate}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row" style="align-items: baseline;">
                <div class="col-2">
                  <div class="sub-info-title">Remarks</div>
                </div>
                <div class="col-10">
                  <div class="sub-info-text" [innerHtml]="data.Note"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-2">
                  <div class="sub-info-title">Attachments</div>
                </div>
                <div class="col-10">
                  <div class="sub-info-text" *ngIf="attachmentNames; else notExist">{{attachmentNames}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="info" style="position: relative; display: flex; align-items: center; padding-top: 5px;">
        <div>{{footLeft}}</div>
        <div class="mx-auto">{{footMiddle}}</div>
        <div>{{footRight}}</div>
      </div>
    </div>
  </div>
</div>
<ng-template #notAvailable>
  <div class="sub-info-text">N/A</div>
</ng-template>
<ng-template #notExist>
  <div class="sub-info-text">None</div>
</ng-template>