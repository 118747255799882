import FileModelWithVersion from "@models/fileModelWithVersion";
import ReviewerWithUserInfo from "@models/reviewerWithUserInfo";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalStatus from "@models/submittalStatus";
import WatcherItemDetail from "@models/watcherItemDetail";
import { getFormattedDate, prepareMutiLineText } from "@shared/utils";
import _ from "lodash";
import {
  IPSubDocumentDownload,
  SubmittalReviewerResponse,
} from "../api-generated";

export default class ReviewerItemDetail extends WatcherItemDetail {
  public Response: string;

  public Attachments: FileModelWithVersion[];

  public Collapse: boolean;

  public Submittal_version: string;

  public Reponse_Date: string;

  public HasComments: boolean;

  constructor(
    item: ReviewerWithUserInfo,
    docs: IPSubDocumentDownload[],
    responseItem: SubmittalReviewerResponse,
  ) {
    super(item);

    this.Reponse_Date = getFormattedDate(responseItem.DateUpdated);

    this.Response = prepareMutiLineText(responseItem.Response);

    this.Collapse = true;

    this.Submittal_version = responseItem.Submittal_version ?? "00";

    this.HasComments = responseItem.hasComments;

    const documnetsForThis = docs
      .filter((doc) => {
        return (
          doc.Status === SubmittalStatus.Compiling &&
          doc.ReferenceId === responseItem.Guid &&
          doc.FileType === SubmittalFileType.Response
        );
      })
      .map((doc) => {
        return new FileModelWithVersion(doc);
      });

    this.Attachments = _.uniqBy(
      documnetsForThis.sort((a, b) => {
        return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
      }),
      "Name",
    );
  }
}
