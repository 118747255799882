import { ISelectItem } from "@aecom/core";

const failureReason: ISelectItem[] = [
  {
    id: 'Denied',
    name: 'Denied',
    checked: false
  },
  {
    id: 'Incorrect or Missing Documents',
    name: 'Incorrect or Missing Documents',
    checked: false
  },
  {
    id: 'Incorrect Division/Specification Selected',
    name: 'Incorrect Division/Specification Selected',
    checked: false
  },
  {
    id: 'Incorrect Document Type Selected',
    name: 'Incorrect Document Type Selected',
    checked: false
  },
  {
    id: 'Incorrect Review Type Selected',
    name: 'Incorrect Review Type Selected',
    checked: false
  },
  {
    id: 'Incorrect Page Count',
    name: 'Incorrect Page Count',
    checked: false
  },
  {
    id: 'Other',
    name: 'Other',
    checked: false
  },
];

export default failureReason;
