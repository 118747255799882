import { FileModel, UploadStatus } from "@aecom/core";
import {
  BlockBlobClient,
  BlobDownloadResponseParsed,
} from "@azure/storage-blob";
import { Injectable } from "@angular/core";
import mime from "mime";
import { SubmittalDocumentsService } from "../api-generated";

@Injectable({
  providedIn: "root",
})
export default class AzureBlobService {

  constructor(public submittalDocumentService: SubmittalDocumentsService) {}

  static async uploadFile(url: string, file: FileModel): Promise<void> {
    const blockBlobClient = new BlockBlobClient(url);
    const type = mime.getType(file.Name) || "application/octet-stream";
    await blockBlobClient.uploadData(file.File, {
      blobHTTPHeaders: { blobContentType: type },
      onProgress: (ev) => {
        file.Percentage = ev.loadedBytes / file.File.size;
        file.Percentage = Math.floor(file.Percentage * 100);

        if (file.Percentage >= 100) {
          file.Status = UploadStatus.LOADED;
        }
      },
    });
  }

  async downloadBlobToFile(
    item: FileModel,
  ): Promise<BlobDownloadResponseParsed> {
    const url = await this.generateURL(item);
    const blobClient = new BlockBlobClient(url);
    return blobClient.download();
  }

  async generateURL(item: FileModel): Promise<string> {
    let lastSlashIndex = item.URL.lastIndexOf('/');
    const sub = item.URL.substring(0, lastSlashIndex);
    lastSlashIndex = sub.lastIndexOf('/');
    const guid = sub.substring(lastSlashIndex + 1);
    const url = await this.submittalDocumentService.generateURL({id: guid, name: item.Name}).toPromise();
    return url;
  }
}

