<aecom-modal data-cy="modal" title="Request to Recall Submittal" class="modal-md" [canClose]="false" theme="light">
  <div class="modal-body">
    <h4 style="color: #000;">Would you like to cancel entirely or make moditifications and resubmit? 
      If your request to cancel is accepted, you will not be able to make modifications to this submittal.</h4>
    <h4 style="margin-top: 15px">Recall Reason</h4>

    <aecom-form-item>
      <div class="button-wrapper d-flex" style="width: 70%;">
        <aecom-button
          data-cy="recallCancel"
          innerClass="btn solid btn-tab {{
            result.reason === 'Cancel'
              ? 'blue'
              : 'bg-grey-e'
          }}"
          style="width: 50%;"
          (click)="result.reason = 'Cancel'"
        >
          Cancel
        </aecom-button>

        <aecom-button
          data-cy="recallModify"
          innerClass="btn solid btn-tab {{
            result.reason === 'Modify'
              ? 'blue'
              : 'bg-grey-e'
          }}"
          style="width: 50%;"
          (click)="result.reason = 'Modify'"
        >
          Modify
        </aecom-button>
      </div>

      <div class="error-message" *ngIf="!isValid && validate && !result.reason">
        Field Required
      </div>
    </aecom-form-item>
    <h4 style="margin-top: 15px">Detail</h4>

    <aecom-form-item>
      <aecom-textbox
        rows="3"
        [theme]="'light'"
        [notEmpty]="true"
        [isMultiLine]="true"
        [ngValue]="result.comments"
        (ngValueChange)="checkInput($event)"
        [validate]="validate"
      ></aecom-textbox>

      <div class="error-message" *ngIf="!isValid && validate && result.comments.length !== 0">
        Field can't be "{{ comments }}"
      </div>
    </aecom-form-item>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">No</aecom-button>

      <aecom-button innerClass="blue lg solid" (click)="save()">Submit</aecom-button>
    </div>
  </div>
</aecom-modal>
