import { ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import SubmittalRole from "@models/submittalRole";
import WatcherItemDetail from "@models/watcherItemDetail";
import { EmailService, ISubNotificationReviewer } from "src/app/api-generated";

@Component({
  selector: "notifyList",
  templateUrl: "./notifyList.component.html",
  styleUrls: ["./notifyList.component.scss"],
})
export default class NotifyListComponent {
  @Input() list: WatcherItemDetail;

  @Input() title: string;

  @Input() turnOnNotifyUser = true;

  @Input() isReviewer = false;

  @Input() showAddUserIcon = false;

  @Output() openReviewerWindow = new EventEmitter<boolean>();

  constructor(private emailService: EmailService, public activeModal: ModalContainerService) {}

  notifyUser(item: WatcherItemDetail): void {
    const data: ISubNotificationReviewer = {
      submittalId: item.SubmittalGuid,
      reviewerId: item.UserId,
      role: this.isReviewer ? SubmittalRole.Reviewer : SubmittalRole.Watcher,
    };

    this.emailService.notifySubmittalReviewerAndWatcher(data).subscribe();
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification Confirmed";
    modalInstance.instance.body = 'The user has been notified via email to provide comments.';
    modalInstance.result;
  }

  openWindowClick(): void {
    this.openReviewerWindow.emit(this.isReviewer);
  }
}
