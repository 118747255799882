import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export default class PreviousRouteService {

  private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }    

  public setPreviousUrl(url: string) {
    this.previousUrl = url;
  } 
}