import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import ReviewerItemDetail from "@models/reviewerItemDetail";

@Component({
  selector: "reviewList",
  templateUrl: "./reviewList.component.html",
  styleUrls: ["./reviewList.component.scss"],
})
export default class ReviewListComponent implements OnInit {
  @Input() list: ReviewerItemDetail[];

  @Input() title: string;

  @Input() expandFirst = false;

  @Input() allowMovingFiles = true;

  @Output() moveAttachment = new EventEmitter<FileModel>();

  @Output() moveAttachments = new EventEmitter<FileModel[]>();

  ngOnInit(): void {
    if (this.list && this.list.length > 0) {
      this.list.sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
      );

      this.list[0].Collapse = !this.expandFirst;
    }
  }

  fileOut(item: FileModel): void {
    // console.log(item);
    this.moveAttachment.emit(item);
  }

  filesOut(item: FileModel[]): void {
    // console.log(item);
    this.moveAttachments.emit(item);
  }
}
