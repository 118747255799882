import { FileModel } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";
import AuthService from "@auth/auth.service";
import FileListType from "@models/fileListType";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalStatus from "@models/submittalStatus";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractRequirementService from "@services/local-contractRequirement.service";
import LocalPhaseService from "@services/local-phase.service";
import LocalSpecificationService from "@services/local-specification.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import LocalSubmittalPageService from "@services/local-submittalPage.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import LocalUserService from "@services/local-user.service";
import { FAC_Contracts, TSB_Contracts, queensGarage } from "@shared/staticValue";
import { getTime, prepareMutiLineText } from "@shared/utils";
import {
  IPSubDocumentDownload,
  IPSubItem,
  IUser,
  SubmittalDocumentsService,
} from "src/app/api-generated";

@Component({
  selector: "sub-detail",
  templateUrl: "./sub-detail.component.html",
  styleUrls: ["./sub-detail.component.scss"],
})
export default class SubDetailComponent implements OnInit {
  @Input() submittal: IPSubItem;
  @Input() documents: IPSubDocumentDownload[];
  @Input() isDBAdmin = false;

  division = "";
  specification = "";
  masterSpec = "";
  documentType = "";
  submittalType = "";
  drawingPage = "";
  priorityNote = "";
  referenceNumber = "";
  referenceNote = "";
  BIM360 = "";
  phase = "";
  contractRequirement = "";

  coverSheet: FileModel[] = [];
  submittalDocuments: FileModel[] = [];
  fileListType: FileListType = FileListType.View;

  isLoaded = false;
  currentUser: string;
  maxCharacters = 30;

  // User groups
  isDocControl = false;
  isManager = false;
  isCoordinator = false;
  isReviewer = false;
  isWatcher = false;
  isUser = false;

  manager: IUser;
  originator: IUser;
  docControlUser: IUser;
  coordinator: IUser;
  responseBy: IUser;

  isOldVersion = false;
  showPhaseAndContractRequirement = false;

  getFileIcon = SubDetailComponent.getFileIcon;

  static getFileIcon(type: string): string {
    return type.includes("image")
      ? " icon icon-image_doc"
      : " icon icon-blank_doc";
  }

  constructor(
    public localSpecificationService: LocalSpecificationService,
    public localSubmittalTypeService: LocalSubmittalTypeService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
    public localSubmittalPageService: LocalSubmittalPageService,
    public localPhaseService: LocalPhaseService,
    public localContractRequirementService: LocalContractRequirementService,
    public submittalDocumentService: SubmittalDocumentsService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public localUserService: LocalUserService,
    public localContractService: LocalContractService,
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    const contractId = this.localContractService.currentContract.Guid;
    const contractCode = this.localContractService.currentContract.Code;

    this.showPhaseAndContractRequirement = contractCode.includes(FAC_Contracts) || contractCode.includes(TSB_Contracts);
    this.currentUser = this.authService.user.Id;
    this.isOldVersion = contractId === queensGarage;
    this.localUserService.getItems().forEach((u) => {
      if (u.id == this.submittal.SubmittedBy) {
        this.originator = u;
      }
      if (u.id == this.submittal.DocControlId) {
        this.docControlUser = u;
      }
      if (u.id == this.submittal.ManagerId) {
        this.manager = u;
      }
      if (u.id == this.submittal.CoordinatorId) {
        this.coordinator = u;
      }
      if (u.id == this.submittal.ReviewedBy) {
        this.responseBy = u;
      }
    });

    this.documents.forEach((item) => {
      if (item.Status === SubmittalStatus.Draft) {
        const tempFile = new FileModel(
          item.documents.Guid,
          item.FileName,
          "",
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL,
          item.Size
        );

        if (item.FileType == SubmittalFileType.CoverSheet) {
          this.coverSheet.push(tempFile);
        } else if (item.FileType == SubmittalFileType.SubmittalDocuments) {
          this.submittalDocuments.push(tempFile);
        }
      }
    });

    const temp = this.localSpecificationService.getItems().filter((item) => {
      return item.Guid == this.submittal.submittal_create[0]?.SpecificationId;
    })[0];

    this.division = temp?.Division ?? '';
    this.specification = temp ? `${temp.SpecId} - ${temp.Title}` : "";

    const selectedMasterSpec = this.localSpecificationService.getItems().find((item)=>{
      return item.Guid === this.submittal.submittal_create[0]?.MasterSpecId;
    });

    this.masterSpec = selectedMasterSpec ? `${selectedMasterSpec.SpecId} ${selectedMasterSpec.Title}` : '';

    this.documentType = this.localSubmittalDocumentTypeService.getItems().find((item) => {
      return item.Guid == this.submittal.submittal_create[0]?.DocumentTypeId;
    })?.Title;

    this.submittalType = this.localSubmittalTypeService.getItems().find((item) => {
      return item.Guid == this.submittal.submittal_create[0]?.SubmittalTypeId;
    })?.Title;

    this.drawingPage = this.localSubmittalPageService.getItems().find((item) => {
      return item.Guid == this.submittal.submittal_create[0]?.DrawingPageId;
    })?.Title;

    this.phase = this.localPhaseService.getItems().find((item) => {
      return item.Guid == this.submittal.submittal_create[0]?.PhaseId;
    })?.Title ?? "";

    this.contractRequirement = this.localContractRequirementService.getItems().find((item) => {
      return item.Guid == this.submittal.submittal_create[0]?.ContractRequirementId;
    })?.Name ?? "";

    if (
      this.submittal.submittal_create[0]?.PriorityReason &&
      this.submittal.submittal_create[0].PriorityReason.trim().length > 0
    ) {
      this.priorityNote = prepareMutiLineText(this.submittal.submittal_create[0].PriorityReason);
    }

    if (
      this.submittal.submittal_create[0]?.ReferenceNote &&
      this.submittal.submittal_create[0].ReferenceNote.trim().length > 0
    ) {
      this.referenceNote = prepareMutiLineText(this.submittal.submittal_create[0].ReferenceNote);
    }

    if (
      this.submittal.submittal_create[0]?.ReferenceNumber &&
      this.submittal.submittal_create[0].ReferenceNumber.trim().length > 0
    ) {
      this.referenceNumber =
        this.submittal.submittal_create[0].ReferenceNumber;
    }

    if (
      this.submittal.submittal_create[0]?.BIM360 &&
      this.submittal.submittal_create[0].BIM360.trim().length > 0
    ) {
      this.BIM360 =
        this.submittal.submittal_create[0].BIM360;
    }

    this.isLoaded = true;
    this.loadingService.stop();
  }

  getTime(a: string): string | null {
    if (!a) return null;
    return getTime(a);
  }

  getDate(a: string): string {
    if (!a) return null;

    return this.getDateWithOffSet(a);
  }

  getDateWithOffSet(date: string): string {
    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
    return tempDate.toLocaleDateString();
  }

  getFixTimeDate(a: string): string {
    if (!a) return null;

    return this.getDateWithOffSet(a) + " 04:00 PM EST";
  }

  getAccTimeDate(a: string): string {
    if (!a) return null;
    const options = { hour: "2-digit", minute: "2-digit", hour12: true } as Intl.DateTimeFormatOptions;
    return `${this.getDateWithOffSet(a)} ${new Date(a).toLocaleTimeString("en-US", options)} EST`;
  }
}
