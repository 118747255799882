import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import AuthService from "@auth/auth.service";
import LoadingService from "@services/loading.service";
import LocalSpecificationService from "@services/local-specification.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import LocalSubmittalPageService from "@services/local-submittalPage.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import RouteParams from "@shared/route-params";
import { forkJoin, Observable, of } from "rxjs";
import {
  IPRFIContractRequirement,
  IPRFISpecification,
  IPSubDocumentDownload,
  IPSubItem,
  Phase,
  SubmittalDocumentsService,
  SubmittalDocumentType,
  SubmittalPage,
  SubmittalService,
  SubmittalType,
} from "../api-generated";
import LocalSubmittalItemService from "@services/local-submittalItem.service";
import LocalPhaseService from "@services/local-phase.service";
import LocalContractRequirementService from "@services/local-contractRequirement.service";
export interface ISubmittalCreateResolver {
  submittal: IPSubItem;
  documents: IPSubDocumentDownload[];
  contractId: string;
  currentUserId: string;
  submittalType: SubmittalType[];
  submittalPage: SubmittalPage[];
  submittalDocumentType: SubmittalDocumentType[];
  submittalSpecification: IPRFISpecification[];
  phase: Phase[];
  contractRequirement: IPRFIContractRequirement[];
}

@Injectable({
  providedIn: "root",
})
export default class CreateResolver implements Resolve<any> {
  constructor(
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public submittalService: SubmittalService,
    public submittalDocumentService: SubmittalDocumentsService,
    public localSpecificationService: LocalSpecificationService,
    public localSubmittalTypeService: LocalSubmittalTypeService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
    public localSubmittalPageService: LocalSubmittalPageService,
    public localPhaseService: LocalPhaseService,
    public localContractRequirementService: LocalContractRequirementService,
    public localSubmittalItemService: LocalSubmittalItemService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<ISubmittalCreateResolver>
    | Promise<ISubmittalCreateResolver | any>
    | Object {
    this.loadingService.start();
    this.localSubmittalItemService.firstLoad = false;
    const currentUser = this.authService.user.Id;
    const submittalId = route.params[RouteParams.ItemId];
    const contractId = state.url.split("/")[1];
    
    return forkJoin({
      submittal:
        submittalId === "new"
          ? of(null)
          : this.submittalService.getSubmittalItem(submittalId, true),
      documents:
        submittalId === "new"
          ? of(null)
          : this.submittalDocumentService.getAllSubmittalDocuments(submittalId),
      contractId: of(contractId),
      currentUserId: of(currentUser),
      submittalType: of(this.localSubmittalTypeService.getItems()),
      submittalPage: of(this.localSubmittalPageService.getItems()),
      submittalDocumentType: of(this.localSubmittalDocumentTypeService.getItems()),
      submittalSpecification: of(this.localSpecificationService.getItems()),
      phase: of(this.localPhaseService.getItems()),
      contractRequirement: of(this.localContractRequirementService.getItems())
    });
  }
}
