import { ISubRecall, SubmittalRecall } from "../api-generated";
import TimeStamp from "./timeStamp";

export default class SubmittalRecallItem extends TimeStamp implements ISubRecall {
  public Guid: string;

  public Notes: string;

  public RecallReason: string | null;

  constructor(recall: SubmittalRecall) {
    super(recall);

    this.Guid = recall.Guid;

    this.Notes = recall.Notes;

    this.RecallReason = recall.RecallReason;
  }
}
