import SubmittalItemUpdateBase from "@models/submittalItemUpdateBase";
import SubmittalRecallBase from "@models/submittalRecallBase";
import { IPSubItem, ISubRecallStep } from "../api-generated";
import RecallRequest from "./recallRequest";

export default class SubmittalRecall
  extends SubmittalItemUpdateBase
  implements ISubRecallStep
{
  public submittal_recall: SubmittalRecallBase;

  public IsRecalled: boolean;

  constructor(contractId: string, recall: RecallRequest, item?: IPSubItem) {
    super(contractId, item);

    this.submittal_recall = new SubmittalRecallBase(recall);

    this.IsRecalled = true;
  }
}
