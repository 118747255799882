import { ISubNotes, SubmittalNotes } from "../api-generated";

import TimeStampWithDraft from "./timeStampWithDraft";

export default class SubmittalNotesBase
  extends TimeStampWithDraft
  implements ISubNotes
{
  public Guid?: string;

  public Notes: string;

  constructor(item?: SubmittalNotes) {
    super(item);
    this.Guid = item?.Guid;

    this.Notes = item?.Notes ?? '';
  }
}
