import { ModalContainerService } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-sendBack-modal",
  templateUrl: "./sendBack-modal.component.html",
  styleUrls: ["./sendBack-modal.component.css"],
})
export default class SendBackModalComponent implements OnInit {
  @Input() by = "Manager";
  comments = "The {by} has sent this draft back for further update.";
  defaultText = "";

  constructor(private activeModal: ModalContainerService) {}

  ngOnInit() {
    this.comments = this.comments.replace("{by}", this.by);
    this.defaultText = this.comments.replace("{by}", this.by);
  }

  save() {
    this.activeModal.close(
      !this.comments || this.comments.trim() === ""
        ? this.defaultText
        : this.comments,
    );
  }

  cancel() {
    this.activeModal.close(0);
  }
}
