import { Inject, Injectable } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { StorageKey } from "../shared/localstorage";

@Injectable({
  providedIn: "root",
})
export default class LocalStorageService {
  anotherTodolist = [];

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {}

  public getLocalStorage(key: string): unknown {
    return this.storage.get(key) || [];
  }

  public saveLocalStorage(key: string, value: unknown): void {
    this.storage.set(key, value);
  }

  public clearLocalStorage(): void {
    const allKeys = Object.values(StorageKey);
    // console.log(allKeys);
    allKeys.forEach((role) => {
      this.storage.remove(role);
    });
  }

  public set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  public  get(key: string) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }
  
}
